import {format, parse} from 'date-fns';
import {ru} from 'date-fns/locale';

export const formatDate = (
    source: string,
    formatTemplate: string = 'EEEEEE, d MMM yyyy, HH:mm',
    parseTemplate: string = `yyyy-MM-dd'T'HH:mm:ss`
) => {
    const date = parse(source, parseTemplate, new Date());
    return format(date, formatTemplate, {locale: ru})
        .toLowerCase();
};


export const formatNativeDate = (
    source: Date,
    template: string
) => {
    return format(source, template, {locale: ru});
};
