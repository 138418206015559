import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AddImageComponent} from './add-image.component';
import {MatIconModule} from '@angular/material/icon';


@NgModule({
    declarations: [AddImageComponent],
    imports: [
        CommonModule,
        MatIconModule
    ],
    exports: [AddImageComponent]
})
export class AddImageModule {
}
