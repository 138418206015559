import {Component, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EventSessionSettingsService, SettingsLoadingStatus, SettingsPopupState} from '../event-session-settings.service';
import {Subscription} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {FullscreenLoaderService} from '../../../../shared/ui-kit/fullscreen-loader/fullscreen-loader.service';
import {formatDate, formatNativeDate} from '../../../shared/utils/date-utils';
import {parse} from 'date-fns';
import {SortState} from '../../../../shared/ui-kit/sort/sort.component';

interface EventSessionLimit {
    customerName: string;
    customerUuid: string;
    saleBegin: string;
    saleEnd: string;
    uuid: string;
    onSale: boolean;
}

interface Customer {
    name: string;
    uuid: string;
}

interface EventSessionTableLimit {
    uuid?: string;
    customerName: string;
    customerUuid: string;
    start: string;
    end: string;
    saleBegin: string;
    saleEnd: string;
    onSale: boolean;
    selected: boolean;
    datetime: number;
}

@Component({
    selector: 'app-open-sales',
    templateUrl: './open-sales.component.html',
    styleUrls: ['./open-sales.component.scss']
})
export class OpenSalesComponent implements OnDestroy {

    clientsOpened = false;

    private allCustomers: Customer[] = [];
    private customers: Customer[] = [];
    private usedCustomersUuids: string[] = [];
    public tableOriginal: EventSessionTableLimit[] = [];
    public table: EventSessionTableLimit[] = [];
    public selectedLimits: EventSessionTableLimit[] = [];

    private readonly eventSessionLoadingSubscription: Subscription;

    public readonly form: FormGroup;

    public tableColumns: string[] = ['select-row', 'client', 'date-time', 'status', 'actions'];

    public error: string | null = null;

    public loading = true;

    private sort: {
        field: string,
        direction: SortState
    } = {field: '', direction: SortState.DEFAULT};

    constructor(
        private readonly httpClient: HttpClient,
        private readonly eventSessionSettingsService: EventSessionSettingsService,
        private readonly fb: FormBuilder,
        private readonly fullscreenLoaderService: FullscreenLoaderService
    ) {
        this.eventSessionLoadingSubscription = this.eventSessionSettingsService.loading.subscribe(async (status) => {
            if (status === SettingsLoadingStatus.LOADED) {
                const eventSessionUuid = this.eventSessionSettingsService.getEventSessionUuid() as string;
                await this.loadLimits(eventSessionUuid);
                await this.loadCustomers();
                this.loading = false;
            }
        });

        this.form = this.fb.group({
            client: [null, [Validators.required]],
            start: [null, [Validators.required]],
            end: [null, [Validators.required]],
        });
    }

    ngOnDestroy(): void {
        this.eventSessionLoadingSubscription.unsubscribe();
    }

    public onRangeChange({start, end}: { start: Date | null, end: Date | null }): void {
        this.form.patchValue({
            start, end
        });
    }

    private async loadCustomers(): Promise<void> {
        try {
            this.allCustomers = await this.httpClient.get<Customer[]>(`/api/customer/all`).toPromise();

            this.filterCustomers();
        } catch (e) {

        }
    }

    private filterCustomers(): void {
        this.customers = this.allCustomers.filter(customer => !this.usedCustomersUuids.includes(customer.uuid));
    }

    private async loadLimits(eventSessionUuid: string): Promise<void> {
        try {
            const eventSessionLimits = await this.httpClient
                .get<EventSessionLimit[]>(`/api/event-session-limit/all/by-event-session/${eventSessionUuid}`).toPromise();

            this.tableOriginal = eventSessionLimits.map(eventSessionLimit => {
                this.usedCustomersUuids.push(eventSessionLimit.customerUuid);
                return {
                    uuid: eventSessionLimit.uuid,
                    customerName: eventSessionLimit.customerName,
                    customerUuid: eventSessionLimit.customerUuid,
                    saleBegin: eventSessionLimit.saleBegin,
                    saleEnd: eventSessionLimit.saleEnd,
                    end: formatDate(eventSessionLimit.saleEnd, 'dd MMM HH:mm'),
                    start: formatDate(eventSessionLimit.saleBegin, 'dd MMM HH:mm'),
                    datetime: parse(eventSessionLimit.saleBegin, `yyyy-MM-dd'T'HH:mm:ss`, new Date()).getTime(),
                    onSale: eventSessionLimit.onSale,
                    selected: false
                };
            });

            this.setPageData();

        } catch (e) {

        }
    }

    public getCustomers(): Customer[] {
        return this.customers;
    }

    public resetForm(): void {
        this.form.reset();
        this.error = null;
    }

    public async addLimit(): Promise<void> {
        this.error = null;
        this.fullscreenLoaderService.open();
        try {
            const model = {
                customerUuid: this.form.value.client.uuid,
                eventSessionUuid: this.eventSessionSettingsService.getEventSessionUuid() as string,
                saleBegin: formatNativeDate(this.form.value.start, `yyyy-MM-dd'T'HH:mm:ss`),
                saleEnd: formatNativeDate(this.form.value.end, `yyyy-MM-dd'T'HH:mm:ss`)
            };

            const eventSessionLimit = await this.httpClient
                .post<EventSessionLimit>(`/api/event-session-limit/create`, model).toPromise();

            this.tableOriginal.push({
                uuid: eventSessionLimit.uuid,
                customerName: eventSessionLimit.customerName,
                customerUuid: eventSessionLimit.customerUuid,
                saleBegin: eventSessionLimit.saleBegin,
                saleEnd: eventSessionLimit.saleEnd,
                end: formatDate(eventSessionLimit.saleEnd, 'dd MMM HH:mm'),
                start: formatDate(eventSessionLimit.saleBegin, 'dd MMM HH:mm'),
                datetime: parse(eventSessionLimit.saleBegin, `yyyy-MM-dd'T'HH:mm:ss`, new Date()).getTime(),
                onSale: eventSessionLimit.onSale,
                selected: false
            });

            this.usedCustomersUuids.push(this.form.value.client.uuid);

            this.filterCustomers();

            this.setPageData();

            this.resetForm();
        } catch (e) {
            this.error = e.error.message;
        }
        this.fullscreenLoaderService.close();
    }

    public toggleLimitSelection(eventSessionTableLimit: EventSessionTableLimit): void {
        if (eventSessionTableLimit.selected) {
            const index = this.selectedLimits.indexOf(eventSessionTableLimit);
            if (index > -1) {
                this.selectedLimits.splice(index, 1);
            }
        } else {
            this.selectedLimits.push(eventSessionTableLimit);
        }
        eventSessionTableLimit.selected = !eventSessionTableLimit.selected;
    }

    public onSelectAllChange(matCheckboxChange: MatCheckboxChange): void {
        if (matCheckboxChange.checked) {
            this.selectedLimits = [...this.table];
        } else {
            this.selectedLimits = [];
        }

        this.table.forEach(sessionPrintImage => {
            sessionPrintImage.selected = matCheckboxChange.checked;
        });
    }

    public async removeSelectedLimits(): Promise<void> {
        this.error = null;
        this.fullscreenLoaderService.open();
        const selectedLimitUuids = this.selectedLimits.map(limit => limit.uuid);
        try {
            await this.httpClient
                .delete(`/api/event-session-limit/delete/${selectedLimitUuids.join(',')}`).toPromise();

            this.tableOriginal = this.tableOriginal.filter(limit => !limit.selected);

            this.selectedLimits.forEach(selectedLimit => {
                const usedCustomerUuidIndex = this.usedCustomersUuids.indexOf(selectedLimit.customerUuid);
                if (usedCustomerUuidIndex > -1) {
                    this.usedCustomersUuids.splice(usedCustomerUuidIndex, 1);
                }
            });
            this.filterCustomers();

            this.selectedLimits = [];

            this.setPageData();
        } catch (e) {
            this.error = e.error.message;
        }
        this.fullscreenLoaderService.close();
    }

    public async removeLimit(eventSessionTableLimit: EventSessionTableLimit): Promise<void> {
        this.error = null;
        this.fullscreenLoaderService.open();
        try {
            await this.httpClient
                .delete(`/api/event-session-limit/delete/${eventSessionTableLimit.uuid}`).toPromise();

            this.tableOriginal.splice(this.tableOriginal.indexOf(eventSessionTableLimit), 1);
            this.tableOriginal = [...this.tableOriginal];

            if (eventSessionTableLimit.selected) {
                this.selectedLimits.splice(this.selectedLimits.indexOf(eventSessionTableLimit), 1);
                this.selectedLimits = [...this.selectedLimits];
            }

            const usedCustomerUuidIndex = this.usedCustomersUuids.indexOf(eventSessionTableLimit.customerUuid);
            if (usedCustomerUuidIndex > -1) {
                this.usedCustomersUuids.splice(usedCustomerUuidIndex, 1);
                this.filterCustomers();
            }

            this.setPageData();

        } catch (e) {
            this.error = e.error.message;
        }
        this.fullscreenLoaderService.close();
    }

    public onSortChange(field: string, direction: SortState): void {
        this.sort = {field, direction};
        this.setPageData();
    }

    public getSortDirection(field: string): SortState {
        if (this.sort.field === field) {
            return this.sort.direction;
        }
        return SortState.DEFAULT;
    }

    private setPageData(): void {
        const tableCopy = this.tableOriginal.slice();

        if (this.sort.direction !== SortState.DEFAULT) {
            tableCopy.sort((a, b): number => {
                let compareResult = 0;
                const left = (a as any)[this.sort.field];
                const right = (b as any)[this.sort.field];
                if (left === null || left === undefined) {
                    compareResult = 1;
                } else if (right === null || right === undefined) {
                    compareResult = -1;
                } else {
                    if (left > right) {
                        compareResult = 1;
                    } else {
                        compareResult = -1;
                    }
                }
                if (this.sort.direction === SortState.DESC) {
                    compareResult *= -1;
                }
                return compareResult;
            });
        }
        this.table = tableCopy;
    }

    public next(): void {
        this.eventSessionSettingsService.setCurrentState(SettingsPopupState.SALE_LIMITS);
    }
}
