import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatchDayCatalogComponent} from './match-day-catalog.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';


@NgModule({
    declarations: [MatchDayCatalogComponent],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        ReactiveFormsModule,
        MatIconModule
    ],
    exports: [MatchDayCatalogComponent]
})
export class MatchDayCatalogModule {
}
