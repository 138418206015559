<div class="popup-wrapper">
    <div class="popup-header">
        <div class="body-text-1">Изменить lock time</div>
        <button
                mat-icon-button
                disableRipple
                type="button"
                class="button-icon"
                color="primary"
                (click)="close()"
        >
            <mat-icon svgIcon="times"></mat-icon>
        </button>
    </div>
    <div class="popup-body" [formGroup]="form">
        <div class="popup-body-row">
            <input-date-time-picker
                    placeholder="Lock time"
                    [disabled]="form.disabled"
                    [date]="form.value.date"
                    (change)="onDateChange($event)"></input-date-time-picker>
        </div>
    </div>
    <div class="error-container" *ngIf="error">
        <inline-alert-component [text]="error"></inline-alert-component>
    </div>
    <div class="popup-footer">
        <button
                mat-raised-button
                disableRipple
                type="button"
                class="button-primary-filled button-primary-filled-secondary"
                color="primary"
                [disabled]="form.invalid || waiting"
                (click)="save()"
        >
            Применить
        </button>
    </div>
</div>
