import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormBuilder, FormGroup} from '@angular/forms';
import {Team} from '../../shared/types/events';
import {HttpClient} from '@angular/common/http';
import {SubscriptionTypeCatalogComponent} from '../../components/reference-book/subscription-type-catalog/subscription-type-catalog.component';
import {FullscreenLoaderService} from '../../../shared/ui-kit/fullscreen-loader/fullscreen-loader.service';

export enum TeamCreatePopupResult {
    CANCEL,
    SAVE,
    SAVE_AND_CREATE
}

export interface ResourceObject {
    lastModified: string;
    name: string;
    size: number;
    url: string;
    resourceUri: string;
}

@Component({
    selector: 'app-team-create-popup',
    templateUrl: './team-create-popup.component.html',
    styleUrls: ['./team-create-popup.component.scss']
})
export class TeamCreatePopupComponent implements OnInit {

    
    public lang: FormControl = new FormControl(0);
    form: FormGroup;
    public loading = true;
    public waiting = false;
    public subscriptionTypeCatalogComponent = SubscriptionTypeCatalogComponent;
    public error: string | null = null;
    popupResult = TeamCreatePopupResult;
    private langName: Map<number, string> = new Map<number, string>([
        [0, 'ru'],
        [1, 'en']
    ]);   

    constructor(
        private readonly dialogRef: MatDialogRef<TeamCreatePopupComponent>,
        private readonly fb: FormBuilder,
        private readonly httpClient: HttpClient,
        private readonly fullscreenLoaderService: FullscreenLoaderService,
        @Inject(MAT_DIALOG_DATA) public readonly team: Team
    ) {
        
        this.form = this.fb.group({});
        const main = this.fb.group({
            uuid: [null],
            name: [null],            
            logoLink: [null],
            altLogoLink: [null]
          });
        this.form.addControl('main', main);
        
        this.langName.forEach((langName) => {
            const lang = this.fb.group({
                caption: [null]
              });
            this.form.addControl(langName, lang);
        });
    }

    async ngOnInit(): Promise<void> {
        this.loading = true;
        if (this.team) {
            this.form.patchValue({
                main: {
                    // @ts-ignore
                    uuid: this.team.uuid,
                    // @ts-ignore
                    name: this.team.name,
                    // @ts-ignore
                    logoLink: this.team.logoLink,
                    // @ts-ignore
                    altLogoLink: this.team.altLogoLink
                    }
                });
                this.langName.forEach((langName) => {
                    this.form.patchValue({
                        // @ts-ignore
                        [langName] : {
                            // @ts-ignore
                            caption: this.team.caption[langName]
                        }
                    });
                });
        }
        this.loading = false;
    }

    public close(result: TeamCreatePopupResult, data?: any): void {
        this.dialogRef.close({
            result,
            data
        });
    }

    public async save(): Promise<void> {
        try {
            await this._save();
            this.close(TeamCreatePopupResult.SAVE);
        } catch (e) {

        }
    }

    public async _save(): Promise<Team> {

        let caption = {};
        this.langName.forEach((langName) => { 
            if (this.form.value[langName].caption) {
                caption = Object.assign({[langName]: this.form.value[langName].caption}, caption);
            } 
        });

        const model: any = {
            uuid: this.form.value.main?.uuid ? this.form.value.main?.uuid : '',
            name: this.form.value.ru.caption,
            caption: caption,
            logoLink: this.form.value.main.logoLink,
            altLogoLink: this.form.value.main.altLogoLink
        };
        this.waiting = true;
        this.error = null;
        this.form.disable();
        try {
           if (this.team) {
               return await this.httpClient.put<Team>(`/api/team/update`, model).toPromise();
            } else {
                 return await this.httpClient.post<Team>(`/api/team/create`, model).toPromise();
             }
        } catch (e) {
            this.error = e.error.message;
            this.form.enable();
            this.waiting = false;
            throw e;
        }
    }

    public onReferenceBookChange(tab: number): void {
        this.lang.setValue(tab);
    }

    public getLangName(index: number): string {
        return (this.langName.get(index)) as string;
    }

    public addImage(control: string, param: string): void {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.addEventListener('change', async (event) => {
            const files = (event.target as HTMLInputElement).files;
            const file = files && files[0];
            const formData: FormData = new FormData();
            formData.append('file', file as File);
            this.fullscreenLoaderService.open();
            try {
                const resourceObject = await this.httpClient.post<ResourceObject>(`/api/resource/object`, formData).toPromise();
                this.form.patchValue({
                    [control]: { [param]: resourceObject.resourceUri
                    }}, {emitEvent: false});
            } catch (e) {
            }
            this.fullscreenLoaderService.close();
        });
        input.click();
        input.remove();
    }

    public removeLink(control: string, param: string): void {
        this.form.patchValue({
            [control]: { [param]: null
            }});
    }

    public downloadResourceObject(control: string, param: string): void {
        const link = document.createElement('a');
        link.target = '_blank';
        link.href = this.form.controls[control].value[param];
        document.body.appendChild(link);
        link.click();
        link.remove();
    }
}
