import {Component, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup} from '@angular/forms';
import {HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
    selector: 'app-sector-list-popup',
    templateUrl: './sector-list-popup.component.html',
    styleUrls: ['./sector-list-popup.component.scss']
})
export class SectorListPopupComponent implements OnInit {

    public waiting = false;
    public error: string | null = null;
    form: FormGroup;
    public loading = true;
    stringData: string = '';
    //@Input() data: any;
   // @Output() sectorsList: string;

    constructor(
        private readonly dialogRef: MatDialogRef<SectorListPopupComponent>,
        private readonly fb: FormBuilder,
        private readonly httpClient: HttpClient,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.form = this.fb.group({});
        const main = this.fb.group({
            sectors: [null],
          });
        this.form.addControl('main', main);
    }

    async ngOnInit(): Promise<void> {
        this.loading = true;
        if (this.data && this.data.length) { 
            this.stringData = this.data.toString()
            this.form.patchValue({
                main: {
                    sectors: this.stringData,
                    }
                });
            }
            this.loading = false;
    }

    public close(): void {
        this.dialogRef.close();
    }

    public async selectSectors(data: string) {
        this.dialogRef.close(data);
    }

}
