import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormBuilder, FormGroup} from '@angular/forms';
import {ZonePreference} from '../../shared/types/events';
import {HttpClient} from '@angular/common/http';
import {SubscriptionTypeCatalogComponent} from '../../components/reference-book/subscription-type-catalog/subscription-type-catalog.component';
import {FullscreenLoaderService} from '../../../shared/ui-kit/fullscreen-loader/fullscreen-loader.service';

export enum ZonePreferenceCreatePopupResult {
    CANCEL,
    SAVE,
    SAVE_AND_CREATE
}

export interface ResourceObject {
    lastModified: string;
    name: string;
    size: number;
    url: string;
    resourceUri: string;
}

@Component({
    selector: 'app-zone-preference-create-popup',
    templateUrl: './zone-preference-create-popup.component.html',
    styleUrls: ['./zone-preference-create-popup.component.scss']
})
export class ZonePreferenceCreatePopupComponent implements OnInit {

    public lang: FormControl = new FormControl(0);
    form: FormGroup;
    public loading = true;
    public waiting = false;
    public subscriptionTypeCatalogComponent = SubscriptionTypeCatalogComponent;
    public error: string | null = null;
    popupResult = ZonePreferenceCreatePopupResult;
    private langName: Map<number, string> = new Map<number, string>([
        [0, 'ru'],
        [1, 'en']
    ]);   

    constructor(
        private readonly dialogRef: MatDialogRef<ZonePreferenceCreatePopupComponent>,
        private readonly fb: FormBuilder,
        private readonly httpClient: HttpClient,
        private readonly fullscreenLoaderService: FullscreenLoaderService,
        @Inject(MAT_DIALOG_DATA) public readonly zonePreference: ZonePreference
    ) {
        
        this.form = this.fb.group({});
        const main = this.fb.group({
            id: [null],           
            imageLink: [null],
          });
        this.form.addControl('main', main);
        
        this.langName.forEach((langName) => {
            const lang = this.fb.group({
                text: [null]
              });
            this.form.addControl(langName, lang);
        });
    }

    async ngOnInit(): Promise<void> {
        this.loading = true;
        if (this.zonePreference) {
            this.form.patchValue({
                main: {
                    // @ts-ignore
                    id: this.zonePreference.id,
                    // @ts-ignore
                    imageLink: this.zonePreference.imageLink,
                    }
                });
                this.langName.forEach((langName) => {
                    this.form.patchValue({
                        // @ts-ignore
                        [langName] : {
                            // @ts-ignore
                            text: this.zonePreference.text[langName]
                        }
                    });
                });
        }
        this.loading = false;
    }

    public close(result: ZonePreferenceCreatePopupResult, data?: any): void {
        this.dialogRef.close({
            result,
            data
        });
    }

    public async save(): Promise<void> {
        try {
            await this._save();
            this.close(ZonePreferenceCreatePopupResult.SAVE);
        } catch (e) {

        }
    }

    public async _save() {

        let text = {};
        this.langName.forEach((langName) => { 
            if (this.form.value[langName].text) {
                text = Object.assign({[langName]: this.form.value[langName].text}, text);
            } 
        });

        const model: any = {
            text: text,
            imageLink: this.form.value.main.imageLink,
        };
        this.waiting = true;
        this.error = null;
        this.form.disable();
        try {
           if (this.zonePreference) {
                return await this.httpClient.put<ZonePreference>(`/api/zone-preference/update`, model).toPromise();
            } else {
                return await this.httpClient.post<ZonePreference>(`/api/zone-preference/create`, model).toPromise();
             }
        } catch (e) {
            //@ts-ignore
            this.error = e.error.message;
            this.form.enable();
            this.waiting = false;
            throw e;
        }
    }

    public onReferenceBookChange(tab: number): void {
        this.lang.setValue(tab);
    }

    public getLangName(index: number): string {
        return (this.langName.get(index)) as string;
    }

    public addImage(control: string, param: string): void {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.addEventListener('change', async (event) => {
            const files = (event.target as HTMLInputElement).files;
            const file = files && files[0];
            const formData: FormData = new FormData();
            formData.append('file', file as File);
            this.fullscreenLoaderService.open();
            try {
                const resourceObject = await this.httpClient.post<ResourceObject>(`/api/resource/object`, formData).toPromise();
                this.form.patchValue({
                    [control]: { [param]: resourceObject.resourceUri
                    }}, {emitEvent: false});
            } catch (e) {
            }
            this.fullscreenLoaderService.close();
        });
        input.click();
        input.remove();
    }

    public removeLink(control: string, param: string): void {
        this.form.patchValue({
            [control]: { [param]: null
            }});
    }

    public downloadResourceObject(control: string, param: string): void {
        const link = document.createElement('a');
        link.target = '_blank';
        link.href = this.form.controls[control].value[param];
        document.body.appendChild(link);
        link.click();
        link.remove();
    }
}
