import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BaseCatalogComponent} from './base-catalog.component';
import {NewCatalogEntityModule} from '../new-catalog-entity/new-catalog-entity.module';


@NgModule({
    declarations: [BaseCatalogComponent],
    exports: [
        BaseCatalogComponent
    ],
    imports: [
        CommonModule,
        NewCatalogEntityModule
    ]
})
export class BaseCatalogModule {
}
