import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup} from '@angular/forms';
import {HttpClient} from '@angular/common/http';

export enum ChangePassPopupResult {
    CANCEL,
    SAVE,
    SAVE_AND_CREATE
}

@Component({
    selector: 'app-change-pass-popup',
    templateUrl: './change-pass-popup.component.html',
    styleUrls: ['./change-pass-popup.component.scss']
})
export class ChangePassPopupComponent implements OnInit {

    form: FormGroup;
    public waiting = false;
    public error: string | null = null;
    popupResult = ChangePassPopupResult;   

    constructor(
        private readonly dialogRef: MatDialogRef<ChangePassPopupComponent>,
        private readonly fb: FormBuilder,
        private readonly httpClient: HttpClient
    ) {
        
        this.form = this.fb.group({});
        const main = this.fb.group({
            old: [null],
            new: [null],
            newconfirm: [null]
          });
        this.form.addControl('main', main);
    }

    async ngOnInit(): Promise<void> {
       
    }

    public close(result: ChangePassPopupResult): void {
        this.dialogRef.close({
            result
        });
    }

    public async save(): Promise<void> {
        try {
            await this._save();
            this.close(ChangePassPopupResult.SAVE);
        } catch (e) {

        }
    }

    public async _save(): Promise<void> {
        if (this.form.value.main?.old && this.form.value.main?.new && this.form.value.main?.new === this.form.value.main?.newconfirm)
        {   
            const model: any = {
                currentPassword: this.form.value.main?.old,
                newPassword: this.form.value.main?.new
            }; 
            this.waiting = true;
            this.error = null;
            this.form.disable();
            try {
                return await this.httpClient.put<void>(`/api/authentication/change-password`, model).toPromise();
            } catch (e) {
                // @ts-ignore
                //this.error = e.error.message? e.error.message : e.message;
                this.error = 'Не верные данные!'
                this.form.enable();
                this.waiting = false;
                throw e;
            }
        } else {
            this.error = 'Не верные данные!'
            throw this.error;
        }
    }

}
