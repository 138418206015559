import {Component, Input, ViewChild} from "@angular/core";
import {MatMenuTrigger} from "@angular/material/menu";
import {MatButton} from "@angular/material/button";

@Component({
    selector: 'button-menu-component',
    template: `
        <button
                mat-button
                disableRipple
                class="button-menu-control"
                [matMenuTriggerRestoreFocus]="false"
                [matMenuTriggerFor]="menu"
                (onMenuOpen)="opMenuOpen(buttonMenuControl, buttonMenuPanelContainer)"
                [class.button-menu-opened]="!!trigger && trigger.menuOpen"
                [disabled]="disabled"
                #buttonMenuControl
        >
            <ng-content select="[menu-placeholder]"></ng-content>
            <mat-icon svgIcon="arrow"></mat-icon>
        </button>
        <mat-menu #menu="matMenu" class="button-menu-panel" [hasBackdrop]="hasBackdrop">
            <div class="button-menu-panel-container" #buttonMenuPanelContainer (click)="$event.stopPropagation()">
                <div class="button-menu-list-container" appSimplebarScroller [scrollEnabled]="scrollEnabled" (click)="close()">
                    <ng-content select="[menu-content]"></ng-content>
                </div>
            </div>
        </mat-menu>
    `
})
export class ButtonMenuComponent {
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger | undefined;

    @Input()
    hasBackdrop: boolean = true;

    @Input()
    disabled: boolean = false;

    @Input()
    scrollEnabled: boolean = true;

    close() {
        this.trigger?.closeMenu();
    }

    opMenuOpen(button: MatButton, menu: HTMLElement) {
        if (!this.scrollEnabled) {
            return
        }
        const boundingClientRect = button._elementRef.nativeElement.getBoundingClientRect();

        menu.style.width = `${boundingClientRect.width}px`;
        menu.style.paddingTop = `${boundingClientRect.height}px`;
        menu.style.marginTop = `-${boundingClientRect.height}px`;
    }
}
