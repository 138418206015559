import {
    ChangeDetectionStrategy,
    Component,
    ComponentFactoryResolver,
    EventEmitter,
    Input,
    OnChanges,
    Output, TemplateRef, Type, ViewChild, ViewContainerRef
} from "@angular/core";
import {BaseRangeCell} from "../base-cell-component/base-range-cell";
import {BaseRangeCellComponent} from "../base-cell-component/base-range-cell.component";

@Component({
    selector: 'calendar-range-days',
    template: `
        <ng-template></ng-template>
    `,
    styleUrls: ['./calendar-range-days.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarRangeDaysComponent implements OnChanges {
    @Input() days: Date[] = [];

    @Input() start: Date | null = null;
    @Input() end: Date | null = null;

    @Input() hoveredDate: Date | null = null;

    @Input() cellComponent: Type<BaseRangeCell> = BaseRangeCellComponent;
    /* @ts-ignore */
    @ViewChild(TemplateRef, { read: ViewContainerRef, static: true }) containerRef: ViewContainerRef;

    @Output() select: EventEmitter<Date> = new EventEmitter(true);

    @Output() hover: EventEmitter<Date> = new EventEmitter(true);

    constructor(
        private cfr: ComponentFactoryResolver
    ) {
    }

    ngOnChanges() {
        const factory = this.cfr.resolveComponentFactory(this.cellComponent);

        this.containerRef.clear();

        this.days.forEach((date: Date) => {
            const component = this.containerRef.createComponent(factory);
            this.patchWithContext(component.instance, date);
            component.changeDetectorRef.detectChanges();
        });
    }

    private patchWithContext(component: BaseRangeCell, date: Date) {
        component.date = date;
        component.start = this.start;
        component.end = this.end;
        component.hoveredDate = this.hoveredDate;
        component.select.subscribe(this.select.emit.bind(this.select));
        component.hover.subscribe(this.hover.emit.bind(this.hover));
    }
}
