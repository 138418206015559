import {Component, Input} from '@angular/core';

@Component({
    selector: 'label',
    template: `
        <span class="label label-{{color}}" [class.label-disabled]="disabled">
            <span class="label-indicator" *ngIf="!hideIndicator"></span>
            <span class="label-placeholder">{{placeholder}}</span>
        </span>
    `
})
export class LabelComponent {

    @Input()
    color: 'success' | 'warning' | 'basic' | 'danger' | 'info' = 'basic';

    @Input()
    disabled: boolean = false;

    @Input()
    placeholder: string = '';

    @Input()
    hideIndicator: boolean = false;

    constructor() {
    }

}
