import {
    ChangeDetectionStrategy,
    Component,
    ComponentFactoryResolver,
    EventEmitter,
    Input,
    OnChanges,
    Output, TemplateRef, Type, ViewChild, ViewContainerRef
} from "@angular/core";
import {BaseCell} from "../base-cell-component/base-cell";
import {BaseCellComponent} from "../base-cell-component/base-cell.component";

@Component({
    selector: 'calendar-days',
    template: `
        <ng-template></ng-template>
    `,
    styleUrls: ['./calendar-days.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarDaysComponent implements OnChanges {
    @Input() days: Date[] = [];

    @Input() selectedValue: Date | null = null;

    @Input() cellComponent: Type<BaseCell> = BaseCellComponent;
    /* @ts-ignore */
    @ViewChild(TemplateRef, { read: ViewContainerRef, static: true }) containerRef: ViewContainerRef;

    @Output() select: EventEmitter<Date> = new EventEmitter(true);

    constructor(
        private cfr: ComponentFactoryResolver
    ) {
    }

    ngOnChanges() {
        const factory = this.cfr.resolveComponentFactory(this.cellComponent);

        this.containerRef.clear();

        this.days.forEach((date: Date) => {
            const component = this.containerRef.createComponent(factory);
            this.patchWithContext(component.instance, date);
            component.changeDetectorRef.detectChanges();
        });
    }

    private patchWithContext(component: BaseCell, date: Date) {
        component.date = date;
        component.selectedValue = this.selectedValue;
        component.select.subscribe(this.select.emit.bind(this.select));
    }
}
