<div class="popup-wrapper" *ngIf="!loading">
    <div class="popup-header">
        <div class="body-text-1">Выбор логотипа</div>
        <button
                mat-icon-button
                disableRipple
                type="button"
                class="button-icon"
                color="primary"
                (click)="close()"
        >
            <mat-icon svgIcon="times"></mat-icon>
        </button>
    </div>
    <div class="popup-body">
        <div class="filter-row" [formGroup]="form">
            <mat-form-field appearance="fill" color="primary" class="input-select"
                            [class.input-select__opened]="resourceTypeOpened">
                <mat-label>Тип логотипа</mat-label>
                <mat-icon svgIcon="dropdown-arrow" matSuffix></mat-icon>
                <mat-select #resourceTypeSelect (openedChange)="resourceTypeOpened = $event" disableRipple
                            formControlName="resourceType" disableOptionCentering
                            [panelClass]="['dropdown-panel', 'dropdown-panel-advanced', 'dropdown-panel-advanced-220']">
                    <div appSimplebarScroller>
                        <mat-option *ngFor="let resourceType of getResourceTypes()" [value]="resourceType">
                            {{resourceType.name}}
                        </mat-option>
                    </div>
                    <div class="create-container" *ngIf="resourceTypeOpened">
                        <app-base-catalog
                                [placeholder]="'Добавить тип'"
                                [component]="resourceTypeCatalogComponent"
                                (create)="onResourceTypeCreate($event); resourceTypeSelect.close()"></app-base-catalog>
                    </div>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="resource-objects-container" appSimplebarScroller *ngIf="!tableLoading">
            <div class="resource-objects">
                <app-add-image (click)="addImage()"></app-add-image>

                <div class="resource-object" *ngFor="let resourceObject of getResourceObjects()" (click)="selectResourceObject(resourceObject)">
                    <img src="{{resourceObject.url}}" alt="">
                    <div class="resource-object-actions">
                        <button
                                mat-icon-button
                                disableRipple
                                type="button"
                                class="button-icon"
                                color="primary"
                                (click)="downloadResourceObject(resourceObject, $event)"
                        >
                            <mat-icon svgIcon="download"></mat-icon>
                        </button>
                        <button
                                mat-icon-button
                                disableRipple
                                type="button"
                                class="button-icon"
                                color="primary"
                                (click)="removeResourceObject(resourceObject, $event)"
                        >
                            <mat-icon svgIcon="basket"></mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="resource-objects-container" *ngIf="tableLoading">
            <div class="resource-objects">
                <app-add-image (click)="addImage()"></app-add-image>
                <skeleton-text [width]="200" [height]="200" [radius]="5" *ngFor="let resourceObject of [1,2,3,4]"></skeleton-text>
            </div>
        </div>
    </div>
</div>

<div class="popup-wrapper popup-skeleton" *ngIf="loading">
    <div class="popup-header">
        <div class="body-text-1"><skeleton-text [width]="141" [height]="18" [inverseColor]="true"></skeleton-text></div>
    </div>
    <div class="popup-body">
        <div class="filter-row">
            <skeleton-text [width]="236" [height]="44"></skeleton-text>
        </div>
        <div class="resource-objects-container" appSimplebarScroller>
            <div class="resource-objects">
                <skeleton-text [width]="200" [height]="200" [radius]="5" *ngFor="let resourceObject of [1,2,3,4,5]"></skeleton-text>
            </div>
        </div>
    </div>
</div>

