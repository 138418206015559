import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ImagePickerPopupComponent, ResourceObject} from './image-picker-popup.component';

@Injectable({
    providedIn: 'root'
})
export class ImagePickerPopupService {
    constructor(
        private readonly matDialog: MatDialog
    ) {
    }

    public async open(): Promise<ResourceObject> {
        return this.matDialog.open(ImagePickerPopupComponent, {
            panelClass: ['primary-popup-panel', 'default-popup-panel'],
            backdropClass: 'primary-popup-backdrop',
            restoreFocus: false,
            disableClose: true,
            autoFocus: false
        }).afterClosed().toPromise();
    }
}
