<div class="popup-wrapper" *ngIf="!loading">
    <div class="popup-header">
        <div class="body-text-1" *ngIf="!qa">Создать вопрос</div>
        <div class="body-text-1" *ngIf="qa">Редактировать вопрос</div>
        <button
                mat-icon-button
                disableRipple
                type="button"
                class="button-icon"
                color="primary"
                (click)="close(popupResult.CANCEL)"
        >
            <mat-icon svgIcon="times"></mat-icon>
        </button>
    </div>

    <div class="order-info">

        <div class="filters-lang">
                <mat-tab-group [selectedIndex]="lang.value" (selectedIndexChange)="onReferenceBookChange($event)" disableRipple
                               animationDuration="0ms">
                   <mat-tab>
                        <ng-template mat-tab-label>
                            {{getLangName(0).toUpperCase()}}<a style="color:red">*</a>
                        </ng-template>
                    </mat-tab>
    
                    <mat-tab>
                        <ng-template mat-tab-label>
                            {{getLangName(1).toUpperCase()}}
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>
            </div>
    </div>  

    <div *ngIf="qa" class="season-caption">
        Категория 
    </div> 
    <div *ngIf="qa" class="season-name">{{getQaCategoryName(findQaCategoryByUuid(qa.categoryUuid))}}</div>
    <div class="popup-body" [formGroup]="form">

        <div *ngIf="!qa" formGroupName="main" class="popup-body-row">
            <mat-form-field appearance="fill" color="primary" class="input-select"
                                    [class.input-select__opened]="qaCategoriesOpened">
                        <mat-label>Категория</mat-label>
                        <mat-icon svgIcon="dropdown-arrow" matSuffix></mat-icon>
                        <mat-select #qaCategorySelect (openedChange)="qaCategoriesOpened = $event" disableRipple
                                    formControlName="categoryUuid" disableOptionCentering
                                    [panelClass]="['dropdown-panel', 'dropdown-panel-advanced']"
                                    
                                    >
                            <div appSimplebarScroller>
                                <mat-option *ngFor="let qaCategory of qaCategories" [value]="qaCategory.uuid">
                                    {{qaCategory.name[getLangName(0)] ? qaCategory.name[getLangName(0)] : '-'}}
                                </mat-option>
                            </div>
                            <div class="create-container" *ngIf="qaCategoriesOpened">
                                <app-base-catalog
                                        [placeholder]="'Добавить категорию'"
                                        [component]="qaCategoryCatalogComponent"
                                        (create)="onQaCategoryCreate($event); qaCategorySelect.close()"></app-base-catalog>
                            </div>
                        </mat-select>
                    </mat-form-field>
        </div> 

        <div  class="popup-body-row popup-body-row-with-hint">
            <div *ngFor="let exp of form.controls | keyvalue"> 
                <div *ngIf="exp.key === getLangName(lang.value)" [formGroupName]="exp.key">
                        <div class="popup-body-row popup-body-row-with-hint">
                            <mat-form-field class="input-field" color="primary">
                                <mat-label>Вопрос<a style="color:red">*</a></mat-label>
                                <input type="text" autocomplete="off" matInput formControlName="question">
                            </mat-form-field>
                        </div>
                        

                </div>
            </div>
        </div>


        <div  class="popup-body-row popup-body-row-with-hint">
            <div *ngFor="let exp of form.controls | keyvalue"> 
                <div *ngIf="exp.key === getLangName(lang.value)" [formGroupName]="exp.key">
                        <!-- <div class="popup-body-row popup-body-row-with-hint">
                            <mat-form-field class="input-field" color="primary">
                                <mat-label>Ответ<a style="color:red">*</a></mat-label>
                                <input type="text" autocomplete="off" matInput formControlName="answer">
                            </mat-form-field>
                        </div> -->
                        <div style="font-size: 12px;padding-left: 4px; color: #839EB3;">Ответ:</div>
                        <div style="width: 456px;">
                            <quill-editor formControlName="answer" [styles]="editorStyle" [modules]="editorOptions"></quill-editor>
                        </div>
                </div>
            </div>
        </div>
    </div>


    <div class="error-container" *ngIf="error">
        <inline-alert-component [text]="error"></inline-alert-component>
    </div>


    <div class="popup-footer">
        <button
                mat-raised-button
                disableRipple
                type="button"
                class="button-primary-filled button-primary-filled-secondary"
                color="primary"
                [disabled]="form.invalid || waiting || !form.value.ru.question || !form.value.ru.answer"
                (click)="save()"
        >
            Сохранить
        </button>
    </div>
</div>


<div class="popup-wrapper" *ngIf="loading">
    <div class="popup-header">
        <div class="body-text-1">
            <skeleton-text [inverseColor]="true" [width]="281" [height]="18"></skeleton-text>
        </div>
    </div>
    <div class="popup-body">
        <div class="popup-body-row">
            <skeleton-text [height]="44"></skeleton-text>
        </div>
        <div class="popup-body-row popup-body-row-with-hint">
            <skeleton-text [height]="44"></skeleton-text>
            <skeleton-text [width]="192" [height]="14"></skeleton-text>
        </div>
    </div>
    <div class="popup-footer">
        <skeleton-text [height]="36"></skeleton-text>
    </div>
</div>
