import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {MatMenuTrigger} from '@angular/material/menu';
import {MatCheckbox} from '@angular/material/checkbox';

export interface FilterDataOption {
  name: string;

  [key: string]: any;
}

export class FilterDataSource {
  public options: FilterDataOption[] = [];
  public selected: FilterDataOption[] = [];

  constructor(options: FilterDataOption[], selected: FilterDataOption[] = []) {
    this.options = options;
    this.selected = selected;
  }

  public reset(): FilterDataSource {
    return new FilterDataSource(this.options);
  }
}


@Component({
  selector: 'filter-component',
  template: `
    <button
      mat-stroked-button
      disableRipple
      [matMenuTriggerRestoreFocus]="false"
      class="filter-button"
      [matMenuTriggerFor]="menu"
      [class.filter-button-opened]="!!trigger && trigger.menuOpen"
      [class.filter-button-selected]="dataSource.selected.length > 0"
    >
            <span class="filter-button-label">
                <ng-content select="[filter-placeholder]"></ng-content>
                <span *ngIf="dataSource.selected.length > 0">: {{dataSource.selected.length}}</span>
            </span>
      <mat-icon svgIcon="times" *ngIf="dataSource.selected.length > 0" (click)="$event.stopPropagation(); reset()"></mat-icon>
      <mat-icon svgIcon="filter-arrow"></mat-icon>
    </button>
    <mat-menu #menu="matMenu" class="filter-panel"
              (close)="onClose()">
      <div (click)="$event.stopPropagation()">
        <div class="filter-popup-header" *ngIf="confirmationRequired">
                    <span class="filter-popup-header-title">
                        <ng-content select="[filter-title]"></ng-content>
                    </span>
          <button
            mat-icon-button
            disableRipple
            type="button"
            class="button-icon"
            color="primary"
            (click)="close()"
          >
            <mat-icon svgIcon="times"></mat-icon>
          </button>
        </div>
        <div *ngIf="search" class="search-container">
          <mat-form-field class="input-field" color="primary">
            <mat-label>Поиск</mat-label>
            <input type="text" matInput id="search" [(ngModel)]="searchValue">
          </mat-form-field>
        </div>
        <div class="filter-popup-body" style="{{someStyle}}">
          <mat-list appSimplebarScroller>
            <ng-container *ngFor="let option of dataSource.options">
              <mat-list-item *ngIf="isOptionVisible(option)" (click)="toggleOption(filterOption, option)">
                <mat-checkbox #filterOption disableRipple (click)="$event.preventDefault()" [checked]="this.checkIncludes(option)"
                              class="input-checkbox">{{option.name}}</mat-checkbox>
              </mat-list-item>
            </ng-container>
          </mat-list>
        </div>
        <div class="filter-popup-footer" *ngIf="confirmationRequired">
          <button
            mat-button
            disableRipple
            type="button"
            class="button-primary-basic-text"
            color="primary"
            (click)="reset()"
          >
            Сбросить
          </button>
          <button
            mat-raised-button
            disableRipple
            type="button"
            class="button-primary-filled button-primary-filled-secondary"
            color="primary"
            (click)="confirm()"
          >
            Применить
          </button>
        </div>
      </div>
    </mat-menu>
  `
})
export class FilterComponent implements OnChanges {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger | undefined;
  @ViewChildren('filterOption') filterOptions: QueryList<MatCheckbox> | undefined;

  @Input()
  search: boolean = false;

  @Input()
  confirmationRequired: boolean = true;

  @Input()
  dataSource: FilterDataSource = new FilterDataSource([]);

  @Input()
  someStyle: string = '';


  @Output()
  onSelectionChange: EventEmitter<FilterDataOption[]> = new EventEmitter<FilterDataOption[]>();

  private changes: Map<FilterDataOption, MatCheckbox> = new Map<FilterDataOption, MatCheckbox>();

  searchValue = '';

  ngOnInit() {
    this.applyChanges();

  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataSource) {
      this.changes.clear();
    }
  }

  toggleOption(matCheckbox: MatCheckbox, option: any) {
    matCheckbox.toggle();
    if (!this.changes.has(option)) {
      this.changes.set(option, matCheckbox);
    }

    if (!this.confirmationRequired) {
      this.applyChanges();
      this.onSelectionChange.emit(this.dataSource.selected);
      this.changes.clear();
    }
  }

  checkIncludes(option: any) {
    var found = false;
    for (var i = 0; i < this.dataSource.selected.length; i++) {
      if ((this.dataSource.selected[i].uuid === option.uuid && this.dataSource.selected[i].uuid && option.uuid) || (this.dataSource.selected[i].key && option.key && this.dataSource.selected[i].key === option.key)) {

        found = true;
        break;
      }
    }
    return found;
  }

  confirm() {
    this.applyChanges();
    this.onSelectionChange.emit(this.dataSource.selected);
    this.close();
  }

  reset() {
    this.filterOptions?.toArray().forEach(filterOption => filterOption.checked = false);
    this.dataSource.selected.length = 0;
    this.onSelectionChange.emit(this.dataSource.selected);
    this.close();
  }

  close() {
    this.trigger?.closeMenu();
  }

  onClose() {
    this.revertChanges();
  }

  private applyChanges() {
    this.changes.forEach((checkbox: MatCheckbox, option: FilterDataOption) => {

      const index = this.dataSource.selected.indexOf(option);
      if (checkbox.checked) {
        if (index === -1) {
          this.dataSource.selected.push(option);
        }
      } else {
        if (index > -1) {
          this.dataSource.selected.splice(index, 1);
        } else {

          for (var i = 0; i < this.dataSource.selected.length; i++) {
            if ((this.dataSource.selected[i].uuid === option.uuid && this.dataSource.selected[i].uuid && option.uuid) || (this.dataSource.selected[i].key && option.key && this.dataSource.selected[i].key === option.key)) {
              this.dataSource.selected.splice(i, 1);
            }
          }

        }
      }
    });
  }

  private revertChanges() {
    this.changes.forEach((checkbox: MatCheckbox, option: FilterDataOption) => {
      const index = this.dataSource.selected.indexOf(option);
      checkbox.checked = index > -1;
    });
    this.changes.clear();
  }

  public isOptionVisible(option: any) {
    if (this.search) {

      if (this.searchValue?.length) {
        return option?.name?.toLowerCase()?.indexOf(this.searchValue?.toLowerCase()) > -1;
      }
    }

    return true;
  }
}

