<div class="event-session-settings-header" *ngIf="!loading">
    <div class="event-session-settings-close">
        <button
                mat-icon-button
                disableRipple
                type="button"
                class="button-icon"
                color="primary"
                (click)="close()"
        >
            <mat-icon svgIcon="times"></mat-icon>
        </button>
    </div>
    <div class="event-session-settings-header-title body-text-1">
        Настройка события
    </div>
    <div class="event-session-settings-event-name body-text-1">
        {{getEventSession()?.eventName}}
    </div>
    <div class="event-session-settings-event-date body-text-1" *ngIf="getEventSession()?.consistType === 'TICKET'">
        {{getEventSession()?.day}}
        <mat-icon svgIcon="time"></mat-icon>
        {{getEventSession()?.time}}
    </div>
</div>
<div class="event-session-settings-header" *ngIf="loading">
    <div class="event-session-settings-close">

    </div>
    <div class="event-session-settings-header-title body-text-2">
        <skeleton-text [height]="18" [width]="170"></skeleton-text>
    </div>
    <div class="event-session-settings-event-name body-text-2">
        <skeleton-text [height]="18" [width]="250"></skeleton-text>
    </div>
    <div class="event-session-settings-event-date body-text-2">
        <skeleton-text [height]="18" [width]="166"></skeleton-text>
    </div>
</div>


<div class="event-session-settings-container">
    <div class="event-session-settings-sidebar">
        <div
                class="event-session-settings-node"
                [class.active]="getCurrentState() === state.EVENT_SESSION_INFO || getCurrentState() === state.SUBSCRIPTION_SESSION_INFO"
                (click)="setInfoState()"
        >Событие
        </div>
        <div
                class="event-session-settings-node"
                [class.active]="getCurrentState() === state.SEASON_TICKET_CONSISTS || getCurrentState() === state.EVENT_TICKET_CONSISTS"
                (click)="setTicketConsistState()"
        >Состав абонемента
        </div>
        <div
                class="event-session-settings-node"
                [class.active]="getCurrentState() === state.TICKET_LAYOUTS"
                (click)="setCurrentState(state.TICKET_LAYOUTS)"
        >Макеты билетов
        </div>
        <div
                class="event-session-settings-node"
                [class.active]="getCurrentState() === state.PRICE_RATES"
                (click)="setCurrentState(state.PRICE_RATES)"
        >Расценки
        </div>
        <div
                class="event-session-settings-node"
                [class.active]="getCurrentState() === state.OPEN_SALES"
                (click)="setCurrentState(state.OPEN_SALES)"
        >Открытие продаж
        </div>
        <div
                class="event-session-settings-node"
                [class.active]="getCurrentState() === state.SALE_LIMITS"
                (click)="setCurrentState(state.SALE_LIMITS)"
        >Ограничение продаж
        </div>
        <div
                class="event-session-settings-node"
                [class.active]="getCurrentState() === state.ANNOUNCEMENT"
                (click)="setCurrentState(state.ANNOUNCEMENT)"
        >Анонс</div>
    </div>
    <div class="event-session-settings-main">
        <ng-container *ngIf="getCurrentState() === state.EVENT_SESSION_INFO">
            <app-event-session-info></app-event-session-info>
        </ng-container>
        <ng-container *ngIf="getCurrentState() === state.SUBSCRIPTION_SESSION_INFO">
            <app-subscription-session-info></app-subscription-session-info>
        </ng-container>
        <ng-container *ngIf="getCurrentState() === state.SEASON_TICKET_CONSISTS">
            <app-season-ticket-consist></app-season-ticket-consist>
        </ng-container>
        <ng-container *ngIf="getCurrentState() === state.EVENT_TICKET_CONSISTS">
            <app-event-ticket-consist></app-event-ticket-consist>
        </ng-container>
        <ng-container *ngIf="getCurrentState() === state.TICKET_LAYOUTS">
            <app-ticket-layouts></app-ticket-layouts>
        </ng-container>
        <ng-container *ngIf="getCurrentState() === state.PRICE_RATES">
            <app-price-rates></app-price-rates>
        </ng-container>
        <ng-container *ngIf="getCurrentState() === state.OPEN_SALES">
            <app-open-sales></app-open-sales>
        </ng-container>
        <ng-container *ngIf="getCurrentState() === state.SALE_LIMITS">
            <app-sale-limits></app-sale-limits>
        </ng-container>
        <ng-container *ngIf="getCurrentState() === state.ANNOUNCEMENT">
            <app-announcement></app-announcement>
        </ng-container>
    </div>
</div>
