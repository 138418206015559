import {Component, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EventSessionSettingsService, SettingsLoadingStatus, SettingsPopupState} from '../event-session-settings.service';
import {Subscription} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FullscreenLoaderService} from '../../../../shared/ui-kit/fullscreen-loader/fullscreen-loader.service';
import {formatNativeDate} from '../../../shared/utils/date-utils';
import {SortState} from '../../../../shared/ui-kit/sort/sort.component';

interface EventSessionLimit {
    clientMaxQuant: number;
    customerName: string;
    customerUuid: string;
    onSale: boolean;
    orderPaymentMinutes: number;
    reserveMaxQuant: number;
    reserveMinutes: number;
    saleBegin: string;
    saleEnd: string;
    uuid: string;
}

@Component({
    selector: 'app-sale-limits',
    templateUrl: './sale-limits.component.html',
    styleUrls: ['./sale-limits.component.scss']
})
export class SaleLimitsComponent implements OnDestroy {

    public tableOriginal: EventSessionLimit[] = [];
    public table: EventSessionLimit[] = [];

    private readonly eventSessionLoadingSubscription: Subscription;

    public readonly form: FormGroup;

    public tableColumns: string[] = ['client', 'client-max-quantity', 'reserve-max-quantity', 'reserve-minutes', 'order-payment-minutes', 'actions'];

    public error: string | null = null;

    public loading = true;

    public editingLimit: EventSessionLimit | null = null;

    public customPatterns = {
        0: {pattern: new RegExp('[1-9]')},
        9: {pattern: new RegExp('[0-9]')}
    };

    private sort: {
        field: string,
        direction: SortState
    } = {field: '', direction: SortState.DEFAULT};

    constructor(
        private readonly httpClient: HttpClient,
        private readonly eventSessionSettingsService: EventSessionSettingsService,
        private readonly fb: FormBuilder,
        private readonly fullscreenLoaderService: FullscreenLoaderService
    ) {
        this.eventSessionLoadingSubscription = this.eventSessionSettingsService.loading.subscribe(async (status) => {
            if (status === SettingsLoadingStatus.LOADED) {
                const eventSessionUuid = this.eventSessionSettingsService.getEventSessionUuid() as string;
                await this.loadLimits(eventSessionUuid);
                this.loading = false;
            }
        });

        this.form = this.fb.group({
            clientMaxQuant: [null, [Validators.required]],
            reserveMaxQuantity: [null, [Validators.required]],
            reserveMinutes: [null, [Validators.required]],
            orderPaymentMinutes: [null, [Validators.required]],
        });
    }

    ngOnDestroy(): void {
        this.eventSessionLoadingSubscription.unsubscribe();
    }

    private async loadLimits(eventSessionUuid: string): Promise<void> {
        try {
            const eventSessionLimits = await this.httpClient
                .get<EventSessionLimit[]>(`/api/event-session-limit/all/by-event-session/${eventSessionUuid}`).toPromise();

            this.tableOriginal = eventSessionLimits.map(eventSessionLimit => {
                return {
                    clientMaxQuant: eventSessionLimit.clientMaxQuant,
                    uuid: eventSessionLimit.uuid,
                    customerName: eventSessionLimit.customerName,
                    customerUuid: eventSessionLimit.customerUuid,
                    saleBegin: eventSessionLimit.saleBegin,
                    saleEnd: eventSessionLimit.saleEnd,
                    onSale: eventSessionLimit.onSale,
                    orderPaymentMinutes: eventSessionLimit.orderPaymentMinutes,
                    reserveMaxQuant: eventSessionLimit.reserveMaxQuant,
                    reserveMinutes: eventSessionLimit.reserveMinutes
                };
            });

            this.setPageData();

        } catch (e) {

        }
    }

    public resetForm(): void {
        this.form.reset();
        this.error = null;
    }

    public editLimit(limit: EventSessionLimit): void {
        this.editingLimit = limit;
        this.form.patchValue({
            clientMaxQuant: limit.clientMaxQuant,
            reserveMaxQuantity: limit.reserveMaxQuant,
            reserveMinutes: limit.reserveMinutes,
            orderPaymentMinutes: limit.orderPaymentMinutes
        });
    }

    public async updateLimit(limit: EventSessionLimit): Promise<void> {
        this.error = null;
        this.fullscreenLoaderService.open();
        try {
            const model = {
                clientMaxQuant: this.form.value.clientMaxQuant,
                orderPaymentMinutes: this.form.value.orderPaymentMinutes,
                reserveMaxQuant: this.form.value.reserveMaxQuantity,
                reserveMinutes: this.form.value.reserveMinutes,
                uuid: limit.uuid
            };

            const eventSessionLimit = await this.httpClient
                .put<EventSessionLimit>(`/api/event-session-limit/update-sales`, model).toPromise();

            limit.clientMaxQuant = eventSessionLimit.clientMaxQuant;
            limit.orderPaymentMinutes = eventSessionLimit.orderPaymentMinutes;
            limit.reserveMinutes = eventSessionLimit.reserveMinutes;
            limit.reserveMaxQuant = eventSessionLimit.reserveMaxQuant;

            this.setPageData();

            this.resetForm();

            this.editingLimit = null;
        } catch (e) {
            this.error = e.error.message;
        }
        this.fullscreenLoaderService.close();
    }

    public onSortChange(field: string, direction: SortState): void {
        this.sort = {field, direction};
        this.setPageData();
    }

    public getSortDirection(field: string): SortState {
        if (this.sort.field === field) {
            return this.sort.direction;
        }
        return SortState.DEFAULT;
    }

    private setPageData(): void {
        const tableCopy = this.tableOriginal.slice();

        if (this.sort.direction !== SortState.DEFAULT) {
            tableCopy.sort((a, b): number => {
                let compareResult = 0;
                const left = (a as any)[this.sort.field];
                const right = (b as any)[this.sort.field];
                if (left === null || left === undefined) {
                    compareResult = 1;
                } else if (right === null || right === undefined) {
                    compareResult = -1;
                } else {
                    if (left > right) {
                        compareResult = 1;
                    } else {
                        compareResult = -1;
                    }
                }
                if (this.sort.direction === SortState.DESC) {
                    compareResult *= -1;
                }
                return compareResult;
            });
        }
        this.table = tableCopy;
    }

    public next(): void {
        this.eventSessionSettingsService.setCurrentState(SettingsPopupState.ANNOUNCEMENT);
    }
}
