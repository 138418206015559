import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from "@angular/core";

export class PaginationConfig {
    constructor(
        public page: number = 0,
        public size: number = 0,
        public total: number = 0
    ) {
    }
}

@Component({
    selector: 'pagination-component',
    template: `
        <span class="pagination-wrapper">
            <span class="pagination-label">Результаты:</span>
            <span class="pagination-label pagination-label-bold">{{from}}-{{to}} из {{config.total}}</span>
            
            <span class="pagination-actions">
                <button
                        mat-icon-button
                        disableRipple
                        type="button"
                        class="button-icon"
                        [disabled]="config.page === 0"
                        color="primary"
                        (click)="first()"
                >
                    <mat-icon svgIcon="pagination-first"></mat-icon>
                </button>
                <button
                        mat-icon-button
                        disableRipple
                        type="button"
                        class="button-icon"
                        [disabled]="config.page === 0"
                        color="primary"
                        (click)="prev()"
                >
                    <mat-icon svgIcon="pagination-prev"></mat-icon>
                </button>
                <button
                        mat-icon-button
                        disableRipple
                        type="button"
                        class="button-icon"
                        [disabled]="config.page === pages"
                        color="primary"
                        (click)="next()"
                >
                    <mat-icon svgIcon="pagination-next"></mat-icon>
                </button>
                <button
                        mat-icon-button
                        disableRipple
                        type="button"
                        class="button-icon"
                        [disabled]="config.page === pages"
                        color="primary"
                        (click)="last()"
                >
                    <mat-icon svgIcon="pagination-last"></mat-icon>
                </button>
            </span>
        </span>
    `
})
export class PaginationComponent implements OnChanges {
    @Input()
    config: PaginationConfig = new PaginationConfig();

    @Output()
    onPageChange: EventEmitter<number> = new EventEmitter<number>();

    public from: number = 0;
    public to: number = 0;
    public pages: number = 0;

    ngOnChanges(changes: SimpleChanges): void {
        this.calculatePageable();

        if (this.config.total > 0) {
            this.pages = Math.ceil(this.config.total / this.config.size) - 1;
        } else {
            this.pages = 0;
        }
    }

    first() {
        this.config.page = 0;
        this.calculatePageable();
        this.onPageChange.emit(this.config.page);
    }

    prev() {
        this.config.page--;
        this.calculatePageable();
        this.onPageChange.emit(this.config.page);
    }

    next() {
        this.config.page++;
        this.calculatePageable();
        this.onPageChange.emit(this.config.page);
    }

    last() {
        this.config.page = this.pages;
        this.calculatePageable();
        this.onPageChange.emit(this.config.page);
    }

    private calculatePageable() {
        if (this.config.total > 0) {
            this.from = this.config.page * this.config.size + 1;
        } else {
            this.from = 0;
        }
        this.to = Math.min((this.config.page + 1) * this.config.size, this.config.total);
    }
}
