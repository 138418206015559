import {NgModule} from "@angular/core";
import {ColorPickerDirective} from "./color-picker.directive";

@NgModule({
    imports: [],
    declarations: [
        ColorPickerDirective
    ],
    exports: [
        ColorPickerDirective
    ]
})
export class ColorPickerModule {
}
