<button *ngIf="!white"
        mat-button
        disableRipple
        class="button-menu-control"
        [matMenuTriggerRestoreFocus]="false"
        [matMenuTriggerFor]="menu"
        [class.button-menu-opened]="!!trigger && trigger.menuOpen"
        (onMenuOpen)="onMenuOpen()"
        #buttonMenuControl
>
    {{getCaption()}}
    <mat-icon svgIcon="arrow"></mat-icon>
</button>
<button *ngIf="white" style="background: white;"
        mat-button
        disableRipple
        class="button-menu-control"
        [matMenuTriggerRestoreFocus]="false"
        [matMenuTriggerFor]="menu"
        [class.button-menu-opened]="!!trigger && trigger.menuOpen"
        (onMenuOpen)="onMenuOpen()"
        #buttonMenuControl
>
    {{getCaption()}}
    <mat-icon svgIcon="arrow"></mat-icon>
</button>
<mat-menu #menu="matMenu" class="button-menu-panel" style="max-width: initial">
    <div class="button-menu-panel-container" #buttonMenuPanelContainer (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
        <div class="input-date-time-range-picker-container">
            <range-calendar [start]="form.controls.startDate.value" [end]="form.controls.endDate.value" (dateRangeChange)="dateRangeChange($event)"></range-calendar>
            <div class="time-range" [formGroup]="form">
                <div class="time-range-start">
                    <label class="body-text-2">Начало:</label>
                    <div class="inputs-container">
                        <div class="time-range-date-wrapper">
                            <input type="text" formControlName="start" mask="d0.M0.0000" [dropSpecialCharacters]="false" [leadZeroDateTime]="true">
                        </div>
                        <div class="time-range-wrapper">
                            <input type="text" formControlName="startTime" mask="Hh:m0" [dropSpecialCharacters]="false" [leadZeroDateTime]="true">
                        </div>
                    </div>
                </div>
                <div class="time-range-end">
                    <label class="body-text-2">Окончание:</label>
                    <div class="inputs-container">
                        <div class="time-range-date-wrapper">
                            <input type="text" formControlName="end" mask="d0.M0.0000" [dropSpecialCharacters]="false" [leadZeroDateTime]="true">
                        </div>
                        <div class="time-range-wrapper">
                            <input type="text" formControlName="endTime" mask="Hh:m0" [dropSpecialCharacters]="false" [leadZeroDateTime]="true">
                        </div>
                    </div>
                </div>
            </div>
            <div class="error-container" *ngIf="error">
                <inline-alert-component text="Неверный интервал"></inline-alert-component>
            </div>
            <div class="actions-container">
                <button
                        mat-button
                        disableRipple
                        type="button"
                        class="button-primary-basic-text"
                        color="primary"
                        (click)="close()"
                >
                    Отмена
                </button>
                <button
                        mat-raised-button
                        disableRipple
                        type="button"
                        class="button-primary-filled button-primary-filled-secondary"
                        color="primary"
                        [disabled]="form.invalid"
                        (click)="apply()"
                >
                    Выбрать
                </button>
            </div>
        </div>
    </div>
</mat-menu>

