<div class="popup-wrapper">
    <div class="popup-header">
        <div class="body-text-1">Список секторов</div>
        <button
                mat-icon-button
                disableRipple
                type="button"
                class="button-icon"
                color="primary"
                (click)="close()"
        >
            <mat-icon svgIcon="times"></mat-icon>
        </button>
    </div>
    <div class="popup-body">

        <div style="padding-bottom: 15px;">Введите через "," ID секторов</div>

        <!-- <div class="popup-body" [formGroup]="form">

            <div  class="popup-body-row popup-body-row-with-hint">
                <div *ngFor="let exp of form.controls | keyvalue"> 
                    <div *ngIf="exp.key === getLangName(lang.value)" [formGroupName]="exp.key">
                            <div class="popup-body-row popup-body-row-with-hint">
                                <mat-form-field class="input-field" color="primary">
                                    <mat-label>Название<a style="color:red">*</a></mat-label>
                                    <input type="text" autocomplete="off" matInput formControlName="caption">
                                </mat-form-field>
                            </div>
                    </div>
                </div>
            </div>
    
           
        </div> -->

            <div class="order-data" [formGroup]="form">
                <div formGroupName="main">  
                    <div class="order-info-column4" style="display: flex;">
                        <mat-form-field class="input-field" color="primary">
                            <mat-label>Сектора</mat-label>
                            <!-- <input type="text" autocomplete="off" matInput formControlName="{{data}}"> -->
                            <input type="text" autocomplete="off" matInput formControlName="sectors">
                        </mat-form-field>
                    </div>
                </div>
            </div>
    

        <div class="popup-footer">
            <button
                    mat-raised-button
                    disableRipple
                    type="button"
                    class="button-primary-filled button-primary-filled-secondary"
                    color="primary"
                    (click)="selectSectors(this.form.value.main.sectors)"
            >
                Выбрать
            </button>
        </div>
    </div>
        
        <div class="error-container" *ngIf="error">
            <inline-alert-component [text]="error"></inline-alert-component>
        </div>

</div>