import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormBuilder, FormGroup} from '@angular/forms';
import {Qa, QaCategory} from '../../shared/types/events';
import {HttpClient} from '@angular/common/http';
import {SortState} from '../../../shared/ui-kit/sort/sort.component';
import {FullscreenLoaderService} from '../../../shared/ui-kit/fullscreen-loader/fullscreen-loader.service';
import { QaCategoryCatalogComponent } from 'src/app/components/reference-book/qa-category-catalog/qa-category-catalog.component';

interface TableQaCategory extends QaCategory {
    selected?: boolean;
}

export enum QaCreatePopupResult {
    CANCEL,
    SAVE,
    SAVE_AND_CREATE
}

export interface ResourceObject {
    lastModified: string;
    name: string;
    size: number;
    url: string;
    resourceUri: string;
}

@Component({
    selector: 'app-qa-create-popup',
    templateUrl: './qa-create-popup.component.html',
    styleUrls: ['./qa-create-popup.component.scss']
})
export class QaCreatePopupComponent implements OnInit {

    public lang: FormControl = new FormControl(0);
    form: FormGroup;
    public loading = true;
    public waiting = false;
    public error: string | null = null;
    popupResult = QaCreatePopupResult;
    public qaCategoryCatalogComponent = QaCategoryCatalogComponent;
    private langName: Map<number, string> = new Map<number, string>([
        [0, 'ru'],
        [1, 'en']
    ]);   

    public editorStyle = {
        height: '300px'
    };

    public editorOptions = {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
          ['blockquote', 
          //'code-block'
        ],
      
          [{ 'header': 1 }, { 'header': 2 }],               // custom button values
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
          [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
          [{ 'direction': 'rtl' }],                         // text direction
      
       //   [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      
        //  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        //  [{ 'font': [] }],
          [{ 'align': [] }],
      
          ['clean'],                                         // remove formatting button
      
          ['link', 'image'//, 'video'
        ]                         // link and image, video
        ]
      };
      
    query = '';
    private sort: {
        field: string,
        direction: SortState
    } = {field: 'name', direction: SortState.ASC};

    public qa: Qa;
    public qaCategories: QaCategory[] = [];
    qaCategoriesOpened = false;

    constructor(
        private readonly dialogRef: MatDialogRef<QaCreatePopupComponent>,
        private readonly fb: FormBuilder,
        private readonly httpClient: HttpClient,
        private readonly fullscreenLoaderService: FullscreenLoaderService,
        //@Inject(MAT_DIALOG_DATA) public readonly tournament: Qa,
        @Inject(MAT_DIALOG_DATA) public readonly xz: any
    ) {
        this.qa = xz?.qa;
        this.qaCategories = xz?.qaCategories;
        
        this.form = this.fb.group({});
        const main = this.fb.group({
            uuid: [null],
            categoryUuid: [null]
          });
        this.form.addControl('main', main);
        
        this.langName.forEach((langName) => {
            const lang = this.fb.group({
                question: [null],
                answer: [null]
              });
            this.form.addControl(langName, lang);
        });
    }

    async ngOnInit(): Promise<void> {
        this.loading = true;
        if (this.qaCategories) {
            this.sortqaCategories()
        }
        if (this.qa && this.qaCategories) {
            this.form.patchValue({
                main: {
                    uuid: this.qa.uuid,
                    categoryUuid: this.qa.categoryUuid
                    }
                });
                this.langName.forEach((langName) => {
                    this.form.patchValue({
                        [langName] : {
                            question: this.qa.question[langName],
                            answer: this.qa.answer[langName]
                        }
                    });
                });
        }
        this.loading = false;
    }

    private sortqaCategories(): void {
        const query = this.query.toLowerCase();

        const qaCategoriesCopy = this.qaCategories
            .filter((tableqaCategorie: TableQaCategory) => {
                return tableqaCategorie.name.ru.toLowerCase().includes(query);
            });
        if (this.sort.direction !== SortState.DEFAULT) {
            
            qaCategoriesCopy.sort((a, b): number => {
                let compareResult = 0;
                const left = (a as any)[this.sort.field].ru.toLowerCase();
                const right = (b as any)[this.sort.field].ru.toLowerCase();
                if (left === null || left === undefined) {
                    compareResult = 1;
                } else if (right === null || right === undefined) {
                    compareResult = -1;
                } else {
                    if (left > right) {
                        compareResult = 1;
                    } else {
                        compareResult = -1;
                    }
                }
                if (this.sort.direction === SortState.DESC) {
                    compareResult *= -1;
                }
                return compareResult;
            });
        }
        this.qaCategories = qaCategoriesCopy;
    }

    public close(result: QaCreatePopupResult, data?: any): void {
        this.dialogRef.close({
            result,
            data
        });
    }

    public async save(): Promise<void> {
        try {
            await this._save();
            this.close(QaCreatePopupResult.SAVE);
        } catch (e) {

        }
    }

    public async _save(): Promise<Qa> {

        let question = {};
        let answer = {};
        this.langName.forEach((langName) => { 
            if (this.form.value[langName].question) {
                question = Object.assign({[langName]: this.form.value[langName].question ? this.form.value[langName].question : ''}, question);
            } 
            if (this.form.value[langName].answer) {
                answer = Object.assign({[langName]: this.form.value[langName].answer ? this.form.value[langName].answer : ''}, answer);
            } 

        });

        const model: any = {
            uuid: this.form.value.main?.uuid ? this.form.value.main?.uuid : '',
            categoryUuid : this.form.value.main.categoryUuid,
            question: question,
            answer: answer
        };
        this.waiting = true;
        this.error = null;
        this.form.disable();
        try {
           if (this.qa) {
               return await this.httpClient.put<Qa>(`/api/faq/qa/update`, model).toPromise();
            } else {
                 return await this.httpClient.post<Qa>(`/api/faq/qa/create`, model).toPromise();
             }
        } catch (e) {
            //@ts-ignore
            this.error = e.error.message;
            this.form.enable();
            this.waiting = false;
            throw e;
        }
    }

    public onReferenceBookChange(tab: number): void {
        this.lang.setValue(tab);
    }

    public getLangName(index: number): string {
        return (this.langName.get(index)) as string;
    }

    public findQaCategoryByUuid(uuid: string): QaCategory | null {
        return this.qaCategories.find(qaCategory => qaCategory.uuid === uuid) || null;
    }

    public getQaCategoryName (qaCategory : QaCategory | null) {
        if (qaCategory) {
            return qaCategory.name.ru;
        } else return null;
    }

    public onQaCategoryCreate(qaCategory: QaCategory): void {
        this.qaCategories.push(qaCategory);
        this.form.patchValue({
            main: {
                qaCategotyUuid: qaCategory.uuid
                }
            });
    }
}
