import {
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    EventEmitter,
    Input,
    Output,
    Type,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import {BaseCatalog} from './base-catalog';

@Component({
    selector: 'app-base-catalog',
    templateUrl: './base-catalog.component.html',
    styleUrls: ['./base-catalog.component.scss']
})
export class BaseCatalogComponent<T> {

    public state: 'OPENED' | 'CLOSED' = 'CLOSED';

    /* @ts-ignore */
    @ViewChild('vc', {read: ViewContainerRef}) vc: ViewContainerRef;

    @Input()
    placeholder = '';

    @Input()
    component: Type<BaseCatalog<T>> | null = null;

    @Input()
    componentContext: Record<string, any> = {};

    @Output()
    create: EventEmitter<T> = new EventEmitter<T>();

    constructor(
        private readonly cfr: ComponentFactoryResolver
    ) {
    }

    public open(): void {
        this.state = 'OPENED';

        setTimeout(() => {
            if (this.component === null) {
                return;
            }
            const factory = this.cfr.resolveComponentFactory(this.component);

            this.vc.clear();

            const component = this.vc.createComponent(factory);

            this.patchWithContext(component);
            component.changeDetectorRef.detectChanges();
        });
    }

    public close(): void {
        this.state = 'CLOSED';
    }

    private patchWithContext(component: ComponentRef<BaseCatalog<T>>): void {
        component.instance.create.subscribe(this.create.emit.bind(this.create));
        component.instance.close.subscribe(() => {
            this.close();
            component.destroy();
            this.vc.clear();
        });

        Object.entries(this.componentContext)
            .forEach(([key, value]) => {
                component.instance[key] = value;
            });
    }

}
