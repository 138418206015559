import {Component, HostBinding, Input} from '@angular/core';

@Component({
    selector: 'skeleton-text',
    templateUrl: './skeleton-text.component.html',
    styleUrls: ['./skeleton-text.component.scss']
})
export class SkeletonTextComponent {
    @Input()
    height = 24;

    @Input()
    width = 100;

    @Input()
    radius = 5;

    @Input()
    inverseColor = false;

    @HostBinding('style.height.px')
    get getHeight(): number {
        return this.height;
    }

    @HostBinding('style.width.px')
    get getWidth(): number {
        return this.width;
    }

    @HostBinding('style.border-radius.px')
    get getRadius(): number {
        return this.radius;
    }

    @HostBinding('class.inverse-color')
    get getInverseColor(): boolean {
        return this.inverseColor;
    }

}
