import {Injectable} from "@angular/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SnackBarComponent} from "./snack-bar.component";

@Injectable({
    providedIn: 'root'
})
export class SnackBarService {
    constructor(
        private readonly matSnackBar: MatSnackBar
    ) {
    }

    show(text: string) {
        this.matSnackBar.openFromComponent(SnackBarComponent, {
            data: {text},
            duration: 5000,
            horizontalPosition: 'right',
            verticalPosition: 'top'
        });

    }
}
