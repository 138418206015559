import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, Type,} from '@angular/core';
import {CalendarService} from "./calendar.service";
import {BaseRangeCell} from "./components/base-cell-component/base-range-cell";
import {BaseRangeCellComponent} from "./components/base-cell-component/base-range-cell.component";

@Component({
    selector: 'range-calendar',
    templateUrl: './range-calendar.component.html',
    styleUrls: ['./calendar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RangeCalendarComponent implements OnChanges {
    private _today = new Date();
    public today = new Date(this._today.getFullYear(), this._today.getMonth(), this._today.getDate()).getTime();

    private leftDate = new Date();
    private rightDate = this.calendarService.nextMonth(this.leftDate);

    public leftDays: Date[] = [];
    public rightDays: Date[] = [];

    public startDate: Date | null = null;
    public endDate: Date | null = null;

    public hoveredDate: Date | null = null;

    @Input() start: Date | null = null;

    @Input() end: Date | null = null;

    @Input() cellComponent: Type<BaseRangeCell> = BaseRangeCellComponent;

    @Output() dateRangeChange: EventEmitter<{ start: Date | null, end: Date | null }> = new EventEmitter(true);

    constructor(
        private calendarService: CalendarService
    ) {
        this.setDays();
    }

    ngOnChanges(): void {
        this.startDate = this.start;
        this.endDate = this.end;
    }

    prevMonth() {
        this.rightDate = this.leftDate;
        this.leftDate = this.calendarService.prevMonth(this.leftDate);
        this.setDays();
    }

    nextMonth() {
        this.leftDate = this.rightDate;
        this.rightDate = this.calendarService.nextMonth(this.rightDate);
        this.setDays();
    }

    public getLeftMonthName() {
        return this.calendarService.getMonthName(this.leftDate);
    }

    public getRightMonthName() {
        return this.calendarService.getMonthName(this.rightDate);
    }

    public getLeftYear() {
        return this.leftDate.getFullYear();
    }

    public getRightYear() {
        return this.rightDate.getFullYear();
    }

    private setDays() {
        this.leftDays = this.calendarService.createDaysGrid(new Date(this.leftDate.getFullYear(), this.leftDate.getMonth(), 1));
        this.rightDays = this.calendarService.createDaysGrid(new Date(this.rightDate.getFullYear(), this.rightDate.getMonth(), 1))
    }

    onDateChange(date: Date) {
        if (this.startDate && !this.endDate) {
            if (date < this.startDate) {
                this.endDate = this.startDate;
                this.startDate = date;
            } else {
                this.endDate = date;
            }
        } else {
            this.startDate = date;
            this.endDate = null;
        }
        this.dateRangeChange.emit({start: this.startDate, end: this.endDate});
    }

    onDateHover(date: Date): void {
        this.hoveredDate = date;
    }
}
