import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {
    ResourceType,
    ResourceTypeCatalogComponent
} from '../../components/reference-book/resource-type-catalog/resource-type-catalog.component';
import {HttpClient} from '@angular/common/http';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {switchMap, takeUntil} from 'rxjs/operators';
import {FullscreenLoaderService} from '../../../shared/ui-kit/fullscreen-loader/fullscreen-loader.service';

export interface ResourceObject {
    lastModified: string;
    name: string;
    size: number;
    url: string;
}

@Component({
    selector: 'app-image-picker-popup',
    templateUrl: './image-picker-popup.component.html',
    styleUrls: ['./image-picker-popup.component.scss']
})
export class ImagePickerPopupComponent implements OnInit, OnDestroy {

    public resourceTypeOpened = false;
    public resourceTypeCatalogComponent = ResourceTypeCatalogComponent;

    private resourceTypes: ResourceType[] = [];

    public loading = true;
    public tableLoading = true;

    public form: FormGroup;

    private $destroy: Subject<void> = new Subject<void>();

    private resourceObjects: ResourceObject[] = [];

    constructor(
        private readonly dialogRef: MatDialogRef<ImagePickerPopupComponent>,
        private readonly httpClient: HttpClient,
        private readonly fb: FormBuilder,
        private readonly fullscreenLoaderService: FullscreenLoaderService
    ) {
        this.form = this.fb.group({
            resourceType: [null]
        });

        this.form.controls.resourceType.valueChanges
            .pipe(
                takeUntil(this.$destroy),
                switchMap(() => {
                    this.tableLoading = true;
                    const resourceTypeName = this.form.controls.resourceType.value.name;
                    return this.httpClient.get<ResourceObject[]>(`/api/resource/objects/${resourceTypeName}`);
                })
            )
            .subscribe((resourceObjects) => {
                this.tableLoading = false;
                this.resourceObjects = resourceObjects;
            });
    }

    ngOnInit(): void {
        this.load();
    }

    ngOnDestroy(): void {
        this.$destroy.next();
        this.$destroy.complete();
    }

    public close(): void {
        this.dialogRef.close();
    }

    private async load(): Promise<void> {
        this.loading = true;
        await this.loadResourceTypes();
        if (this.resourceTypes.length > 0) {
            this.form.controls.resourceType.setValue(this.resourceTypes[0]);
        }
        this.loading = false;
    }

    public getResourceTypes(): ResourceType[] {
        return this.resourceTypes;
    }

    private async loadResourceTypes(): Promise<void> {
        try {
            this.resourceTypes = await this.httpClient.get<ResourceType[]>(`/api/resource/types`).toPromise();
        } catch (e) {

        }
    }

    public onResourceTypeCreate(resourceType: ResourceType): void {
        this.resourceTypes.push(resourceType);
        this.form.controls.resourceType.setValue(resourceType);
    }

    public getResourceObjects(): ResourceObject[] {
        return this.resourceObjects;
    }

    public addImage(): void {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.addEventListener('change', async (event) => {
            const files = (event.target as HTMLInputElement).files;
            const file = files && files[0];

            const formData: FormData = new FormData();
            formData.append('file', file as File);
            formData.append('resourceType', this.form.controls.resourceType.value.name);

            this.fullscreenLoaderService.open();
            try {
                const resourceObject = await this.httpClient.post<ResourceObject>(`/api/resource/objects`, formData).toPromise();
                this.resourceObjects.push(resourceObject);
            } catch (e) {

            }
            this.fullscreenLoaderService.close();
        });
        input.click();
        input.remove();
    }

    public downloadResourceObject(resourceObject: ResourceObject, event: Event): void {
        event.stopPropagation();

        const link = document.createElement('a');
        link.download = resourceObject.name;
        link.href = resourceObject.url;
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    public async removeResourceObject(resourceObject: ResourceObject, event: Event): Promise<void> {
        event.stopPropagation();
        this.fullscreenLoaderService.open();
        try {
            const type = this.form.controls.resourceType.value.name;
            const name = resourceObject.name;
            await this.httpClient.delete(`/api/resource/objects/${type}/${name}`).toPromise();
            this.resourceObjects.splice(this.resourceObjects.indexOf(resourceObject), 1);
        } catch (e) {

        }
        this.fullscreenLoaderService.close();
    }

    public selectResourceObject(resourceObject: ResourceObject): void {
        this.dialogRef.close(resourceObject);
    }
}
