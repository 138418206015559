import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SeasonCatalogComponent} from './season-catalog.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import { UiKitComponentsModule } from 'src/shared/ui-kit/ui-kit-components.module';

@NgModule({
    declarations: [SeasonCatalogComponent],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        ReactiveFormsModule,
        MatIconModule,
        UiKitComponentsModule,
    ],
    exports: [SeasonCatalogComponent]
})
export class SeasonCatalogModule {
}
