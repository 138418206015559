<div class="popup-wrapper">
    <div class="popup-icon-wrapper">
        <mat-icon svgIcon="warning-filled"></mat-icon>
    </div>

    <div class="popup-body">
        <div class="headline-1">Ошибка</div>
        <div class="body-text-2">{{message}}</div>
    </div>

    <div class="popup-actions">
        <button
                mat-raised-button
                disableRipple
                type="button"
                class="button-primary-filled"
                color="primary"
                (click)="close()"
        >
            Понятно
        </button>
    </div>
</div>
