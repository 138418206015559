<div class="calendar calendar-range">
    <div class="calendar-month-container">
        <div class="calendar-header">
            <button
                    mat-icon-button
                    disableRipple
                    type="button"
                    class="button-icon"
                    color="primary"
                    (click)="prevMonth()"
            >
                <mat-icon svgIcon="pagination-prev"></mat-icon>
            </button>
            <div class="calendar-header-title">{{getLeftMonthName()}}, {{getLeftYear()}}</div>
        </div>
        <div class="calendar-day-names">
            <span class="calendar-day-name">Пн</span>
            <span class="calendar-day-name">Вт</span>
            <span class="calendar-day-name">Ср</span>
            <span class="calendar-day-name">Чт</span>
            <span class="calendar-day-name">Пт</span>
            <span class="calendar-day-name">Сб</span>
            <span class="calendar-day-name">Вс</span>
        </div>
        <calendar-range-days
                [days]="leftDays"
                [start]="startDate"
                [end]="endDate"
                [hoveredDate]="hoveredDate"
                [cellComponent]="cellComponent"
                (hover)="onDateHover($event)"
                (select)="onDateChange($event)"></calendar-range-days>
    </div>
    <div class="calendar-month-container">
        <div class="calendar-header">
            <div class="calendar-header-title">{{getRightMonthName()}}, {{getRightYear()}}</div>
            <button
                    mat-icon-button
                    disableRipple
                    type="button"
                    class="button-icon"
                    color="primary"
                    (click)="nextMonth()"
            >
                <mat-icon svgIcon="pagination-next"></mat-icon>
            </button>
        </div>
        <div class="calendar-day-names">
            <span class="calendar-day-name">Пн</span>
            <span class="calendar-day-name">Вт</span>
            <span class="calendar-day-name">Ср</span>
            <span class="calendar-day-name">Чт</span>
            <span class="calendar-day-name">Пт</span>
            <span class="calendar-day-name">Сб</span>
            <span class="calendar-day-name">Вс</span>
        </div>
        <calendar-range-days
                [days]="rightDays"
                [start]="startDate"
                [end]="endDate"
                [hoveredDate]="hoveredDate"
                [cellComponent]="cellComponent"
                (hover)="onDateHover($event)"
                (select)="onDateChange($event)"></calendar-range-days>
    </div>
</div>
