import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Event, EventType} from '../../shared/types/events';
import {HttpClient} from '@angular/common/http';
import {SubscriptionTypeCatalogComponent} from '../../components/reference-book/subscription-type-catalog/subscription-type-catalog.component';

export enum CuSubscriptionPopupResult {
    CANCEL,
    SAVE,
    SAVE_AND_CREATE
}

@Component({
    selector: 'app-cu-subscription-popup',
    templateUrl: './cu-subscription-popup.component.html',
    styleUrls: ['./cu-subscription-popup.component.scss']
})
export class CuSubscriptionPopupComponent implements OnInit {

    eventTypeOpened = false;

    form: FormGroup;

    private eventTypes: EventType[] = [];

    public loading = true;
    public waiting = false;

    public subscriptionTypeCatalogComponent = SubscriptionTypeCatalogComponent;

    public error: string | null = null;

    popupResult = CuSubscriptionPopupResult;

    constructor(
        private readonly dialogRef: MatDialogRef<CuSubscriptionPopupComponent>,
        private readonly fb: FormBuilder,
        private readonly httpClient: HttpClient,
        @Inject(MAT_DIALOG_DATA) public readonly event: Event
    ) {
        this.form = this.fb.group({
            eventName: [null],
            eventCaption: [null],
            eventType: [null]
        });
    }

    async ngOnInit(): Promise<void> {
        this.loading = true;
        await Promise.all([
            this.loadEventTypes()
        ]);
        if (this.event) {
           // this.form.patchValue({
           //     eventName: this.event.eventName,
           //     eventCaption: this.event.eventCaption,
           //     eventType: this.findEventTypeByUuid(this.event.eventTypeUuid)
           // });
           return
        }
        this.loading = false;
    }

    public close(result: CuSubscriptionPopupResult, data?: any): void {
        this.dialogRef.close({
            result,
            data
        });
    }

    public getEventTypes(): any[] {
        return this.eventTypes;
    }

    public onEventTypeCreate(eventType: EventType): void {
        this.eventTypes.push(eventType);
        this.form.controls.eventType.setValue(eventType);
    }


    private async loadEventTypes(): Promise<void> {
        try {
            this.eventTypes = await this.httpClient
                .get<EventType[]>(`/api/event-type/all`, {params: {consistType: 'SEASON_TICKET'}}).toPromise();
        } catch (e) {

        }
    }

    public async save(): Promise<void> {
        try {
            await this._save();
            this.close(CuSubscriptionPopupResult.SAVE);
        } catch (e) {

        }
    }

    public async _save(): Promise<Event> {
        const model: any = {
            caption: this.form.value.eventCaption,
            consistType: 'SEASON_TICKET',
            eventTypeUuid: this.form.value.eventType.uuid,
            name: this.form.value.eventName
        };
        if (this.event) {
            model.uuid = this.event.uuid;
        }
        this.waiting = true;
        this.error = null;
        this.form.disable();
        try {
            if (this.event) {
                return await this.httpClient.put<Event>(`/api/event/update`, model).toPromise();
            } else {
                return await this.httpClient.post<Event>(`/api/event/create`, model).toPromise();
            }
        } catch (e) {
            this.error = e.error.message;
            this.form.enable();
            this.waiting = false;
            throw e;
        }
    }


    public async saveAndCreatEventSession(): Promise<void> {
        try {
            const event = await this._save();
            this.close(CuSubscriptionPopupResult.SAVE_AND_CREATE, event);
        } catch (e) {

        }
    }

    private findEventTypeByUuid(uuid: string): EventType | null {
        return this.eventTypes.find(eventType => eventType.uuid === uuid) || null;
    }
}
