import {Component, ViewChild} from '@angular/core';
import {MatMenuTrigger} from '@angular/material/menu';

@Component({
    selector: 'icon-button-menu-component',
    template: `
        <button
                mat-icon-button
                disableRipple
                type="button"
                class="button-icon"
                color="primary"
                [matMenuTriggerFor]="menu"
                [matMenuTriggerRestoreFocus]="false"
                (onMenuOpen)="onOpen()"
        >
            <ng-content select="[menu-icon]"></ng-content>
        </button>
        <mat-menu #menu="matMenu" class="icon-button-menu-popup-panel" yPosition="below" xPosition="before">
            <div class="icon-button-menu-panel-container" (click)="$event.stopPropagation()">
                <div class="icon-button-menu-list-container" appSimplebarScroller (click)="close()">
                    <ng-content select="[menu-content]"></ng-content>
                </div>
            </div>
        </mat-menu>
    `
})
export class IconButtonMenuComponent {
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger | undefined;

    close(): void {
        this.trigger?.closeMenu();
    }

    onOpen(): void {
        window.dispatchEvent(new Event('resize'));
    }
}
