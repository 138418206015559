import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {SkeletonTextComponent} from "./skeleton-text/skeleton-text.component";
import {SkeletonCircleComponent} from "./skeleton-circle/skeleton-circle.component";

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        SkeletonTextComponent,
        SkeletonCircleComponent
    ],
    exports: [
        SkeletonTextComponent,
        SkeletonCircleComponent
    ]
})
export class SkeletonModule {
}
