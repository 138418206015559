import {Component, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {MatMenuTrigger} from "@angular/material/menu";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {format, parse, set} from 'date-fns';
import {Subscription} from "rxjs";

@Component({
    selector: 'input-date-time-picker',
    templateUrl: './input-date-time-picker.component.html',
    styleUrls: ['./input-date-time-picker.component.scss']
})
export class InputDateTimePickerComponent implements OnDestroy {
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger | undefined;

    @Input()
    date: Date | null = null;

    @Input()
    label = '';

    @Input()
    placeholder = 'Дата';

    @Input()
    disabled = false;

    @Output()
    change: EventEmitter<Date | null> = new EventEmitter<Date | null>();

    public form: FormGroup;

    public error = false;

    private dateSubscription: Subscription;
    private formSubscription: Subscription;

    constructor(
        private readonly fb: FormBuilder
    ) {
        this.form = this.fb.group({
            start: [null, [Validators.required]],
            startDate: [null, [Validators.required]],
            startTime: ['00:00', [Validators.required]],
        })

        this.dateSubscription = this.form.controls.start.valueChanges.subscribe(() => {
            if (this.form.controls.start.valid) {
                this.form.controls.startDate.setValue(
                    parse(this.form.controls.start.value, 'dd.MM.yyyy', new Date())
                )
            } else {
                this.form.controls.startDate.setValue(null);
            }
        });

        this.formSubscription = this.form.valueChanges.subscribe(() => {
            this.error = false;
        });
    }

    ngOnDestroy(): void {
        this.dateSubscription.unsubscribe();
        this.formSubscription.unsubscribe();
    }

    dateChange(date: Date | null) {
        this.form.patchValue({
            start: date === null ? null : format(date, 'dd.MM.yyyy'),
            startDate: date,
        });
    }

    apply(): void {
        const start = set(this.form.value.startDate, {
            hours: parse(this.form.value.startTime, 'HH:mm', new Date()).getHours(),
            minutes: parse(this.form.value.startTime, 'HH:mm', new Date()).getMinutes()
        });
        this.change.emit(start);
        this.close();
    }

    close() {
        this.trigger?.closeMenu();
    }

    onMenuOpen() {
        this.form.patchValue({
            start: this.date === null ? null : format(this.date, 'dd.MM.yyyy'),
            startDate: this.date === null ? null : set(this.date, {hours: 0, minutes: 0}),
            startTime: this.date === null ? '00:00' : format(this.date, 'HH:mm'),
        })
    }

    getCaption(): string {
        if (this.date !== null && this.date !== undefined) {
            return `${format(this.date, 'dd.MM.yyyy HH:mm')}`;
        }
        return this.placeholder;
    }
}
