<div class="page-container">
    <div class="error-container" *ngIf="error">
        <inline-alert-component [text]="error"></inline-alert-component>
    </div>
    <div class="create-form-container" *ngIf="!loading">
        <div class="create-form-header">
            <span class="body-text-2">Добавить место и время продаж</span>
            <button
                    mat-button
                    disableRipple
                    type="button"
                    class="button-primary-basic-text"
                    color="primary"
                    (click)="resetForm()"
            >
                Сбросить
            </button>
        </div>
        <div class="create-form-body" [formGroup]="form">
            <div class="create-form-field-device-model">
                <mat-form-field appearance="fill" color="primary" class="input-select"
                                [class.input-select__opened]="clientsOpened">
                    <mat-label>Клиент</mat-label>
                    <mat-icon svgIcon="dropdown-arrow" matSuffix></mat-icon>
                    <mat-select (openedChange)="clientsOpened = $event" formControlName="client" disableRipple
                                disableOptionCentering
                                panelClass="dropdown-panel">
                        <div appSimplebarScroller>
                            <mat-option *ngFor="let customer of getCustomers()" [value]="customer">
                                {{customer.name}}
                            </mat-option>
                        </div>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="create-form-field-print-image">
                <input-date-time-range-picker [start]="form.controls.start.value" [end]="form.controls.end.value"
                                              (rangeChange)="onRangeChange($event)"></input-date-time-range-picker>
            </div>
            <div class="create-form-field-action">
                <button
                        mat-stroked-button
                        disableRipple
                        type="button"
                        class="button-primary-outline"
                        [disabled]="form.invalid"
                        color="primary"
                        (click)="addLimit()"
                >
                    <mat-icon svgIcon="checkmark" class="mat-button-icon_left"></mat-icon>
                    Добавить
                </button>
            </div>
        </div>
    </div>

    <div class="create-form-container" *ngIf="loading">
        <div class="create-form-header">
            <span class="body-text-2"><skeleton-text [width]="250" [height]="18"></skeleton-text></span>
            <skeleton-text [width]="100" [height]="32"></skeleton-text>
        </div>
        <div class="create-form-body" [formGroup]="form">
            <div class="create-form-field-device-model">
                <skeleton-text [height]="44"></skeleton-text>
            </div>
            <div class="create-form-field-print-image">
                <skeleton-text [height]="44"></skeleton-text>
            </div>
            <div class="create-form-field-action">
                <skeleton-text [width]="162" [height]="44"></skeleton-text>
            </div>
        </div>
    </div>


    <div class="print-images-container" *ngIf="!loading">
        <div class="print-images-actions">
            <div class="print-images-action-checkbox">
                <mat-checkbox
                        disableRipple
                        class="input-checkbox"
                        color="primary"
                        (change)="onSelectAllChange($any($event))"
                        [checked]="selectedLimits.length > 0 && selectedLimits.length === table.length"
                        [indeterminate]="table.length !== selectedLimits.length && selectedLimits.length > 0"
                >
                </mat-checkbox>
            </div>
            <div class="body-text-3 print-images-action-label">
                Выбрано: <span>{{selectedLimits.length}}</span> из <span>{{table.length}}</span>
            </div>
            <div class="print-images-action-divider"></div>
            <button
                    mat-button
                    disableRipple
                    type="button"
                    class="button-primary-basic-filled"
                    [disabled]="selectedLimits.length === 0"
                    color="primary"
                    (click)="removeSelectedLimits()"
            >
                Удалить
            </button>
        </div>
        <div class="print-images-table">
            <mat-table [dataSource]="table" appSimplebarScroller [scrollWithMouse]="true">

                <ng-container matColumnDef="select-row" sticky>
                    <mat-header-cell *matHeaderCellDef class="mat-cell-sticky-start"></mat-header-cell>
                    <mat-cell *matCellDef="let element" class="mat-cell-sticky-start">
                        <div class="mat-cell-content">
                            <mat-checkbox
                                    disableRipple
                                    class="input-checkbox"
                                    color="primary"
                                    [checked]="element.selected"
                                    (click)="$event.preventDefault()"
                            >
                            </mat-checkbox>
                        </div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="client">
                    <mat-header-cell *matHeaderCellDef>
                        <div class="mat-cell-content">
                            <sort label="Клиент" [state]="getSortDirection('customerName')"
                                  (onChange)="onSortChange('customerName', $event)"></sort>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="mat-cell-content">
                            <div class="body-text-3">{{element.customerName}}</div>
                        </div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="date-time">
                    <mat-header-cell *matHeaderCellDef>
                        <div class="mat-cell-content">
                            <sort label="Период" [state]="getSortDirection('datetime')"
                                  (onChange)="onSortChange('datetime', $event)"></sort>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="mat-cell-content">
                            <div class="body-text-3">{{element.start}} - {{element.end}}</div>
                        </div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef>
                        <div class="mat-cell-content">
                            <sort label="Статус" [state]="getSortDirection('onSale')"
                                  (onChange)="onSortChange('onSale', $event)"></sort>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="mat-cell-content">
                            <label color="success" *ngIf="element.onSale" [placeholder]="'В продаже'"></label>
                        </div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
                        <div class="mat-cell-content">
                            <button
                                    mat-icon-button
                                    disableRipple
                                    type="button"
                                    class="button-icon"
                                    color="primary"
                                    (click)="removeLimit(element)"
                            >
                                <mat-icon svgIcon="basket"></mat-icon>
                            </button>
                        </div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="empty-scrollbar-row">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let element"></mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="tableColumns; sticky: true"></mat-header-row>

                <mat-row *matRowDef="let row; columns: tableColumns;" (click)="toggleLimitSelection(row)"></mat-row>

                <mat-footer-row class="empty-scrollbar-row"
                                *matFooterRowDef="['empty-scrollbar-row'];"></mat-footer-row>
            </mat-table>
        </div>
    </div>

    <div class="print-images-container" *ngIf="loading">
        <div class="print-images-actions">
            <div class="print-images-action-checkbox"></div>
            <div class="body-text-3 print-images-action-label">
                <skeleton-text [inverseColor]="true" [width]="111" [height]="18"></skeleton-text>
            </div>
            <div class="print-images-action-divider"></div>
            <skeleton-text [inverseColor]="true" [width]="95" [height]="32"></skeleton-text>
        </div>
        <div class="print-images-table">
            <mat-table [dataSource]="[1,2,3]" appSimplebarScroller>

                <ng-container matColumnDef="select-row" sticky>
                    <mat-header-cell *matHeaderCellDef class="mat-cell-sticky-start"></mat-header-cell>
                    <mat-cell *matCellDef="let element" class="mat-cell-sticky-start">
                        <div class="mat-cell-content"></div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="client">
                    <mat-header-cell *matHeaderCellDef>
                        <div class="mat-cell-content">
                            <skeleton-text [width]="100" [height]="12"></skeleton-text>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="mat-cell-content">
                            <skeleton-text [width]="180" [height]="18"></skeleton-text>
                        </div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="date-time">
                    <mat-header-cell *matHeaderCellDef>
                        <div class="mat-cell-content">
                            <skeleton-text [width]="100" [height]="12"></skeleton-text>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="mat-cell-content">
                            <skeleton-text [width]="180" [height]="18"></skeleton-text>
                        </div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef>
                        <div class="mat-cell-content">
                            <skeleton-text [width]="100" [height]="12"></skeleton-text>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="mat-cell-content">
                            <skeleton-text [width]="110" [height]="32"></skeleton-text>
                        </div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
                        <div class="mat-cell-content"></div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="empty-scrollbar-row">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let element"></mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="tableColumns; sticky: true"></mat-header-row>

                <mat-row *matRowDef="let row; columns: tableColumns;" (click)="toggleLimitSelection(row)"></mat-row>

                <mat-footer-row class="empty-scrollbar-row"
                                *matFooterRowDef="['empty-scrollbar-row'];"></mat-footer-row>
            </mat-table>
        </div>
    </div>


    <div class="actions" *ngIf="!loading">
        <button
                mat-raised-button
                disableRipple
                type="button"
                class="button-primary-filled"
                color="success"
                (click)="next()"
        >
            Далее
        </button>
    </div>

    <div class="actions" *ngIf="loading">
        <skeleton-text [inverseColor]="true" [width]="95" [height]="36"></skeleton-text>
    </div>
</div>
