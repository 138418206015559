import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ResourceTypeCatalogComponent} from './resource-type-catalog.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';


@NgModule({
    declarations: [ResourceTypeCatalogComponent],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        ReactiveFormsModule,
        MatIconModule
    ]
})
export class ResourceTypeCatalogModule {
}
