import {Injectable} from "@angular/core";
import {DateService} from "./date.service";

export const rangeFromTo = <T>(from: number, to = 0, producer: (number: any) => T = i => i) => {
    const arr = [];

    for (let i = from; i < to; i++) {
        arr.push(producer(i));
    }

    return arr;
};

export const range = <T>(bound: number, producer: (number: any) => T = i => i) => {
    return rangeFromTo(0, bound, producer);
};

const MONTH_NAMES = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь'
]

@Injectable({
    providedIn: 'root'
})
export class CalendarService {

    constructor(
        private dateService: DateService
    ) {
    }

    createDaysGrid(activeMonth: Date): Date[] {
        let days = this.createDates(activeMonth);
        days = this.fillLeft(days, activeMonth);
        days = this.fillRight(days);
        return days;
    }

    prevMonth(date: Date) {
        return this.dateService.addMonth(date, -1);
    }

    nextMonth(date: Date) {
        return this.dateService.addMonth(date, 1);
    }

    getMonthName(date: Date) {
        return MONTH_NAMES[date.getMonth()];
    }

    private createDates(activeMonth: Date): Date[] {
        return this.createDateRangeForMonth(activeMonth);
    }

    private createDateRangeForMonth(date: Date): Date[] {
        const daysInMonth: number = this.dateService.getNumberOfDaysInMonth(date);
        return range(daysInMonth, i => {
            const year = this.dateService.getYear(date);
            const month = this.dateService.getMonth(date);
            return this.dateService.createDate(year, month, i + 1)
        });
    }

    private getStartOfWeekDayDiff(date: Date): number {
        const startOfMonth = this.dateService.getMonthStart(date);
        return this.getWeekStartDiff(startOfMonth);
    }

    private getWeekStartDiff(date: Date): number {
        return (7 - this.dateService.getFirstDayOfWeek() + this.dateService.getDayOfWeek(date)) % 7;
    }

    private fillLeft(days: Date[], activeMonth: Date): Date[] {
        const startOfWeekDayDiff = this.getStartOfWeekDayDiff(activeMonth);

        if (startOfWeekDayDiff === 0) {
            return days;
        }

        return new Array(-(1 - startOfWeekDayDiff)).fill(null).concat(days);
    }

    private fillRight(days: Date[]): Date[] {
        const right = days.length % 7;
        if (right === 0) {
            return days;
        }
        return days.concat(new Array(7 - right).fill(null));
    }
}
