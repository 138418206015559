import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
    selector: 'app-ticket-return-popup',
    templateUrl: './ticket-return-popup.component.html',
    styleUrls: ['./ticket-return-popup.component.scss']
})
export class TicketReturnPopupComponent {

    form: FormGroup;
    
    public waiting = false;

    public error: string | null = null;

    public amout = 0;
    public percent = 0;
    public sms = '';

    constructor(
        private readonly dialogRef: MatDialogRef<TicketReturnPopupComponent>,
        private readonly fb: FormBuilder,
        private readonly httpClient: HttpClient,
        @Inject(MAT_DIALOG_DATA) public readonly data: any
    ) {
        this.form = this.fb.group({
            percent: [this.percent, [Validators.min(0), Validators.max(100), Validators.required]],
            amount: [this.amout, [Validators.min(0), Validators.max(this.data.totalSumm), Validators.required]],
            sms: ['',[Validators.min(100000), Validators.max(999999), Validators.required]]
        });
    }

    public close(): void {
        this.dialogRef.close();
    }

    public sendSms(): void {
        this.waiting = true;
        this.error = null;
        try {
            this.httpClient
                .post(`/api/otp`, {purpose: 'otp_purpose_refund_tickets_'+this.data.ticketsIds.join(',')}).toPromise();
        } catch (e) {
            this.error = e.error.message;
        }
        this.waiting = false;
    }

    
    public onChangeAmount(event: any) {
        this.amout = event.value ? event.value : 0;
        if (this.amout < 0) {this.amout = 0}
        if (this.amout > this.data.totalSumm) {this.amout = this.data.totalSumm}
        this.percent = (Math.trunc((this.amout/this.data.totalSumm)*100*1000))/1000;
        this.form = this.fb.group({percent: this.percent, amount: this.amout, sms: this.sms === '' ? '' : this.sms});
    }

    public onChangePercent(event: any) {
        this.percent = event.value ? event.value : 0;
        if (this.percent < 0) {this.percent = 0}
        if (this.percent > 100) {this.percent = 100}
        this.amout = (Math.trunc((this.data.totalSumm*this.percent/100)*100))/100;
        this.form = this.fb.group({percent: this.percent, amount: this.amout, sms: this.sms === '' ? '' : this.sms});
    }

    public onChangeSms(event: any) {
        this.sms = event.value ? event.value : '';
        this.form = this.fb.group({percent: this.percent, amount: this.amout, sms: this.sms === '' ? '' : this.sms});
    }

    public checkSms() {
        let result = 0;
        if (this.sms && this.sms.length === 6) {
            result = 1;
        }
        return result;  
    }

    public async return(): Promise<void> {
        this.waiting = true;
        this.error = null;
        try {
            
            const model = {
                    ticketIds: this.data.ticketsIds,
                    refundAmount: this.data.totalSumm - this.amout,
                    otp: this.sms+''
            };
            const httpOptions = {
                headers: new HttpHeaders({ 'Content-Type': 'application/json' }), body: model
            };
            await this.httpClient.delete(`/api/order/${this.data.orderId}/refund`, httpOptions).toPromise();
            this.close();
        } catch (e) {
            this.error = e.error.detail;
        }
        this.waiting = false;
    }

}
