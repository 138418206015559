<div class="popup-wrapper">
    <div class="popup-header">
        <div class="body-text-1">Возврат платежа</div>
        <button
                mat-icon-button
                disableRipple
                type="button"
                class="button-icon"
                color="primary"
                (click)="close()"
        >
            <mat-icon svgIcon="times"></mat-icon>
        </button>
    </div>
    <div class="popup-body">

        <div style="padding-bottom: 15px;">Сумма к возвтрату <b>{{data.totalSumm  | mask:'separator.2'}}</b> руб.</div>

        <div class="popup-footer">
            <button
                    mat-raised-button
                    disableRipple
                    type="button"
                    class="button-primary-filled button-primary-filled-secondary"
                    color="primary"
                    (click)="return()"
            >
                Сделать возврат
            </button>
        </div>
    </div>
        
        <div class="error-container" *ngIf="error">
            <inline-alert-component [text]="error"></inline-alert-component>
        </div>

</div>