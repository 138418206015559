<div class="page-container">
    <div class="error-container" *ngIf="error">
        <inline-alert-component [text]="error"></inline-alert-component>
    </div>
    <div class="create-form-container" *ngIf="!loading">
        <div class="create-form-header">
            <span class="body-text-2">Добавить цену</span>
            <button
                    mat-button
                    disableRipple
                    type="button"
                    class="button-primary-basic-text"
                    color="primary"
                    (click)="resetForm()"
            >
                Сбросить
            </button>
        </div>
        <div class="create-form-body" [formGroup]="form">
            <div class="create-form-field-zone">
                <mat-form-field appearance="fill" color="primary" class="input-select"
                                [class.input-select__opened]="zoneOpened"
                                [class.input-select__disabled]="form.controls.zone.disabled"
                >
                    <mat-label>Зона</mat-label>
                    <mat-icon svgIcon="dropdown-arrow" matSuffix></mat-icon>
                    <mat-select (openedChange)="zoneOpened = $event" formControlName="zone" disableRipple
                                disableOptionCentering
                                panelClass="dropdown-panel">
                        <div appSimplebarScroller>
                            <mat-option *ngFor="let zone of getZones()" [value]="zone">
                                {{zone.name}}
                            </mat-option>
                        </div>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="create-form-field-category">
                <mat-form-field appearance="fill" color="primary" class="input-select"
                                [class.input-select__opened]="categoryOpened"
                                [class.input-select__disabled]="form.controls.category.disabled"
                >
                    <mat-label>Категория</mat-label>
                    <mat-icon svgIcon="dropdown-arrow" matSuffix></mat-icon>
                    <mat-select (openedChange)="categoryOpened = $event" formControlName="category" disableRipple
                                disableOptionCentering
                                panelClass="dropdown-panel">
                        <div appSimplebarScroller>
                            <mat-option *ngFor="let category of getCategories()" [value]="category">
                                {{category.name}}
                            </mat-option>
                        </div>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="create-form-field-price">
                <mat-form-field class="input-field" color="primary">
                    <mat-label>Цена</mat-label>
                    <input type="text" mask="099999999999" [dropSpecialCharacters]="false" matInput
                           formControlName="price">
                </mat-form-field>
            </div>
            <div class="create-form-field-vat">
                <mat-form-field class="input-field" color="primary">
                    <mat-label>НДС, %</mat-label>
                    <input type="text" mask="999" [dropSpecialCharacters]="false" matInput formControlName="vat">
                </mat-form-field>
            </div>
			<div class="create-form-field-customers">
				<filter-component [dataSource]="customersDataSource"
								  [confirmationRequired]="false"
								  (onSelectionChange)="onCustomersChange($event)">
					<span filter-placeholder>Место продажи</span>
					<span filter-title>Место продажи</span>
				</filter-component>
			</div>
            <div class="create-form-field-action">
                <button
                        mat-stroked-button
                        disableRipple
                        type="button"
                        class="button-primary-outline"
                        [disabled]="form.invalid"
                        color="primary"
                        (click)="addPrice()"
                >
                    <mat-icon svgIcon="checkmark" class="mat-button-icon_left"></mat-icon>
                    Добавить
                </button>
            </div>
        </div>
    </div>
    <div class="create-form-container" *ngIf="loading">
        <div class="create-form-header">
            <span class="body-text-2"><skeleton-text [width]="140" [height]="18"></skeleton-text></span>
            <skeleton-text [width]="100" [height]="32"></skeleton-text>
        </div>
        <div class="create-form-body" [formGroup]="form">
            <div class="create-form-field-category">
                <skeleton-text [height]="44"></skeleton-text>
            </div>
            <div class="create-form-field-zone">
                <skeleton-text [height]="44"></skeleton-text>
            </div>
            <div class="create-form-field-price">
                <skeleton-text [height]="44"></skeleton-text>
            </div>
            <div class="create-form-field-vat">
                <skeleton-text [height]="44"></skeleton-text>
            </div>
			<div class="create-form-field-customers">
				<skeleton-text [height]="44"></skeleton-text>
			</div>
            <div class="create-form-field-action">
                <skeleton-text [width]="162" [height]="44"></skeleton-text>
            </div>
        </div>
    </div>


    <div class="price-rates-container" *ngIf="!loading">
        <div class="price-rates-actions">
            <div class="price-rates-action-checkbox">
                <mat-checkbox
                        disableRipple
                        class="input-checkbox"
                        color="primary"
                        (change)="onSelectAllChange($any($event))"
                        [checked]="selectedPrices.length > 0 && selectedPrices.length === prices.length"
                        [indeterminate]="prices.length !== selectedPrices.length && selectedPrices.length > 0"
                >
                </mat-checkbox>
            </div>
            <div class="body-text-3 price-rates-action-label">
                Выбрано: <span>{{selectedPrices.length}}</span> из <span>{{prices.length}}</span>
            </div>
            <div class="price-rates-action-divider"></div>
            <button
                    mat-button
                    disableRipple
                    type="button"
                    class="button-primary-basic-filled"
                    [disabled]="selectedPrices.length === 0"
                    color="primary"
                    (click)="removeSelectedPrices()"
            >
                Удалить
            </button>
        </div>
        <div class="price-rates-table">
            <div class="price-header">
                <div class="price-row">
                    <div class="price-col price-selection"></div>
                    <div class="price-col price-zone"></div>
                    <div class="price-col price-category">Категория</div>
                    <div class="price-col price-price">Цена</div>
                    <div class="price-col price-tax">НДС, %</div>
                    <div class="price-col price-customers">Место продажи</div>
                    <div class="price-col price-actions"></div>
                </div>
            </div>
            <div class="table-body" appSimplebarScroller>
                <div class="price-group" *ngFor="let zone of pricesTable.zones">
                    <div class="price-row price-group-header">
                        <div class="price-col price-selection"></div>
                        <div class="price-col body-text-2">Зона {{zone.zoneName}}</div>
                    </div>
                    <div class="price-row" *ngFor="let price of zone.prices" [formGroup]="editForm"
                         [class.price-row-editing]="editingPrice?.uuid === price[0].uuid">
                        <div class="price-col price-selection">
                            <mat-checkbox
                                    disableRipple
                                    class="input-checkbox"
                                    color="primary"
                                    [checked]="price[0].selected || false"
                                    (change)="togglePriceRateSelection(price)"
                            >
                            </mat-checkbox>
                        </div>
                        <div class="price-col price-zone"></div>
                        <div class="price-col price-category body-text-3">
                            <span *ngIf="price[0].uuid !== editingPrice?.uuid">{{price[0].categoryName}}</span>
                            <mat-form-field *ngIf="price[0].uuid === editingPrice?.uuid" appearance="fill" color="primary"
                                            class="input-select"
                                            [class.input-select__opened]="categoryEditOpened"
                                            [class.input-select__disabled]="form.controls.category.disabled"
                            >
                                <mat-label>Категория</mat-label>
                                <mat-icon svgIcon="dropdown-arrow" matSuffix></mat-icon>
                                <mat-select (openedChange)="categoryEditOpened = $event" formControlName="category"
                                            disableRipple
                                            disableOptionCentering
                                            panelClass="dropdown-panel">
                                    <div appSimplebarScroller>
                                        <mat-option *ngFor="let category of getCategories()" [value]="category">
                                            {{category.name}}
                                        </mat-option>
                                    </div>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="price-col price-price body-text-3">
                            <span *ngIf="editingPrice?.uuid !== price[0].uuid"><span appNumberFormatter
                                                                                  [value]="price[0].basePrice"></span> ₽</span>
                            <mat-form-field *ngIf="editingPrice?.uuid === price[0].uuid" class="input-field"
                                            color="primary">
                                <mat-label>Цена</mat-label>
                                <input type="text" mask="099999999999" [dropSpecialCharacters]="false" matInput
                                       formControlName="price">
                            </mat-form-field>
                        </div>
                        <div class="price-col price-tax body-text-3">
                            <span *ngIf="price[0].uuid !== editingPrice?.uuid && price[0].tax !== null">{{price[0].tax}}%</span>
                            <span *ngIf="price[0].uuid !== editingPrice?.uuid && price[0].tax === null">НДС не облагается</span>
                            <mat-form-field *ngIf="price[0].uuid === editingPrice?.uuid" class="input-field"
                                            color="primary">
                                <mat-label>НДС, %</mat-label>
                                <input type="text" mask="999" [dropSpecialCharacters]="false" matInput
                                       formControlName="vat">
                            </mat-form-field>
                        </div>
						<div class="price-col price-customers body-text-3">
							<span
								class="customer"
								[style.background-color]="customer.color + '1a'"
								[style.border-color]="customer.color"
								*ngFor="let customer of getUniqCustomers(price)"
							>{{customer.name}}</span>
						</div>
                        <div class="price-col price-actions">
							<button
								mat-icon-button
								disableRipple
								type="button"
								class="button-icon"
								color="primary"
								*ngIf="price.length > 1"
								(click)="editGroupedPriceRate(price)"
							>
								<mat-icon svgIcon="arrow"></mat-icon>
							</button>
                            <button
                                    mat-icon-button
                                    disableRipple
                                    type="button"
                                    class="button-icon"
                                    color="primary"
                                    *ngIf="editingPrice?.uuid !== price[0].uuid"
                                    (click)="editPrice(price)"
                            >
                                <mat-icon svgIcon="pencil"></mat-icon>
                            </button>
                            <button
                                    mat-button
                                    disableRipple
                                    type="button"
                                    class="button-primary-basic-filled"
                                    *ngIf="editingPrice?.uuid === price[0].uuid"
                                    (click)="updatePrice()"
                                    [disabled]="editForm.invalid"
                                    color="primary"
                            >
                                Сохранить
                            </button>
                            <button
                                    mat-icon-button
                                    disableRipple
                                    type="button"
                                    class="button-icon"
                                    color="primary"
                                    (click)="removePrice(price)"
                            >
                                <mat-icon svgIcon="basket"></mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="price-rates-container" *ngIf="loading">
        <div class="price-rates-actions">
            <div class="price-rates-action-checkbox"></div>
            <div class="body-text-3 price-rates-action-label">
                <skeleton-text [inverseColor]="true" [width]="111" [height]="18"></skeleton-text>
            </div>
            <div class="price-rates-action-divider"></div>
            <skeleton-text [inverseColor]="true" [width]="95" [height]="32"></skeleton-text>
        </div>
        <div class="price-rates-table">
            <div class="price-header">
                <div class="price-row">
                    <div class="price-col price-selection"></div>
                    <div class="price-col price-zone">
                        <skeleton-text [width]="80" [height]="12"></skeleton-text>
                    </div>
                    <div class="price-col price-category">
                        <skeleton-text [width]="80" [height]="12"></skeleton-text>
                    </div>
                    <div class="price-col price-price">
                        <skeleton-text [width]="80" [height]="12"></skeleton-text>
                    </div>
                    <div class="price-col price-tax">
                        <skeleton-text [width]="80" [height]="12"></skeleton-text>
                    </div>
					<div class="price-col price-customers">
						<skeleton-text [width]="80" [height]="12"></skeleton-text>
					</div>
                    <div class="price-col price-actions"></div>
                </div>
            </div>
            <div class="table-body" appSimplebarScroller>
                <div class="price-group" *ngFor="let zone of [1,2]">
                    <div class="price-row price-group-header">
                        <div class="price-col price-selection"></div>
                        <div class="price-col body-text-2">
                            <skeleton-text [inverseColor]="true" [height]="14" [width]="100"></skeleton-text>
                        </div>
                    </div>
                    <div class="price-row" *ngFor="let price of [1,2,3]">
                        <div class="price-col price-selection"></div>
                        <div class="price-col price-zone"></div>
                        <div class="price-col price-category body-text-3">
                            <skeleton-text [height]="14" [width]="100"></skeleton-text>
                        </div>
                        <div class="price-col price-price body-text-3">
                            <skeleton-text [height]="14" [width]="100"></skeleton-text>
                        </div>
                        <div class="price-col price-tax body-text-3">
                            <skeleton-text [height]="14" [width]="100"></skeleton-text>
                        </div>
						<div class="price-col price-customers body-text-3">
							<skeleton-text [height]="14" [width]="100"></skeleton-text>
						</div>
                        <div class="price-col price-actions"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="actions" *ngIf="!loading">
        <button
                mat-raised-button
                disableRipple
                type="button"
                class="button-primary-filled"
                color="primary"
                (click)="copyTo()"
        >
            Копировать в...
        </button>
        <button
                mat-raised-button
                disableRipple
                type="button"
                class="button-primary-filled"
                color="primary"
                (click)="copyFrom()"
        >
            Копировать из...
        </button>
        <button
                mat-raised-button
                disableRipple
                type="button"
                class="button-primary-filled"
                color="success"
                (click)="next()"
        >
            Далее
        </button>
    </div>

    <div class="actions" *ngIf="loading">
        <skeleton-text [inverseColor]="true" [width]="141" [height]="36"></skeleton-text>
        <skeleton-text [inverseColor]="true" [width]="148" [height]="36"></skeleton-text>
        <skeleton-text [inverseColor]="true" [width]="95" [height]="36"></skeleton-text>
    </div>
</div>
