import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Options} from "ng5-slider";

@Component({
    selector: 'range-slider',
    templateUrl: './range-slider.component.html',
    styleUrls: ['./range-slider.component.scss']
})
export class RangeSliderComponent {

    @Input()
    value: number = 0;

    @Output()
    valueChange: EventEmitter<number> = new EventEmitter<number>();

    @Input()
    highValue: number = 0;

    @Output()
    highValueChange: EventEmitter<number> = new EventEmitter<number>();


    @Output()
    rangeChange: EventEmitter<{ min: number, max: number }> = new EventEmitter<{ min: number; max: number }>();

    @Input()
    options: Options = {
        animate: false,
        floor: 0,
        ceil: 200
    };

    constructor() {
    }

    onRangeChange() {
        this.rangeChange.emit({min: this.value, max: this.highValue});
        this.highValueChange.emit(this.highValue);
        this.valueChange.emit(this.value);
    }

}
