import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {HttpClient} from '@angular/common/http';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {formatDate, formatNativeDate} from '../../shared/utils/date-utils';
import {EventSession} from '../../shared/types/events';

interface EventSessionLimit {
    customerName: string;
    customerUuid: string;
    saleBegin: string;
    saleEnd: string;
    uuid: string;
}

interface EventSessionTableLimit {
    uuid?: string;
    customerName: string;
    customerUuid: string;
    start: string;
    end: string;
    saleBegin: string;
    saleEnd: string;
}

interface Customer {
    name: string;
    uuid: string;
}

@Component({
    selector: 'app-open-sales-popup',
    templateUrl: './open-sales-popup.component.html',
    styleUrls: ['./open-sales-popup.component.scss']
})
export class OpenSalesPopupComponent implements OnInit {

    public waiting = false;
    public loading = false;

    public error: string | null = null;

    public clientsOpened = false;

    public form: FormGroup;

    private allCustomers: Customer[] = [];

    private customers: Customer[] = [];

    public table: EventSessionTableLimit[] = [];

    private usedCustomersUuids: string[] = [];

    constructor(
        private readonly dialogRef: MatDialogRef<OpenSalesPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly eventSessions: EventSession[],
        private readonly httpClient: HttpClient,
        private readonly fb: FormBuilder
    ) {
        this.form = this.fb.group({
            client: [null, [Validators.required]],
            start: [null, [Validators.required]],
            end: [null, [Validators.required]],
        });
    }

    async ngOnInit(): Promise<void> {
        this.loading = true;
        if (this.eventSessions.length === 1) {
            await this.loadLimits();
        }
        await this.loadCustomers();
        this.loading = false;
    }

    private async loadLimits(): Promise<void> {
        try {
            const eventSessionUuid = this.eventSessions[0].uuid;
            const eventSessionLimits = await this.httpClient
                .get<EventSessionLimit[]>(`/api/event-session-limit/all/by-event-session/${eventSessionUuid}`).toPromise();

            this.table = eventSessionLimits.map(eventSessionLimit => {
                this.usedCustomersUuids.push(eventSessionLimit.customerUuid);
                return {
                    uuid: eventSessionLimit.uuid,
                    customerName: eventSessionLimit.customerName,
                    customerUuid: eventSessionLimit.customerUuid,
                    saleBegin: eventSessionLimit.saleBegin,
                    saleEnd: eventSessionLimit.saleEnd,
                    end: formatDate(eventSessionLimit.saleEnd, 'dd MMM HH:mm'),
                    start: formatDate(eventSessionLimit.saleBegin, 'dd MMM HH:mm')
                };
            });

        } catch (e) {

        }
    }

    private async loadCustomers(): Promise<void> {
        try {
            this.allCustomers = await this.httpClient.get<Customer[]>(`/api/customer/all`).toPromise();

            this.filterCustomers();
        } catch (e) {

        }
    }

    public getCustomers(): Customer[] {
        return this.customers;
    }

    public close(result?: boolean): void {
        this.dialogRef.close(result);
    }

    public removeLimit(limit: EventSessionTableLimit): void {
        this.table.splice(this.table.indexOf(limit), 1);
        this.table = [...this.table];
        this.usedCustomersUuids.splice(this.usedCustomersUuids.indexOf(limit.customerUuid), 1);
        this.filterCustomers();
    }

    public clearSales(): void {
        this.table = [];
        this.usedCustomersUuids = [];
        this.form.reset();
        this.filterCustomers();
    }

    public onRangeChange({start, end}: { start: Date | null, end: Date | null }): void {
        this.form.patchValue({
            start, end
        });
    }

    public createLimit(): void {
        this.table.push({
            customerName: this.form.value.client.name,
            customerUuid: this.form.value.client.uuid,
            start: formatNativeDate(this.form.value.start, 'dd MMM HH:mm'),
            end: formatNativeDate(this.form.value.end, 'dd MMM HH:mm'),
            saleBegin: formatNativeDate(this.form.value.start, `yyyy-MM-dd'T'HH:mm:ss`),
            saleEnd: formatNativeDate(this.form.value.end, `yyyy-MM-dd'T'HH:mm:ss`)
        });
        this.usedCustomersUuids.push(this.form.value.client.uuid);
        this.filterCustomers();
        this.form.reset();
    }

    public async openSales(): Promise<void> {
        this.error = null;
        this.waiting = true;
        try {
            const model = {
                customersSaleDates: this.table
                    .reduce((accumulator: { [key: string]: { end: string, start: string } }, tableLimit) => {
                        accumulator[tableLimit.customerUuid] = {
                            start: tableLimit.saleBegin,
                            end: tableLimit.saleEnd
                        };
                        return accumulator;
                    }, {}),
                eventSessionUuids: this.eventSessions.map(eventSession => eventSession.uuid)
            };
            await this.httpClient.post(`/api/event-session-limit/all/by-event-session/rewrite`, model).toPromise();
            this.close(true);
            /* TODO перетянуть таблицы сессий ВЕЗДЕ */
        } catch (e) {
            this.error = e.error.message;
        }
        this.waiting = false;
    }

    private filterCustomers(): void {
        this.customers = this.allCustomers.filter(customer => !this.usedCustomersUuids.includes(customer.uuid));
    }
}
