import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormBuilder, FormGroup} from '@angular/forms';
import {EventType} from '../../shared/types/events';
import {HttpClient} from '@angular/common/http';

export enum EventTypeCreatePopupResult {
    CANCEL,
    SAVE,
    SAVE_AND_CREATE
}

@Component({
    selector: 'app-event-type-create-popup',
    templateUrl: './event-type-create-popup.component.html',
    styleUrls: ['./event-type-create-popup.component.scss']
})
export class EventTypeCreatePopupComponent implements OnInit {

    
    public lang: FormControl = new FormControl(0);
    form: FormGroup;
    public loading = true;
    public waiting = false;
    public error: string | null = null;
    popupResult = EventTypeCreatePopupResult;
    private langName: Map<number, string> = new Map<number, string>([
        [0, 'ru'],
        [1, 'en']
    ]);   

    constructor(
        private readonly dialogRef: MatDialogRef<EventTypeCreatePopupComponent>,
        private readonly fb: FormBuilder,
        private readonly httpClient: HttpClient,
        @Inject(MAT_DIALOG_DATA) public readonly eventtype: EventType
    ) {
        
        this.form = this.fb.group({});
        const main = this.fb.group({
            uuid: [null]
          });
        this.form.addControl('main', main);
        
        this.langName.forEach((langName) => {
            const lang = this.fb.group({
                name: [null]
              });
            this.form.addControl(langName, lang);
        });
    }

    async ngOnInit(): Promise<void> {
        this.loading = true;
        if (this.eventtype) {
            this.form.patchValue({
                main: {
                    // @ts-ignore
                    uuid: this.eventtype.uuid
                    }
                });
                this.langName.forEach((langName) => {
                    this.form.patchValue({
                        // @ts-ignore
                        [langName] : {
                            // @ts-ignore
                            name: this.eventtype.name[langName]
                        }
                    });
                });
        }
        this.loading = false;
    }

    public close(result: EventTypeCreatePopupResult, data?: any): void {
        this.dialogRef.close({
            result,
            data
        });
    }

    public async save(): Promise<void> {
        try {
            await this._save();
            this.close(EventTypeCreatePopupResult.SAVE);
        } catch (e) {

        }
    }

    public async _save(): Promise<EventType> {

        let name = {};
        this.langName.forEach((langName) => { 
            if (this.form.value[langName].name) {
                name = Object.assign({[langName]: this.form.value[langName].name}, name);
            } 
        });

        const model: any = {
            uuid: this.form.value.main?.uuid ? this.form.value.main?.uuid : '',
            name: name
        };
        this.waiting = true;
        this.error = null;
        this.form.disable();
        try {
           if (this.eventtype) {
               return await this.httpClient.put<EventType>(`/api/event-type/update`, model).toPromise();
            } else {
                 return await this.httpClient.post<EventType>(`/api/event-type/create`, model).toPromise();
             }
        } catch (e) {
            // @ts-ignore
            this.error = e.error.message;
            this.form.enable();
            this.waiting = false;
            throw e;
        }
    }

    public onReferenceBookChange(tab: number): void {
        this.lang.setValue(tab);
    }

    public getLangName(index: number): string {
        return (this.langName.get(index)) as string;
    }
}
