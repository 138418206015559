import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NewCatalogEntityComponent} from './new-catalog-entity.component';
import {MatIconModule} from '@angular/material/icon';


@NgModule({
    declarations: [NewCatalogEntityComponent],
    imports: [
        CommonModule,
        MatIconModule
    ],
    exports: [NewCatalogEntityComponent]
})
export class NewCatalogEntityModule {
}
