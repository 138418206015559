import {
    AfterViewInit,
    Directive,
    ElementRef,
    OnDestroy,
} from '@angular/core';
import Pickr from "@simonwep/pickr";
import {NgControl} from "@angular/forms";
import {Subject, Subscription} from "rxjs";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";

@Directive({
    selector: '[colorPicker]'
})
export class ColorPickerDirective implements AfterViewInit, OnDestroy {

    private readonly color: Subject<string> = new Subject<string>();

    private pickr: Pickr | null = null;

    private colorSubscription: Subscription;

    constructor(
        private elementRef: ElementRef,
        private control: NgControl
    ) {
        this.colorSubscription = this.color.pipe(
            distinctUntilChanged(),
            debounceTime(1)
        ).subscribe((value) => {
            this.control.control?.setValue(value);
        })
    }

    ngAfterViewInit() {
        this.pickr = Pickr.create({
            el: this.elementRef.nativeElement,
            theme: 'nano',
            inline: true,
            showAlways: true,
            default: this.control.value || 'rgb(244, 67, 54)',
            defaultRepresentation: 'HEXA',
            lockOpacity: true,
            swatches: [
                'rgb(244, 67, 54)',
                'rgb(233, 30, 99,)',
                'rgb(156, 39, 176)',
                'rgb(103, 58, 183)',
                'rgb(63, 81, 181)',
                'rgb(33, 150, 243)',
                'rgb(3, 169, 244)',
                'rgb(0, 188, 212)',
                'rgb(0, 150, 136)',
                'rgb(76, 175, 80)',
                'rgb(139, 195, 74)',
                'rgb(205, 220, 57)',
                'rgb(255, 235, 59)',
                'rgb(255, 193, 7)'
            ],
            components: {
                preview: false,
                opacity: false,
                hue: true,
                interaction: {
                    input: true
                }
            }
        }).on('change', (color: Pickr.HSVaColor) => {
            this.color.next(color.toHEXA().toString());
        })
    }

    ngOnDestroy() {
        this.colorSubscription.unsubscribe();
    }

}
