import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
    EventSession,
    EventSessionFull,
    EventSessionFund,
    EventSessionLocation,
    EventSessionLocationScheme,
    EventSessionSeatview,
    EventSessionType,
    EventSessionZone,
    Season
} from '../../shared/types/events';
import {HttpClient} from '@angular/common/http';
import {SeasonCatalogComponent} from '../../components/reference-book/season-catalog/season-catalog.component';
import {EventSessionTypeCatalogComponent} from '../../components/reference-book/event-session-type-catalog/event-session-type-catalog.component';
import {combineLatest, Subscription} from 'rxjs';
import {distinctUntilChanged, switchMap} from 'rxjs/operators';
import {EventSessionSettingsPopupService} from '../event-settings-popup/event-session-settings-popup.service';
import {SettingsPopupState} from '../event-settings-popup/event-session-settings.service';
import {ImagePickerPopupService} from '../image-picker-popup/image-picker-popup.service';

const templatesFilterByLocationSchemeUuid = (locationSchemeUuid: string) => {
    return (entity: { locationSchemeUuid: string, [key: string]: any }): boolean => {
        return entity.locationSchemeUuid === locationSchemeUuid;
    };
};

@Component({
    selector: 'app-cu-subscription-session-popup',
    templateUrl: './cu-subscription-session-popup.component.html',
    styleUrls: ['./cu-subscription-session-popup.component.scss']
})
export class CuSubscriptionSessionPopupComponent implements OnInit, OnDestroy {

    seasonOpened = false;
    eventSessionTypeOpened = false;
    locationOpened = false;
    locationSchemeOpened = false;
    fundOpened = false;
    zoneOpened = false;
    seatviewOpened = false;

    form: FormGroup;

    private seasons: Season[] = [];
    private eventSessionTypes: EventSessionType[] = [];
    private locations: EventSessionLocation[] = [];
    private locationSchemes: EventSessionLocationScheme[] = [];
    private funds: EventSessionFund[] = [];
    private zones: EventSessionZone[] = [];
    private seatviews: EventSessionSeatview[] = [];

    public loading = true;
    public waiting = false;

    public seasonCatalogComponent = SeasonCatalogComponent;
    public eventSessionTypeCatalogComponent = EventSessionTypeCatalogComponent;

    public error: string | null = null;

    private locationSubscription: Subscription;
    private locationSchemeSubscription: Subscription;

    constructor(
        private readonly dialogRef: MatDialogRef<CuSubscriptionSessionPopupComponent>,
        private readonly fb: FormBuilder,
        private readonly httpClient: HttpClient,
        private readonly eventSessionSettingsPopupService: EventSessionSettingsPopupService,
        private readonly imagePickerPopupService: ImagePickerPopupService,
        @Inject(MAT_DIALOG_DATA) public readonly data: { eventUuid: string, eventSession: EventSession | null }
    ) {
        this.form = this.fb.group({
            season: [null],
            eventSessionType: [null, Validators.required],
            location: [null, [Validators.required]],
            locationScheme: [{
                value: null,
                disabled: true
            }, [Validators.required]],
            fund: [{
                value: null,
                disabled: true
            }, [Validators.required]],
            zone: [{
                value: null,
                disabled: true
            }, [Validators.required]],
            seatview: [{
                value: null,
                disabled: true
            }, [Validators.required]],
            logoLink: [null]
        });

        this.locationSubscription = this.form.controls.location.valueChanges
            .pipe(
                distinctUntilChanged(),
                switchMap(() => {
                    this.form.patchValue({
                        locationScheme: null,
                        zone: null,
                        fund: null,
                        seatview: null
                    }, {emitEvent: false});
                    this.form.controls.locationScheme.disable({emitEvent: false});
                    this.form.controls.zone.disable();
                    this.form.controls.fund.disable();
                    this.form.controls.seatview.disable();
                    const locationUuid = this.form.controls.location.value.uuid;
                    return this.httpClient.get<EventSessionLocationScheme[]>(`/api/location-scheme/all`,
                        {params: {locationUuids: locationUuid}}
                    );
                })
            ).subscribe(locationSchemes => {
                this.locationSchemes = locationSchemes;
                this.form.controls.locationScheme.enable({emitEvent: false});
            });

        this.locationSchemeSubscription = this.form.controls.locationScheme.valueChanges
            .pipe(
                distinctUntilChanged(),
                switchMap(() => {
                    this.form.patchValue({
                        zone: null,
                        fund: null,
                        seatview: null
                    });
                    this.form.controls.zone.disable();
                    this.form.controls.fund.disable();
                    this.form.controls.seatview.disable();
                    const locationUuid = this.form.controls.location.value.uuid;
                    return combineLatest([
                        this.httpClient.get<EventSessionFund[]>(`/api/funds-template/all`,
                            {params: {locationUuids: locationUuid}}
                        ),
                        this.httpClient.get<EventSessionZone[]>(`/api/zones-template/all`,
                            {params: {locationUuids: locationUuid}}
                        ),
                        this.httpClient.get<EventSessionSeatview[]>(`/api/seatviews-template/all`,
                            {params: {locationUuids: locationUuid}}
                        ),
                    ]);
                })
            ).subscribe(([funds, zones, seatviews]) => {
                const locationSchemeUuid = this.form.controls.locationScheme.value.uuid;
                const filterFn = templatesFilterByLocationSchemeUuid(locationSchemeUuid);
                this.funds = funds.filter(filterFn);
                this.zones = zones.filter(filterFn);
                this.seatviews = seatviews.filter(filterFn);
                this.form.controls.zone.enable();
                this.form.controls.fund.enable();
                this.form.controls.seatview.enable();
            });
    }

    async ngOnInit(): Promise<void> {
        this.loading = true;
        await Promise.all([
            this.loadSeasons(),
            this.loadEventSessionTypes(),
            this.loadLocations()
        ]);
        if (this.data.eventSession) {
            const eventSession = await this.loadEventSession(this.data.eventSession.uuid);
            const locationUuid = this.data.eventSession.locationUuid;
            await Promise.all([
                this.loadLocationSchemes(locationUuid),
                this.loadFunds(locationUuid, eventSession.locationSchemeUuid),
                this.loadZones(locationUuid, eventSession.locationSchemeUuid),
                this.loadSeatviews(locationUuid, eventSession.locationSchemeUuid)
            ]);
            this.form.patchValue({
                season: this.findSeasonByUuid(eventSession.seasonUuid),
                eventSessionType: this.findEventSessionType(eventSession.eventSessionTypeUuid),
                location: this.findLocationByUuid(locationUuid),
                locationScheme: this.findLocationSchemeByUuid(eventSession.locationSchemeUuid),
                fund: this.findFundByUuid(eventSession.templateFundUuid),
                zone: this.findZoneByUuid(eventSession.templateZoneUuid),
                seatview: this.findSeatviewByUuid(eventSession.templateSeatviewUuid),
                logoLink: eventSession.logoLink
            }, {emitEvent: false});

            this.form.controls.locationScheme.enable({emitEvent: false});
            this.form.controls.zone.enable({emitEvent: false});
            this.form.controls.fund.enable({emitEvent: false});
            this.form.controls.seatview.enable({emitEvent: false});
        }
        this.loading = false;
    }

    ngOnDestroy(): void {
        this.locationSubscription.unsubscribe();
        this.locationSchemeSubscription.unsubscribe();
    }

    public close(created: boolean): void {
        this.dialogRef.close(created);
    }

    public getSeasons(): Season[] {
        return this.seasons;
    }

    public getEventSessionTypes(): EventSessionType[] {
        return this.eventSessionTypes;
    }

    public getLocations(): EventSessionLocation[] {
        return this.locations;
    }

    public getLocationSchemes(): EventSessionLocationScheme[] {
        return this.locationSchemes;
    }

    public getFunds(): EventSessionFund[] {
        return this.funds;
    }

    public getZones(): EventSessionZone[] {
        return this.zones;
    }

    public getSeatviews(): EventSessionSeatview[] {
        return this.seatviews;
    }

    public onSeasonCreate(season: Season): void {
        this.seasons.push(season);
        this.form.controls.season.setValue(season);
    }

    public onEventSessionTypeCreate(eventSessionType: EventSessionType): void {
        this.eventSessionTypes.push(eventSessionType);
        this.form.controls.eventSessionType.setValue(eventSessionType);
    }

    private async loadSeasons(): Promise<void> {
        try {
            const seasons = await this.httpClient.get<Season[]>(`/api/season/all`).toPromise();
            this.seasons = [{name: 'Не выбрано'} as Season, ...seasons];
        } catch (e) {

        }
    }

    private async loadEventSessionTypes(): Promise<void> {
        try {
            this.eventSessionTypes = await this.httpClient.get<EventSessionType[]>(`/api/event-session-type/all`,
                {params: {consistType: 'SEASON_TICKET'}}
            ).toPromise();
        } catch (e) {

        }
    }

    private async loadLocations(): Promise<void> {
        try {
            this.locations = await this.httpClient
                .get<EventSessionLocation[]>(`/api/location/all`).toPromise();
        } catch (e) {

        }
    }

    private async loadLocationSchemes(locationUuid: string): Promise<void> {
        try {
            this.locationSchemes = await this.httpClient.get<EventSessionLocationScheme[]>(`/api/location-scheme/all`,
                {params: {locationUuids: locationUuid}}
            ).toPromise();
        } catch (e) {

        }
    }

    private async loadFunds(locationUuid: string, locationSchemeUuid: string): Promise<void> {
        try {
            const funds = await this.httpClient
                .get<EventSessionFund[]>(`/api/funds-template/all`,
                    {params: {locationUuids: locationUuid}}
                ).toPromise();
            const filterFn = templatesFilterByLocationSchemeUuid(locationSchemeUuid);
            this.funds = funds.filter(filterFn);
        } catch (e) {

        }
    }

    private async loadZones(locationUuid: string, locationSchemeUuid: string): Promise<void> {
        try {
            const zones = await this.httpClient
                .get<EventSessionZone[]>(`/api/zones-template/all`,
                    {params: {locationUuids: locationUuid}}
                ).toPromise();
            const filterFn = templatesFilterByLocationSchemeUuid(locationSchemeUuid);
            this.zones = zones.filter(filterFn);
        } catch (e) {

        }
    }

    private async loadSeatviews(locationUuid: string, locationSchemeUuid: string): Promise<void> {
        try {
            const seatviews = await this.httpClient
                .get<EventSessionSeatview[]>(`/api/seatviews-template/all`,
                    {params: {locationUuids: locationUuid}}
                ).toPromise();
            const filterFn = templatesFilterByLocationSchemeUuid(locationSchemeUuid);
            this.seatviews = seatviews.filter(filterFn);
        } catch (e) {

        }
    }

    private async loadEventSession(uuid: string): Promise<EventSessionFull> {
        return this.httpClient.get<EventSessionFull>(`/api/event-session/one/by-id/${uuid}`).toPromise();
    }

    public async save(): Promise<void> {
        try {
            await this._save();
            this.close(true);
        } catch (e) {

        }
    }

    public async _save(): Promise<EventSession> {
        const model: any = {
            eventSessionTypeUuid: this.form.value.eventSessionType.uuid,
            eventUuid: this.data.eventUuid,
            locationSchemeUuid: this.form.value.locationScheme.uuid,
            seasonUuid: this.form.value.season ? this.form.value.season.uuid : null,
            templateFundUuid: this.form.value.fund.uuid,
            templateSeatviewUuid: this.form.value.seatview.uuid,
            templateZoneUuid: this.form.value.zone.uuid,
            logoLink: this.form.value.logoLink
        };
        if (this.data.eventSession) {
            model.uuid = this.data.eventSession.uuid;
        }
        this.waiting = true;
        this.error = null;
        this.form.disable({emitEvent: false});
        try {
            if (this.data.eventSession) {
                return await this.httpClient.put<EventSession>(`/api/event-session/update`, model).toPromise();
            } else {
                return await this.httpClient.post<EventSession>(`/api/event-session/create`, model).toPromise();
            }
        } catch (e) {
            this.error = e.error.message;
            this.form.enable({emitEvent: false});
            this.waiting = false;
            throw e;
        }
    }


    public async saveAndSettings(): Promise<void> {
        try {
            const eventSession = await this._save();
            this.close(true);
            this.eventSessionSettingsPopupService.open(eventSession.uuid, SettingsPopupState.SEASON_TICKET_CONSISTS);
        } catch (e) {

        }
    }

    public onDateChange(date: Date | null): void {
        this.form.controls.date.setValue(date);
    }

    private findSeasonByUuid(uuid: string): Season | null {
        return this.seasons.find(season => season.uuid === uuid) || null;
    }

    private findLocationByUuid(uuid: string): EventSessionLocation | null {
        return this.locations.find(location => location.uuid === uuid) || null;
    }

    private findLocationSchemeByUuid(uuid: string): EventSessionLocationScheme | null {
        return this.locationSchemes.find(locationScheme => locationScheme.uuid === uuid) || null;
    }

    private findEventSessionType(uuid: string): EventSessionType | null {
        return this.eventSessionTypes.find(eventSessionType => eventSessionType.uuid === uuid) || null;
    }

    private findFundByUuid(uuid: string): EventSessionFund | null {
        return this.funds.find(fund => fund.uuid === uuid) || null;
    }

    private findZoneByUuid(uuid: string): EventSessionZone | null {
        return this.zones.find(zone => zone.uuid === uuid) || null;
    }

    private findSeatviewByUuid(uuid: string): EventSessionSeatview | null {
        return this.seatviews.find(seatview => seatview.uuid === uuid) || null;
    }

    public async selectImage(): Promise<void> {
        try {
            const resourceObject = await this.imagePickerPopupService.open();
            this.form.patchValue({
                logoLink: resourceObject.url
            }, {emitEvent: false});
        } catch (e) {

        }
    }

    public removeLink(): void {
        this.form.patchValue({
            logoLink: null
        }, {emitEvent: false});
    }

    public downloadResourceObject(): void {
        const link = document.createElement('a');
        link.target = '_blank';
        link.href = this.form.controls.logoLink.value;
        document.body.appendChild(link);
        link.click();
        link.remove();
    }
}
