import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {EventSessionFull} from '../../shared/types/events';
import {formatDate} from '../../shared/utils/date-utils';

export enum SettingsPopupState {
    EVENT_SESSION_INFO,
    SUBSCRIPTION_SESSION_INFO,
    EVENT_TICKET_CONSISTS,
    SEASON_TICKET_CONSISTS,
    TICKET_LAYOUTS,
    PRICE_RATES,
    OPEN_SALES,
    SALE_LIMITS,
    ANNOUNCEMENT
}

export enum SettingsLoadingStatus {
    UNKNOWN,
    LOADING,
    LOADED
}

@Injectable({
    providedIn: 'root'
})
export class EventSessionSettingsService {
    public static state = SettingsPopupState;
    public loading: BehaviorSubject<SettingsLoadingStatus> = new BehaviorSubject<SettingsLoadingStatus>(SettingsLoadingStatus.UNKNOWN);

    private eventSessionUuid: string | null = null;
    private eventSession: EventSessionFull | null = null;

    private currentState: SettingsPopupState | null = null;

    constructor(
        private readonly httpClient: HttpClient
    ) {
    }

    public init(eventSessionUuid: string, state: SettingsPopupState): void {
        this.eventSessionUuid = eventSessionUuid;
        this.currentState = state;
        this.eventSession = null;

        this.loadEventSession();
    }

    public getCurrentState(): SettingsPopupState | null {
        return this.currentState;
    }

    public setCurrentState(state: SettingsPopupState): void {
        this.currentState = state;
    }

    public getEventSessionUuid(): string | null {
        return this.eventSessionUuid;
    }

    public getEventSession(): EventSessionFull | null {
        return this.eventSession;
    }

    private async loadEventSession(): Promise<void> {
        this.loading.next(SettingsLoadingStatus.LOADING);
        try {
            const eventSession = await this.httpClient
                .get<EventSessionFull>(`/api/event-session/one/by-id/${this.eventSessionUuid}`).toPromise();

            if(eventSession.consistType === 'TICKET') {
                eventSession.time = formatDate(eventSession.dateBegin, 'HH:mm');
                eventSession.day = formatDate(eventSession.dateBegin, 'EEEEEE, d MMM yyyy');
            }

            this.eventSession = eventSession;
        } catch (e) {

        }
        this.loading.next(SettingsLoadingStatus.LOADED);
    }
}
