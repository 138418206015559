import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl} from '@angular/forms';
import {debounceTime, distinctUntilChanged, switchMap, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {CopySubscriptionSessionPricesFilter, EventSession, EventSessionMatchDay, EventSessionType, Season} from '../../shared/types/events';
import {HttpClient} from '@angular/common/http';
import {FilterDataOption, FilterDataSource} from '../../../shared/ui-kit/filter/filter.component';
import {SortState} from '../../../shared/ui-kit/sort/sort.component';
import {FullscreenLoaderService} from '../../../shared/ui-kit/fullscreen-loader/fullscreen-loader.service';

interface PopupData {
    eventSessionUuid: string;
    locationUuid: string;
}

@Component({
    selector: 'app-copy-subscription-session-prices-from-popup',
    templateUrl: './copy-subscription-session-prices-from-popup.component.html',
    styleUrls: ['./copy-subscription-session-prices-from-popup.component.scss']
})
export class CopySubscriptionSessionPricesFromPopupComponent implements OnInit, OnDestroy {

    public error: string | null = null;

    public search: FormControl = new FormControl('');
    private destroy$: Subject<void> = new Subject<void>();

    public eventSessionFilter: CopySubscriptionSessionPricesFilter = {
        eventSessionTypes: [],
        seasons: []
    };
    private filter$: Subject<CopySubscriptionSessionPricesFilter> = new Subject<CopySubscriptionSessionPricesFilter>();

    displayedColumns: string[] = ['name', 'season'];
    dataSource: EventSession[] = [];
    tableData: EventSession[] = [];

    seasonsFilter: FilterDataSource = new FilterDataSource([]);
    eventSessionTypeFilter: FilterDataSource = new FilterDataSource([]);

    private sort: {
        field: string,
        direction: SortState
    } = {field: '', direction: SortState.DEFAULT};

    public loading = false;
    public tableLoading = false;

    constructor(
        private readonly dialogRef: MatDialogRef<CopySubscriptionSessionPricesFromPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: PopupData,
        private readonly httpClient: HttpClient,
        private readonly fullscreenLoaderService: FullscreenLoaderService
    ) {
        this.filter$.pipe(
            switchMap(eventFilter => {
                this.tableLoading = true;
                const params: any = {
                    eventConsistType: 'SEASON_TICKET',
                    eventSessionTypeUuids: eventFilter.eventSessionTypes?.map(eventSessionType => eventSessionType.uuid) || [],
                    locationUuids: [this.data.locationUuid],
                    seasonUuids: eventFilter.seasons?.map(season => season.uuid) || []
                };
                return this.httpClient.get<EventSession[]>(`/api/event-session/all`, {params});
            }),
            takeUntil(this.destroy$)
        ).subscribe((eventSessions) => {
            this.tableLoading = false;
            this.dataSource = eventSessions
                .filter(eventSession => eventSession.uuid !== this.data.eventSessionUuid);
            this.setPageData();
        });

        this.search.valueChanges
            .pipe(
                debounceTime(250),
                distinctUntilChanged(),
                takeUntil(this.destroy$)
            )
            .subscribe(() => {
                this.setPageData();
            });
    }

    ngOnInit(): void {
        this.load();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();

        this.filter$.complete();
    }

    private async load(): Promise<void> {
        this.filter$.next(this.eventSessionFilter);
        this.loading = true;
        await Promise.all([
            this.loadSeasons(),
            this.loadEventSessionTypes()
        ]);
        this.loading = false;
    }

    public async close(status: boolean): Promise<void> {
        this.dialogRef.close(status);
    }

    private setPageData(): void {
        const query = this.search.value.toLowerCase();

        const eventsCopy = this.dataSource
            .filter((event: EventSession) => {
                return event.eventName.toLowerCase().includes(query);
            });

        if (this.sort.direction !== SortState.DEFAULT) {
            eventsCopy.sort((a, b): number => {
                let compareResult = 0;
                const left = (a as any)[this.sort.field];
                const right = (b as any)[this.sort.field];
                if (left === null || left === undefined) {
                    compareResult = 1;
                } else if (right === null || right === undefined) {
                    compareResult = -1;
                } else {
                    if (left > right) {
                        compareResult = 1;
                    } else {
                        compareResult = -1;
                    }
                }
                if (this.sort.direction === SortState.DESC) {
                    compareResult *= -1;
                }
                return compareResult;
            });
        }
        this.tableData = eventsCopy;
    }

    private async loadSeasons(): Promise<void> {
        try {
            const seasons = await this.httpClient.get<Season[]>(`/api/season/all`).toPromise();
            this.seasonsFilter = new FilterDataSource(seasons);
        } catch (e) {

        }
    }

    private async loadEventSessionTypes(): Promise<void> {
        try {
            const eventSessionTypes = await this.httpClient
                .get<EventSessionType[]>(`/api/event-session-type/all`, {params: {consistType: 'SEASON_TICKET'}}).toPromise();
            this.eventSessionTypeFilter = new FilterDataSource(eventSessionTypes);
        } catch (e) {

        }
    }

    public onSeasonsChange(seasons: FilterDataOption[]): void {
        this.eventSessionFilter.seasons = seasons as Season[];
        this.filter$.next(this.eventSessionFilter);
    }

    public onEventSessionTypeChange(eventSessionTypes: FilterDataOption[]): void {
        this.eventSessionFilter.eventSessionTypes = eventSessionTypes as EventSessionType[];
        this.filter$.next(this.eventSessionFilter);
    }

    public resetFilters(): void {
        this.eventSessionFilter = {
            seasons: [],
            eventSessionTypes: []
        };
        this.seasonsFilter = new FilterDataSource(this.seasonsFilter.options);
        this.eventSessionTypeFilter = new FilterDataSource(this.eventSessionTypeFilter.options);
        this.search.setValue('');
        this.filter$.next(this.eventSessionFilter);
    }

    public onSortChange(field: string, direction: SortState): void {
        this.sort = {field, direction};
        this.setPageData();
    }

    public getSortDirection(field: string): SortState {
        if (this.sort.field === field) {
            return this.sort.direction;
        }
        return SortState.DEFAULT;
    }

    public async rowClick(eventSession: EventSession): Promise<void> {
        this.error = null;
        this.fullscreenLoaderService.open();
        try {
            const model = {
                fromEventSessionUuid: eventSession.uuid,
                toEventSessionUuids: [this.data.eventSessionUuid]
            };
            await this.httpClient.put(`/api/price/copy`, model).toPromise();
            this.close(true);
        } catch (e) {
            this.error = e.error.message;
        }

        this.fullscreenLoaderService.close();
    }
}
