<div class="page-container">
    <div class="error-container" *ngIf="error">
        <inline-alert-component [text]="error"></inline-alert-component>
    </div>
    <div class="create-form-container" *ngIf="!loading">
        <div class="create-form-header">
            <span class="body-text-2">Добавить макет</span>
            <button
                    mat-button
                    disableRipple
                    type="button"
                    class="button-primary-basic-text"
                    color="primary"
                    (click)="resetForm()"
            >
                Сбросить
            </button>
        </div>
        <div class="create-form-body" [formGroup]="form">
            <div class="create-form-field-device-model">
                <mat-form-field appearance="fill" color="primary" class="input-select"
                                [class.input-select__opened]="deviceModelOpened"
                                [class.input-select__disabled]="form.controls.deviceModel.disabled"
                >
                    <mat-label>Модель принтера</mat-label>
                    <mat-icon svgIcon="dropdown-arrow" matSuffix></mat-icon>
                    <mat-select (openedChange)="deviceModelOpened = $event" formControlName="deviceModel" disableRipple
                                disableOptionCentering
                                panelClass="dropdown-panel">
                        <div appSimplebarScroller>
                            <mat-option *ngFor="let deviceMode of getDeviceModels()" [value]="deviceMode">
                                {{deviceMode.name}}
                            </mat-option>
                        </div>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="create-form-field-print-image">
                <mat-form-field appearance="fill" color="primary" class="input-select"
                                [class.input-select__opened]="printImageOpened"
                                [class.input-select__disabled]="form.controls.printImage.disabled"
                >
                    <mat-label>Макета билета</mat-label>
                    <mat-icon svgIcon="dropdown-arrow" matSuffix></mat-icon>
                    <mat-select (openedChange)="printImageOpened = $event" formControlName="printImage" disableRipple
                                disableOptionCentering
                                panelClass="dropdown-panel">
                        <div appSimplebarScroller>
                            <mat-option *ngFor="let printImage of getPrintImages()" [value]="printImage">
                                {{printImage.name}}
                            </mat-option>
                        </div>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="create-form-field-category">
                <mat-form-field appearance="fill" color="primary" class="input-select"
                                [class.input-select__opened]="categoryOpened"
                                [class.input-select__disabled]="form.controls.category.disabled"
                >
                    <mat-label>Категория</mat-label>
                    <mat-icon svgIcon="dropdown-arrow" matSuffix></mat-icon>
                    <mat-select (openedChange)="categoryOpened = $event" formControlName="category" disableRipple
                                disableOptionCentering
                                panelClass="dropdown-panel">
                        <div appSimplebarScroller>
                            <mat-option *ngFor="let category of getCategories()" [value]="category">
                                {{category.name}}
                            </mat-option>
                        </div>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="create-form-field-zone">
                <mat-form-field appearance="fill" color="primary" class="input-select"
                                [class.input-select__opened]="zoneOpened"
                                [class.input-select__disabled]="form.controls.zone.disabled"
                >
                    <mat-label>Зона</mat-label>
                    <mat-icon svgIcon="dropdown-arrow" matSuffix></mat-icon>
                    <mat-select (openedChange)="zoneOpened = $event" formControlName="zone" disableRipple
                                disableOptionCentering
                                panelClass="dropdown-panel">
                        <div appSimplebarScroller>
                            <mat-option *ngFor="let zone of getZones()" [value]="zone">
                                {{zone.name}}
                            </mat-option>
                        </div>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="create-form-field-action">
                <button
                        mat-stroked-button
                        disableRipple
                        type="button"
                        class="button-primary-outline"
                        [disabled]="form.controls.deviceModel.value === null || form.controls.printImage.value === null || form.controls.category.value === null || form.controls.zone.value === null"
                        color="primary"
                        (click)="addSessionPrintImage()"
                >
                    <mat-icon svgIcon="checkmark" class="mat-button-icon_left"></mat-icon>
                    Добавить
                </button>
            </div>
        </div>
    </div>
    <div class="create-form-container" *ngIf="loading">
        <div class="create-form-header">
            <span class="body-text-2"><skeleton-text [width]="140" [height]="18"></skeleton-text></span>
            <skeleton-text [width]="100" [height]="32"></skeleton-text>
        </div>
        <div class="create-form-body" [formGroup]="form">
            <div class="create-form-field-device-model">
                <skeleton-text [height]="44"></skeleton-text>
            </div>
            <div class="create-form-field-print-image">
                <skeleton-text [height]="44"></skeleton-text>
            </div>
            <div class="create-form-field-category">
                <skeleton-text [height]="44"></skeleton-text>
            </div>
            <div class="create-form-field-zone">
                <skeleton-text [height]="44"></skeleton-text>
            </div>
            <div class="create-form-field-action">
                <skeleton-text [width]="162" [height]="44"></skeleton-text>
            </div>
        </div>
    </div>


    <div class="print-images-container" *ngIf="!loading">
        <div class="print-images-actions">
            <div class="print-images-action-checkbox">
                <mat-checkbox
                        disableRipple
                        class="input-checkbox"
                        color="primary"
                        (change)="onSelectAllChange($any($event))"
                        [checked]="selectedSessionPrintImages.length > 0 && selectedSessionPrintImages.length === sessionPrintImages.length"
                        [indeterminate]="sessionPrintImages.length !== selectedSessionPrintImages.length && selectedSessionPrintImages.length > 0"
                >
                </mat-checkbox>
            </div>
            <div class="body-text-3 print-images-action-label">
                Выбрано: <span>{{selectedSessionPrintImages.length}}</span> из
                <span>{{sessionPrintImages.length}}</span>
            </div>
            <div class="print-images-action-divider"></div>
            <button
                    mat-button
                    disableRipple
                    type="button"
                    class="button-primary-basic-filled"
                    [disabled]="selectedSessionPrintImages.length === 0"
                    color="primary"
                    (click)="removeSelectedPrintImages()"
            >
                Удалить
            </button>
        </div>
        <div class="print-images-table">
            <mat-table [dataSource]="sessionPrintImages" appSimplebarScroller [scrollWithMouse]="true">

                <ng-container matColumnDef="select-row" sticky>
                    <mat-header-cell *matHeaderCellDef class="mat-cell-sticky-start"></mat-header-cell>
                    <mat-cell *matCellDef="let element" class="mat-cell-sticky-start">
                        <div class="mat-cell-content">
                            <mat-checkbox
                                    disableRipple
                                    class="input-checkbox"
                                    color="primary"
                                    [checked]="element.selected"
                                    (click)="$event.preventDefault()"
                            >
                            </mat-checkbox>
                        </div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="printer">
                    <mat-header-cell *matHeaderCellDef>
                        <div class="mat-cell-content">
                            <div class="body-text-3">Принтер</div>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="mat-cell-content">
                            <div class="body-text-3">{{element.deviceModelName}}</div>
                        </div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="print-image">
                    <mat-header-cell *matHeaderCellDef>
                        <div class="mat-cell-content">
                            <div class="body-text-3">Макет билета</div>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="mat-cell-content">
                            <div class="body-text-3">{{element.printImageName}}</div>
                        </div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="category">
                    <mat-header-cell *matHeaderCellDef>
                        <div class="mat-cell-content">
                            <div class="body-text-3">Категория</div>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="mat-cell-content">
                            <div class="body-text-3">{{element.categoryName || 'Все'}}</div>
                        </div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="zone">
                    <mat-header-cell *matHeaderCellDef>
                        <div class="mat-cell-content">
                            <div class="body-text-3">Зона</div>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="mat-cell-content">
                            <div class="body-text-3">{{element.zoneName || 'Все'}}</div>
                        </div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
                        <div class="mat-cell-content">
                            <button
                                    mat-icon-button
                                    disableRipple
                                    type="button"
                                    class="button-icon"
                                    color="primary"
                                    (click)="removePrintImage(element)"
                            >
                                <mat-icon svgIcon="basket"></mat-icon>
                            </button>
                        </div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="empty-scrollbar-row">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let element"></mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="tableColumns; sticky: true"></mat-header-row>

                <mat-row *matRowDef="let row; columns: tableColumns;"
                         (click)="togglePrintImageSelection(row)"></mat-row>

                <mat-footer-row class="empty-scrollbar-row"
                                *matFooterRowDef="['empty-scrollbar-row'];"></mat-footer-row>
            </mat-table>
        </div>
    </div>

    <div class="print-images-container" *ngIf="loading">
        <div class="print-images-actions">
            <div class="print-images-action-checkbox"></div>
            <div class="body-text-3 print-images-action-label">
                <skeleton-text [inverseColor]="true" [width]="111" [height]="18"></skeleton-text>
            </div>
            <div class="print-images-action-divider"></div>
            <skeleton-text [inverseColor]="true" [width]="95" [height]="32"></skeleton-text>
        </div>
        <div class="print-images-table">
            <mat-table [dataSource]="[1,2,3]" appSimplebarScroller>

                <ng-container matColumnDef="select-row" sticky>
                    <mat-header-cell *matHeaderCellDef class="mat-cell-sticky-start"></mat-header-cell>
                    <mat-cell *matCellDef="let element" class="mat-cell-sticky-start">
                        <div class="mat-cell-content">
                        </div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="printer">
                    <mat-header-cell *matHeaderCellDef>
                        <div class="mat-cell-content">
                            <skeleton-text [height]="12" [width]="100"></skeleton-text>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="mat-cell-content">
                            <skeleton-text [height]="18"></skeleton-text>
                        </div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="print-image">
                    <mat-header-cell *matHeaderCellDef>
                        <div class="mat-cell-content">
                            <skeleton-text [height]="12" [width]="100"></skeleton-text>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="mat-cell-content">
                            <skeleton-text [height]="18"></skeleton-text>
                        </div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="category">
                    <mat-header-cell *matHeaderCellDef>
                        <div class="mat-cell-content">
                            <skeleton-text [height]="12" [width]="100"></skeleton-text>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="mat-cell-content">
                            <skeleton-text [height]="18"></skeleton-text>
                        </div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="zone">
                    <mat-header-cell *matHeaderCellDef>
                        <div class="mat-cell-content">
                            <skeleton-text [height]="12" [width]="100"></skeleton-text>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="mat-cell-content">
                            <skeleton-text [height]="18"></skeleton-text>
                        </div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
                        <div class="mat-cell-content"></div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="empty-scrollbar-row">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let element"></mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="tableColumns; sticky: true"></mat-header-row>

                <mat-row *matRowDef="let row; columns: tableColumns;"
                         (click)="togglePrintImageSelection(row)"></mat-row>

                <mat-footer-row class="empty-scrollbar-row"
                                *matFooterRowDef="['empty-scrollbar-row'];"></mat-footer-row>
            </mat-table>
        </div>
    </div>


    <div class="actions" *ngIf="!loading">
        <button
                mat-raised-button
                disableRipple
                type="button"
                class="button-primary-filled"
                color="success"
                (click)="next()"
        >
            Далее
        </button>
    </div>

    <div class="actions" *ngIf="loading">
        <skeleton-text [inverseColor]="true" [width]="95" [height]="36"></skeleton-text>
    </div>
</div>
