import {Inject, Injectable, LOCALE_ID} from "@angular/core";
import {getLocaleFirstDayOfWeek} from "@angular/common";

@Injectable({
    providedIn: 'root'
})
export class DateService {

    readonly DAYS_IN_WEEK: number = 7;

    private _today = new Date();

    public readonly today = new Date(this._today.getFullYear(), this._today.getMonth(), this._today.getDate()).getTime();

    constructor(
        @Inject(LOCALE_ID) private readonly locale: string
    ) {
    }

    createDate(year: number, month: number, date: number): Date {
        return new Date(year, month, date);
    }

    getMonthEnd(date: Date): Date {
        return this.createDate(date.getFullYear(), date.getMonth() + 1, 0);
    }

    getMonthStart(date: Date): Date {
        return this.createDate(date.getFullYear(), date.getMonth(), 1);
    }

    getNumberOfDaysInMonth(date: Date): number {
        return this.getMonthEnd(date).getDate();
    }

    getFirstDayOfWeek(): number {
        return getLocaleFirstDayOfWeek(this.locale);
    }

    getDayOfWeek(date: Date): number {
        return date.getDay();
    }

    getMonth(date: Date): number {
        return date.getMonth();
    }

    getYear(date: Date): number {
        return date.getFullYear();
    }

    addMonth(date: Date, num: number): Date {
        const month = this.createDate(date.getFullYear(), date.getMonth() + num, 1);
        month.setDate(Math.min(date.getDate(), this.getMonthEnd(month).getDate()));
        return month;
    }
}
