import {Component, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {MatMenuTrigger} from "@angular/material/menu";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {format, parse, set} from 'date-fns';
import {ru} from "date-fns/locale";
import {Subscription} from "rxjs";

@Component({
    selector: 'input-date-time-range-picker',
    templateUrl: './input-date-time-range-picker.component.html',
    styleUrls: ['./input-date-time-range-picker.component.scss']
})
export class InputDateTimeRangePickerComponent implements OnDestroy {
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger | undefined;

    @Input()
    start: Date | null = null;

    @Input()
    end: Date | null = null;

    @Input()
    placeholder = 'Дата';

    @Input()
    white = false;

    @Output()
    rangeChange: EventEmitter<{ start: Date | null, end: Date | null }> = new EventEmitter<{ start: Date | null; end: Date | null }>();

    public form: FormGroup;

    public error = false;

    private startSubscription: Subscription;
    private endSubscription: Subscription;
    private formSubscription: Subscription;

    constructor(
        private readonly fb: FormBuilder
    ) {
        this.form = this.fb.group({
            start: [null, [Validators.required]],
            end: [null, [Validators.required]],
            startDate: [null, [Validators.required]],
            endDate: [null, [Validators.required]],
            startTime: ['00:00', [Validators.required]],
            endTime: ['23:59', [Validators.required]]
        })

        this.startSubscription = this.form.controls.start.valueChanges.subscribe(() => {
            if (this.form.controls.start.valid) {
                this.form.controls.startDate.setValue(
                    parse(this.form.controls.start.value, 'dd.MM.yyyy', new Date())
                )
            } else {
                this.form.controls.startDate.setValue(null);
            }
        });

        this.endSubscription = this.form.controls.end.valueChanges.subscribe(() => {
            if (this.form.controls.end.valid) {
                this.form.controls.endDate.setValue(
                    parse(this.form.controls.end.value, 'dd.MM.yyyy', new Date())
                )
            } else {
                this.form.controls.endDate.setValue(null);
            }
        });

        this.formSubscription = this.form.valueChanges.subscribe(() => {
            this.error = false;
        });
    }

    ngOnDestroy(): void {
        this.startSubscription.unsubscribe();
        this.endSubscription.unsubscribe();
        this.formSubscription.unsubscribe();
    }

    dateRangeChange({start, end}: { start: Date | null, end: Date | null }) {
        this.form.patchValue({
            start: start === null ? null : format(start, 'dd.MM.yyyy'),
            startDate: start,
            end: end === null ? null : format(end, 'dd.MM.yyyy'),
            endDate: end
        });
    }

    apply(): void {
        const start = set(this.form.value.startDate, {
            hours: parse(this.form.value.startTime, 'HH:mm', new Date()).getHours(),
            minutes: parse(this.form.value.startTime, 'HH:mm', new Date()).getMinutes()
        });
        const end = set(this.form.value.endDate, {
            hours: parse(this.form.value.endTime, 'HH:mm', new Date()).getHours(),
            minutes: parse(this.form.value.endTime, 'HH:mm', new Date()).getMinutes()
        });
        if (start > end) {
            this.error = true;
            return;
        }
        this.rangeChange.emit({start, end});
        this.close();
    }

    close() {
        this.trigger?.closeMenu();
    }

    onMenuOpen() {
        this.form.patchValue({
            start: this.start === null ? null : format(this.start, 'dd.MM.yyyy'),
            end: this.end === null ? null : format(this.end, 'dd.MM.yyyy'),
            startDate: this.start === null ? null : set(this.start, {hours: 0, minutes: 0}),
            endDate: this.end === null ? null : set(this.end, {hours: 0, minutes: 0}),
            startTime: this.start === null ? '00:00' : format(this.start, 'HH:mm'),
            endTime: this.end === null ? '23:59' : format(this.end, 'HH:mm')
        })
    }

    getCaption(): string {
        if (this.start !== null && this.end !== null) {
            return `${format(this.start, 'dd MMM HH:mm', {locale: ru})} - ${format(this.end, 'dd MMM HH:mm', {locale: ru})}`;
        }
        return this.placeholder;
    }
}
