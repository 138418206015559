import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    Output
} from "@angular/core";
import {DateService} from "../../date.service";
import {BaseCell} from "./base-cell";

@Component({
    selector: 'calendar-day-cell',
    template: `
        <span class="calendar-cell-content">{{date === null ? '' : date.getDate()}}</span>
    `,
    styleUrls: ['./base-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseCellComponent implements BaseCell {
    @Input() date: Date | null = null;

    @Input() selectedValue: Date | null = null;

    @Output() select: EventEmitter<Date> = new EventEmitter(true);

    constructor(
        private dateService: DateService
    ) {
    }

    @HostBinding('class.calendar-day-today') get today(): boolean {
        if (!(this.date instanceof Date)) {
            return false;
        }
        return this.date.getTime() === this.dateService.today;
    }

    @HostBinding('class.calendar-day-empty') get empty(): boolean {
        return this.date === null;
    }

    @HostBinding('class.calendar-day-selected') get selected(): boolean {
        if (!(this.selectedValue instanceof Date)) {
            return false;
        }
        if (!(this.date instanceof Date)) {
            return false;
        }
        return this.selectedValue.getTime() === this.date.getTime();
    }

    @HostListener('click')
    onClick() {
        if (!(this.date instanceof Date)) {
            return
        }
        this.select.emit(this.date);
    }
}
