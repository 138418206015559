import {NgModule} from '@angular/core';
import {LabelComponent} from './label/label.component';
import {ButtonMenuComponent} from './button-menu/button-menu.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {IconButtonMenuComponent} from './icon-button-menu/icon-button-menu.component';
import {InlineSpinnerComponent} from './inline-spinner/inline-spinner.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {CommonModule} from '@angular/common';
import {FilterComponent} from './filter/filter.component';
import {PaginationComponent} from './pagination/pagination.component';
import {CalendarModule} from './calendar/calendar.module';
import {SortModule} from './sort/sort.module';
import {RangeSliderModule} from './range-slider/range-slider.module';
import {SkeletonModule} from './skeleton/skeleton.module';
import {InlineAlertModule} from './inline-alert/inline-alert.module';
import {FullscreenLoaderModule} from './fullscreen-loader/fullscreen-loader.module';
import {SnackBarModule} from './snack-bar/snack-bar.module';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {InputDateTimeRangePickerModule} from './input-date-time-range-picker/input-date-time-range-picker.module';
import {SimplebarScrollerModule} from './directives/simple-scroller/simplebar-scroller.module';
import {InputDateTimePickerModule} from './input-date-time-picker/input-date-time-picker.module';
import {ColorPickerModule} from './directives/color-picker/color-picker.module';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
  declarations: [
    LabelComponent,
    ButtonMenuComponent,
    IconButtonMenuComponent,
    InlineSpinnerComponent,
    FilterComponent,
    PaginationComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SimplebarScrollerModule,
    MatMenuModule,
    MatCheckboxModule,
    MatButtonModule,
    MatListModule,
    MatIconModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    CalendarModule,
    SortModule,
    RangeSliderModule,
    SkeletonModule,
    InlineAlertModule,
    FullscreenLoaderModule,
    SnackBarModule,
    InputDateTimeRangePickerModule,
    InputDateTimePickerModule,
    ColorPickerModule,
    MatInputModule,
  ],
  exports: [
    SimplebarScrollerModule,
    LabelComponent,
    ButtonMenuComponent,
    IconButtonMenuComponent,
    InlineSpinnerComponent,
    FilterComponent,
    PaginationComponent,
    CalendarModule,
    SortModule,
    RangeSliderModule,
    SkeletonModule,
    InlineAlertModule,
    SnackBarModule,
    InputDateTimeRangePickerModule,
    InputDateTimePickerModule,
    ColorPickerModule
  ],
  providers: []
})
export class UiKitComponentsModule {

}
