import {NgModule} from '@angular/core';
import {NumberFormatterDirective} from './number-formatter.directive';

@NgModule({
    declarations: [NumberFormatterDirective],
    exports: [NumberFormatterDirective]
})
export class NumberFormatterModule {

}
