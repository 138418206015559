<div class="page-container" *ngIf="!loading">
    <div class="event-info">
        <div class="info-col">
            <div class="col-title body-text-3">Состав команд</div>
            <div class="col-value body-text-3">{{getEventSession()?.teamAName}} - {{getEventSession()?.teamBName}}</div>
        </div>
        <div class="info-col">
            <div class="col-title body-text-3">Тип</div>
            <div class="col-value body-text-3">{{getEventSession()?.eventTypeName}}</div>
        </div>
    </div>

    <div class="settings" [formGroup]="form">
        <div class="settings-row settings-subtitle">Событие</div>
        <div class="settings-row">
            <mat-form-field appearance="fill" color="primary" class="input-select"
                            [class.input-select__opened]="seasonOpened"
                            [class.input-select__disabled]="form.disabled"
            >
                <mat-label>Сезон</mat-label>
                <mat-icon svgIcon="dropdown-arrow" matSuffix></mat-icon>
                <mat-select #seasonSelect (openedChange)="seasonOpened = $event" disableRipple
                            formControlName="season" disableOptionCentering
                            [panelClass]="['dropdown-panel', 'dropdown-panel-advanced']">
                    <div appSimplebarScroller>
                        <mat-option *ngFor="let season of getSeasons()" [value]="season">
                            {{season.name}}
                        </mat-option>
                    </div>
                    <div class="create-container" *ngIf="seasonOpened">
                        <app-base-catalog
                                [placeholder]="'Добавить сезон'"
                                [component]="seasonCatalogComponent"
                                (create)="onSeasonCreate($event); seasonSelect.close()"></app-base-catalog>
                    </div>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="settings-row settings-row-2">
            <input-date-time-picker placeholder="Дата и время*" [disabled]="form.disabled" [date]="form.value.date"
                                    (change)="onDateChange($event)"></input-date-time-picker>
            <mat-form-field class="input-field" color="primary">
                <mat-label>Продолжительность*</mat-label>
                <input type="text" mask="Hh:m0" [dropSpecialCharacters]="false" [leadZeroDateTime]="true"
                       autocomplete="off" matInput formControlName="duration">
            </mat-form-field>
        </div>
        <div class="settings-row">
            <mat-form-field appearance="fill" color="primary" class="input-select"
                            [class.input-select__opened]="eventSessionTypeOpened"
                            [class.input-select__disabled]="form.disabled"
            >
                <mat-label>Турнир/Тип*</mat-label>
                <mat-icon svgIcon="dropdown-arrow" matSuffix></mat-icon>
                <mat-select #eventSessionTypeSelect (openedChange)="eventSessionTypeOpened = $event" disableRipple
                            formControlName="eventSessionType" disableOptionCentering
                            [panelClass]="['dropdown-panel', 'dropdown-panel-advanced']">
                    <div appSimplebarScroller>
                        <mat-option *ngFor="let eventSessionType of getEventSessionTypes()" [value]="eventSessionType">
                            {{eventSessionType.name}}
                        </mat-option>
                    </div>
                    <div class="create-container" *ngIf="eventSessionTypeOpened">
                        <app-base-catalog
                                [placeholder]="'Добавить тип'"
                                [component]="eventSessionTypeCatalogComponent"
								[componentContext]="{consistType: 'TICKET'}"
                                (create)="eventSessionTypeSelect.close()"></app-base-catalog>
                    </div>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="settings-row">
            <mat-form-field appearance="fill" color="primary" class="input-select"
                            [class.input-select__opened]="matchDayOpened"
                            [class.input-select__disabled]="form.disabled"
            >
                <mat-label>Стадия</mat-label>
                <mat-icon svgIcon="dropdown-arrow" matSuffix></mat-icon>
                <mat-select #matchDaySelect (openedChange)="matchDayOpened = $event" disableRipple
                            formControlName="matchDay" disableOptionCentering
                            [panelClass]="['dropdown-panel', 'dropdown-panel-advanced']">
                    <div appSimplebarScroller>
                        <mat-option *ngFor="let matchDay of getMatchDays()" [value]="matchDay">
                            {{matchDay.name}}
                        </mat-option>
                    </div>
                    <div class="create-container" *ngIf="matchDayOpened">
                        <app-base-catalog
                                [placeholder]="'Добавить стадию'"
                                [component]="matchDayCatalogComponent"
                                (create)="onMatchDayCreate($event); matchDaySelect.close()"></app-base-catalog>
                    </div>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="settings-row settings-row-2">
            <mat-form-field appearance="fill" color="primary" class="input-select"
                            [class.input-select__opened]="locationOpened"
                            [class.input-select__disabled]="form.disabled"
            >
                <mat-label>Место проведения*</mat-label>
                <mat-icon svgIcon="dropdown-arrow" matSuffix></mat-icon>
                <mat-select (openedChange)="locationOpened = $event" formControlName="location" disableRipple
                            disableOptionCentering
                            panelClass="dropdown-panel">
                    <div appSimplebarScroller>
                        <mat-option *ngFor="let location of getLocations()" [value]="location">
                            {{location.name}}
                        </mat-option>
                    </div>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" color="primary" class="input-select"
                            [class.input-select__opened]="locationSchemeOpened"
                            [class.input-select__disabled]="form.controls.locationScheme.disabled"
            >
                <mat-label>Схема мест*</mat-label>
                <mat-icon svgIcon="dropdown-arrow" matSuffix></mat-icon>
                <mat-select (openedChange)="locationSchemeOpened = $event" formControlName="locationScheme"
                            disableRipple
                            disableOptionCentering
                            panelClass="dropdown-panel">
                    <div appSimplebarScroller>
                        <mat-option *ngFor="let locationScheme of getLocationSchemes()" [value]="locationScheme">
                            {{locationScheme.name}}
                        </mat-option>
                    </div>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="settings-row settings-subtitle">Шаблоны</div>
        <div class="settings-row">
            <mat-form-field appearance="fill" color="primary" class="input-select"
                            [class.input-select__opened]="fundOpened"
                            [class.input-select__disabled]="form.controls.fund.disabled"
            >
                <mat-label>Фонды*</mat-label>
                <mat-icon svgIcon="dropdown-arrow" matSuffix></mat-icon>
                <mat-select (openedChange)="fundOpened = $event" formControlName="fund" disableRipple
                            disableOptionCentering
                            panelClass="dropdown-panel">
                    <div appSimplebarScroller>
                        <mat-option *ngFor="let fund of getFunds()" [value]="fund">
                            {{fund.name}}
                        </mat-option>
                    </div>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="settings-row">
            <mat-form-field appearance="fill" color="primary" class="input-select"
                            [class.input-select__opened]="zoneOpened"
                            [class.input-select__disabled]="form.controls.zone.disabled"
            >
                <mat-label>Ценовые зоны*</mat-label>
                <mat-icon svgIcon="dropdown-arrow" matSuffix></mat-icon>
                <mat-select (openedChange)="zoneOpened = $event" formControlName="zone" disableRipple
                            disableOptionCentering
                            panelClass="dropdown-panel">
                    <div appSimplebarScroller>
                        <mat-option *ngFor="let zone of getZones()" [value]="zone">
                            {{zone.name}}
                        </mat-option>
                    </div>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="settings-row">
            <mat-form-field appearance="fill" color="primary" class="input-select"
                            [class.input-select__opened]="seatviewOpened"
                            [class.input-select__disabled]="form.controls.seatview.disabled"
            >
                <mat-label>Зоны видимости*</mat-label>
                <mat-icon svgIcon="dropdown-arrow" matSuffix></mat-icon>
                <mat-select (openedChange)="seatviewOpened = $event" formControlName="seatview" disableRipple
                            disableOptionCentering
                            panelClass="dropdown-panel">
                    <div appSimplebarScroller>
                        <mat-option *ngFor="let seatview of getSeatviews()" [value]="seatview">
                            {{seatview.name}}
                        </mat-option>
                    </div>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="settings-row settings-subtitle">Логотип</div>
        <div class="settings-row">
            <app-add-image (click)="selectImage()" *ngIf="!form.controls.logoLink.value"></app-add-image>
            <div class="logo-link" *ngIf="form.controls.logoLink.value">
                <img src="{{form.controls.logoLink.value}}">
                <div class="logo-link-actions">
                    <button
                            mat-icon-button
                            disableRipple
                            type="button"
                            class="button-icon"
                            color="primary"
                            (click)="downloadResourceObject()"
                    >
                        <mat-icon svgIcon="download"></mat-icon>
                    </button>
                    <button
                            mat-icon-button
                            disableRipple
                            type="button"
                            class="button-icon"
                            color="primary"
                            (click)="removeLink()"
                    >
                        <mat-icon svgIcon="basket"></mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="actions">
        <button
                mat-raised-button
                disableRipple
                type="button"
                class="button-primary-filled"
                color="success"
                [disabled]="form.invalid || waiting"
                (click)="save()"
        >
            Сохранить
        </button>
    </div>
</div>

<div class="page-container" *ngIf="loading">
    <div class="event-info">
        <div class="info-col">
            <div class="col-title body-text-3">
                <skeleton-text [width]="100" [height]="20"></skeleton-text>
            </div>
            <div class="col-value body-text-3">
                <skeleton-text [width]="120" [height]="20"></skeleton-text>
            </div>
        </div>
        <div class="info-col">
            <div class="col-title body-text-3">
                <skeleton-text [width]="50" [height]="20"></skeleton-text>
            </div>
            <div class="col-value body-text-3">
                <skeleton-text [width]="120" [height]="20"></skeleton-text>
            </div>
        </div>
    </div>

    <div class="settings">
        <div class="settings-row settings-subtitle">
            <skeleton-text [inverseColor]="true" [width]="100" [height]="19"></skeleton-text>
        </div>
        <div class="settings-row">
            <skeleton-text [inverseColor]="true" [height]="44"></skeleton-text>
        </div>
        <div class="settings-row settings-row-2">
            <skeleton-text [inverseColor]="true" [height]="44"></skeleton-text>
            <skeleton-text [inverseColor]="true" [height]="44"></skeleton-text>
        </div>
        <div class="settings-row">
            <skeleton-text [inverseColor]="true" [height]="44"></skeleton-text>
        </div>
        <div class="settings-row">
            <skeleton-text [inverseColor]="true" [height]="44"></skeleton-text>
        </div>
        <div class="settings-row settings-row-2">
            <skeleton-text [inverseColor]="true" [height]="44"></skeleton-text>
            <skeleton-text [inverseColor]="true" [height]="44"></skeleton-text>
        </div>
        <div class="settings-row settings-subtitle">
            <skeleton-text [inverseColor]="true" [width]="100" [height]="19"></skeleton-text>
        </div>
        <div class="settings-row">
            <skeleton-text [inverseColor]="true" [height]="44"></skeleton-text>
        </div>
        <div class="settings-row">
            <skeleton-text [inverseColor]="true" [height]="44"></skeleton-text>
        </div>
        <div class="settings-row">
            <skeleton-text [inverseColor]="true" [height]="44"></skeleton-text>
        </div>
        <div class="settings-row settings-subtitle">
            <skeleton-text [inverseColor]="true" [width]="100" [height]="19"></skeleton-text>
        </div>
        <div class="settings-row">
            <skeleton-text [inverseColor]="true" [width]="100" [height]="100" style="width: 100px !important;" [radius]="5"></skeleton-text>
        </div>
    </div>

    <div class="error-container" *ngIf="error">
        <inline-alert-component [text]="error"></inline-alert-component>
    </div>

    <div class="actions">
        <skeleton-text [inverseColor]="true" [width]="115" [height]="36"></skeleton-text>
    </div>
</div>
