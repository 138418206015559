import {IconsRegistry} from "./icon/icons.registry";
import {NgModule} from "@angular/core";

@NgModule({
    providers: []
})
export class UiKitModule {
    constructor(
        private readonly iconsRegistry: IconsRegistry
    ) {
        this.iconsRegistry.init();
    }
}
