import {Component, OnDestroy} from '@angular/core';
import {ImagePickerPopupService} from '../../image-picker-popup/image-picker-popup.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {FullscreenLoaderService} from '../../../../shared/ui-kit/fullscreen-loader/fullscreen-loader.service';
import {EventSessionSettingsService, SettingsLoadingStatus} from '../event-session-settings.service';
import {Subscription} from 'rxjs';
import {formatNativeDate} from '../../../shared/utils/date-utils';
import {parse} from 'date-fns';

interface Advertisement {
    datetimeBegin: string;
    datetimeEnd: string;
    enableSubscribe: boolean;
    logoLink: string;
    text: string;
}

@Component({
    selector: 'app-announcement',
    templateUrl: './announcement.component.html',
    styleUrls: ['./announcement.component.scss']
})
export class AnnouncementComponent implements OnDestroy {

    public form: FormGroup;
    private eventSessionLoadingSubscription: Subscription;

    public loading = true;

    public error: string | null = null;

    constructor(
        private readonly fb: FormBuilder,
        private readonly httpClient: HttpClient,
        private readonly fullscreenLoaderService: FullscreenLoaderService,
        private readonly imagePickerPopupService: ImagePickerPopupService,
        private readonly eventSessionSettingsService: EventSessionSettingsService
    ) {
        this.form = this.fb.group({
            start: [null, [Validators.required]],
            end: [null, [Validators.required]],
            enableSubscribe: [false],
            text: [null, [Validators.maxLength(250)]],
            logoLink: [null]
        });

        this.eventSessionLoadingSubscription = this.eventSessionSettingsService.loading.subscribe(async (status) => {
            if (status === SettingsLoadingStatus.LOADED) {
                const eventSession = this.eventSessionSettingsService.getEventSession();
                if (eventSession === null) {
                    return;
                }
                const eventUuid = eventSession.uuid;
                await this.loadAdvertisement(eventUuid);
                this.loading = false;
            }
        });
    }

    ngOnDestroy(): void {
        this.eventSessionLoadingSubscription.unsubscribe();
    }

    private async loadAdvertisement(eventSessionUuid: string): Promise<void> {
        try {
            const advertisement = await this.httpClient
                .get<Advertisement>(`/api/event-session-advertisement/one/by-event-session/${eventSessionUuid}`).toPromise();
            this.form.patchValue({
                start: parse(advertisement.datetimeBegin, `yyyy-MM-dd'T'HH:mm:ss`, new Date()).getTime(),
                end: parse(advertisement.datetimeEnd, `yyyy-MM-dd'T'HH:mm:ss`, new Date()).getTime(),
                enableSubscribe: advertisement.enableSubscribe,
                text: advertisement.text,
                logoLink: advertisement.logoLink
            });
        } catch (e) {

        }
    }

    public async selectImage(): Promise<void> {
        try {
            const resourceObject = await this.imagePickerPopupService.open();
            this.form.patchValue({
                logoLink: resourceObject.url
            });
        } catch (e) {

        }
    }

    public onRangeChange({start, end}: { start: Date | null, end: Date | null }): void {
        this.form.patchValue({
            start, end
        });
    }

    public async save(): Promise<void> {
        this.error = null;
        this.fullscreenLoaderService.open();
        try {
            const model = {
                datetimeBegin: formatNativeDate(this.form.value.start, `yyyy-MM-dd'T'HH:mm:ss`),
                datetimeEnd: formatNativeDate(this.form.value.end, `yyyy-MM-dd'T'HH:mm:ss`),
                enableSubscribe: this.form.value.enableSubscribe,
                eventSessionUuid: this.eventSessionSettingsService.getEventSessionUuid(),
                logoLink: this.form.value.logoLink,
                text: this.form.value.text
            };
            await this.httpClient.put(`/api/event-session-advertisement/save`, model).toPromise();
        } catch (e) {
            this.error = e.error.message;
        }
        this.fullscreenLoaderService.close();
    }

    public async remove(): Promise<void> {
        this.error = null;
        this.fullscreenLoaderService.open();
        try {
            const eventSessionUuid = this.eventSessionSettingsService.getEventSessionUuid();
            await this.httpClient
                .delete(`/api/event-session-advertisement/delete/by-event-session/${eventSessionUuid}`).toPromise();
            this.form.reset();
        } catch (e) {
            this.error = e.error.message;
        }
        this.fullscreenLoaderService.close();
    }


    public removeLink(): void {
        this.form.patchValue({
            logoLink: null
        });
    }

    public downloadResourceObject(): void {
        const link = document.createElement('a');
        link.target = '_blank';
        link.href = this.form.controls.logoLink.value;
        document.body.appendChild(link);
        link.click();
        link.remove();
    }
}
