import {
    ApplicationRef,
    ComponentFactoryResolver,
    ComponentRef,
    EmbeddedViewRef,
    Inject,
    Injectable,
    Injector
} from "@angular/core";
import {DOCUMENT} from "@angular/common";
import {FullscreenLoaderComponent} from "./fullscreen-loader.component";

@Injectable({
    providedIn: 'root'
})
export class FullscreenLoaderService {

    private componentRef: ComponentRef<FullscreenLoaderComponent> | null = null;

    constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly componentFactoryResolver: ComponentFactoryResolver,
        private readonly applicationRef: ApplicationRef,
        private readonly injector: Injector
    ) {

    }

    public open(): void {
        if (this.componentRef !== null) {
            return;
        }
        this.componentRef = this.componentFactoryResolver.resolveComponentFactory(FullscreenLoaderComponent)
            .create(this.injector);

        this.applicationRef.attachView(this.componentRef.hostView);

        const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>)
            .rootNodes[0] as HTMLElement;

        this.document.body.appendChild(domElem);
    }

    public close(): void {
        if (this.componentRef === null) {
            return;
        }
        this.applicationRef.detachView(this.componentRef.hostView);
        this.componentRef.destroy();
        this.componentRef = null;
    }
}
