import {NgModule} from "@angular/core";
import {FullscreenLoaderComponent} from "./fullscreen-loader.component";
import {MatIconModule} from "@angular/material/icon";

@NgModule({
    declarations: [FullscreenLoaderComponent],
    imports: [
        MatIconModule
    ],
    entryComponents: [FullscreenLoaderComponent]
})
export class FullscreenLoaderModule {

}
