<div class="popup-wrapper" *ngIf="!loading">
    <div class="popup-header">
        <div class="body-text-1">Копировать расценки из события</div>
        <button
                mat-icon-button
                disableRipple
                type="button"
                class="button-icon"
                color="primary"
                (click)="close(false)"
        >
            <mat-icon svgIcon="times"></mat-icon>
        </button>
    </div>
    <div class="popup-body">
        <div class="filters-row">
            <mat-form-field floatLabel="never" color="primary" class="input-search"
                            [class.input-search_not-empty]="search.value">
                <mat-label>Поиск</mat-label>
                <mat-icon matPrefix svgIcon="search"></mat-icon>
                <input matInput autocomplete="off" type="text" [formControl]="search">

                <button
                        mat-icon-button
                        disableRipple
                        type="button"
                        class="button-icon"
                        *ngIf="search.value"
                        color="primary"
                        matSuffix
                        (click)="search.setValue('')"
                >
                    <mat-icon svgIcon="times-filled"></mat-icon>
                </button>
            </mat-form-field>
        </div>
        <div class="filters-row">
            <div class="filter">
                <filter-component [dataSource]="seasonsFilter" [confirmationRequired]="true"
                                  (onSelectionChange)="onSeasonsChange($event)">
                    <span filter-placeholder>Сезон</span>
                    <span filter-title>Сезон</span>
                </filter-component>
            </div>
            <div class="filter">
                <filter-component [dataSource]="eventSessionTypeFilter" [confirmationRequired]="true"
                                  (onSelectionChange)="onEventSessionTypeChange($event)">
                    <span filter-placeholder>Турнир/тип</span>
                    <span filter-title>Турнир/тип</span>
                </filter-component>
            </div>
            <div class="filter">
                <button
                        mat-button
                        disableRipple
                        type="button"
                        class="button-primary-basic-text"
                        color="primary"
                        [disabled]="eventSessionFilter.seasons?.length === 0 && eventSessionFilter.eventSessionTypes?.length === 0"
                        (click)="resetFilters()"
                >
                    Сбросить
                </button>
            </div>
        </div>
        <div class="table-container">
            <mat-table [dataSource]="tableData" *ngIf="!tableLoading" appSimplebarScroller [scrollWithMouse]="true">
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>
                        <div class="mat-cell-content">
                            <sort label="Название" [state]="getSortDirection('eventName')"
                                  (onChange)="onSortChange('eventName', $event)"></sort>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="mat-cell-content">
                            <div class="body-text-3">{{element.eventName}}</div>
                        </div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="season">
                    <mat-header-cell *matHeaderCellDef>
                        <div class="mat-cell-content">
                            <sort label="Сезон" [state]="getSortDirection('seasonName')"
                                  (onChange)="onSortChange('seasonName', $event)"></sort>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="mat-cell-content">
                            <div class="body-text-3" *ngIf="element.seasonName">{{element.seasonName}}</div>
                            <div class="body-text-3" *ngIf="!element.seasonName">-</div>
                        </div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="empty-scrollbar-row">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let element"></mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

                <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="rowClick(row)"></mat-row>

                <mat-footer-row class="empty-scrollbar-row"
                                *matFooterRowDef="['empty-scrollbar-row'];"></mat-footer-row>
            </mat-table>

            <mat-table [dataSource]="[1,2,3,4]" *ngIf="tableLoading" appSimplebarScroller>
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>
                        <div class="mat-cell-content">
                            <skeleton-text [width]="100" [height]="12"></skeleton-text>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="mat-cell-content">
                            <skeleton-text [width]="120" [height]="14"></skeleton-text>
                        </div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="season">
                    <mat-header-cell *matHeaderCellDef>
                        <div class="mat-cell-content">
                            <skeleton-text [width]="100" [height]="12"></skeleton-text>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="mat-cell-content">
                            <skeleton-text [width]="120" [height]="14"></skeleton-text>
                        </div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="empty-scrollbar-row">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let element"></mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

                <mat-footer-row class="empty-scrollbar-row"
                                *matFooterRowDef="['empty-scrollbar-row'];"></mat-footer-row>
            </mat-table>
        </div>
    </div>
    <div class="error-container" *ngIf="error">
        <inline-alert-component [text]="error"></inline-alert-component>
    </div>
</div>

<div class="popup-wrapper" *ngIf="loading">
    <div class="popup-header">
        <div class="body-text-1">
            <skeleton-text [width]="291" [height]="18" [inverseColor]="true"></skeleton-text>
        </div>
    </div>
    <div class="popup-body">
        <div class="filters-row">
            <skeleton-text [width]="586" [height]="40"></skeleton-text>
        </div>
        <div class="filters-row">
            <div class="filter">
                <skeleton-text [width]="89" [height]="32" [radius]="20"></skeleton-text>
            </div>
            <div class="filter">
                <skeleton-text [width]="125" [height]="32" [radius]="20"></skeleton-text>
            </div>
            <div class="filter">
                <skeleton-text [width]="85" [height]="14"></skeleton-text>
            </div>
        </div>
        <div class="table-container">
            <mat-table [dataSource]="[1,2,3,4]" appSimplebarScroller>
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>
                        <div class="mat-cell-content">
                            <skeleton-text [width]="100" [height]="12"></skeleton-text>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="mat-cell-content">
                            <skeleton-text [width]="120" [height]="14"></skeleton-text>
                        </div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="season">
                    <mat-header-cell *matHeaderCellDef>
                        <div class="mat-cell-content">
                            <skeleton-text [width]="100" [height]="12"></skeleton-text>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="mat-cell-content">
                            <skeleton-text [width]="120" [height]="14"></skeleton-text>
                        </div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="empty-scrollbar-row">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let element"></mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

                <mat-footer-row class="empty-scrollbar-row"
                                *matFooterRowDef="['empty-scrollbar-row'];"></mat-footer-row>
            </mat-table>
        </div>
    </div>
    <div class="error-container" *ngIf="error">
        <inline-alert-component [text]="error"></inline-alert-component>
    </div>
</div>
