import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-default-error-popup',
    templateUrl: './default-error-popup.component.html',
    styleUrls: ['./default-error-popup.component.scss']
})
export class DefaultErrorPopupComponent {

    constructor(
        private readonly dialogRef: MatDialogRef<DefaultErrorPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly message: string
    ) {
    }

    public async close(): Promise<void> {
        this.dialogRef.close();
    }
}
