import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {PagesResolver} from './pages/pages.resolver';

const routes: Routes = [
    {path: 'auth', loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule)},
    {path: 'pages', resolve: {user: PagesResolver}, loadChildren: () => import('./pages/pages.module').then((m) => m.PagesModule)},
    {path: '', redirectTo: 'pages', pathMatch: 'full'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
