import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {SnackBarComponent} from "./snack-bar.component";
import {MatIconModule} from "@angular/material/icon";

@NgModule({
    imports: [
        CommonModule,
        MatIconModule
    ],
    declarations: [
        SnackBarComponent
    ],
    exports: [
        SnackBarComponent
    ],
    entryComponents: [SnackBarComponent]
})
export class SnackBarModule {
}
