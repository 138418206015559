<div class="popup-wrapper" *ngIf="!loading">
    <div class="popup-header">
        <div class="body-text-1" *ngIf="!tournament">Создать турнир</div>
        <div class="body-text-1" *ngIf="tournament">Редактировать турнир</div>
        <button
                mat-icon-button
                disableRipple
                type="button"
                class="button-icon"
                color="primary"
                (click)="close(popupResult.CANCEL)"
        >
            <mat-icon svgIcon="times"></mat-icon>
        </button>
    </div>

    <div class="order-info">

        <div class="filters-lang">
                <mat-tab-group [selectedIndex]="lang.value" (selectedIndexChange)="onReferenceBookChange($event)" disableRipple
                               animationDuration="0ms">
                   <mat-tab>
                        <ng-template mat-tab-label>
                            {{getLangName(0).toUpperCase()}}<a style="color:red">*</a>
                        </ng-template>
                    </mat-tab>
    
                    <mat-tab>
                        <ng-template mat-tab-label>
                            {{getLangName(1).toUpperCase()}}
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>
            </div>
    </div>  

    <div *ngIf="tournament" class="season-caption">
        Сезон 
    </div> 
    <div *ngIf="tournament" class="season-name">{{getSeasonName(findSeasonByUuid(tournament.seasonUuid))}}</div>
    <div class="popup-body" [formGroup]="form">

        <div *ngIf="!tournament" formGroupName="main" class="popup-body-row">
            <mat-form-field appearance="fill" color="primary" class="input-select"
                                    [class.input-select__opened]="seasonOpened">
                        <mat-label>Сезон</mat-label>
                        <mat-icon svgIcon="dropdown-arrow" matSuffix></mat-icon>
                        <mat-select #seasonSelect (openedChange)="seasonOpened = $event" disableRipple
                                    formControlName="seasonUuid" disableOptionCentering
                                    [panelClass]="['dropdown-panel', 'dropdown-panel-advanced']"
                                    
                                    >
                            <div appSimplebarScroller>
                                <mat-option *ngFor="let seasonA of seasons" [value]="seasonA.uuid">
                                    {{seasonA.name[getLangName(0)] ? seasonA.name[getLangName(0)] : '-'}}
                                </mat-option>
                            </div>
                            <div class="create-container" *ngIf="seasonOpened">
                                <app-base-catalog
                                        [placeholder]="'Добавить новый сезон'"
                                        [component]="seasonCatalogComponent"
                                        (create)="onSeasonCreate($event); seasonSelect.close()"></app-base-catalog>
                            </div>
                        </mat-select>
                    </mat-form-field>
        </div> 

        <div  class="popup-body-row popup-body-row-with-hint">
            <div *ngFor="let exp of form.controls | keyvalue"> 
                <div *ngIf="exp.key === getLangName(lang.value)" [formGroupName]="exp.key">
                        <div class="popup-body-row popup-body-row-with-hint">
                            <mat-form-field class="input-field" color="primary">
                                <mat-label>Название<a style="color:red">*</a></mat-label>
                                <input type="text" autocomplete="off" matInput formControlName="caption">
                            </mat-form-field>
                        </div>
                </div>
            </div>
        </div>

        <!-- <div class="popup-body-row popup-body-row-with-hint" formGroupName="main">
            <mat-form-field class="input-field" color="primary">
                <mat-label>Логотип, URL</mat-label>
                <input type="text" autocomplete="off" matInput formControlName="logoLink">
            </mat-form-field>
        </div> -->

        <div>   
            <div *ngIf="form.controls.main.value.logoLink" class="order-info-caption">Логотип</div> 
            <div class="add-image" (click)="addImage('main','logoLink')" *ngIf="!form.controls.main.value.logoLink">
                <mat-icon svgIcon="plus"></mat-icon>
                <span class="body-text-3">Добавить логотип</span>
            </div>
            <div class="logo-link" *ngIf="form.controls.main.value.logoLink">
                <img style="max-width: 700px; max-height: 150px;" (click)="addImage('main','logoLink')" src="{{form.controls.main.value.logoLink}}">
                <div class="logo-link-actions">
                    <button
                            mat-icon-button
                            disableRipple
                            type="button"
                            class="button-icon"
                            color="primary"
                            (click)="downloadResourceObject('main','logoLink')"
                    >
                        <mat-icon svgIcon="download"></mat-icon>
                    </button>
                    <button
                            mat-icon-button
                            disableRipple
                            type="button"
                            class="button-icon"
                            color="primary"
                            (click)="removeLink('main','logoLink')"
                    >
                        <mat-icon svgIcon="basket"></mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>


    <div class="error-container" *ngIf="error">
        <inline-alert-component [text]="error"></inline-alert-component>
    </div>


    <div class="popup-footer">
        <button
                mat-raised-button
                disableRipple
                type="button"
                class="button-primary-filled button-primary-filled-secondary"
                color="primary"
                [disabled]="form.invalid || waiting || !form.value.ru.caption"
                (click)="save()"
        >
            Сохранить
        </button>
    </div>
</div>


<div class="popup-wrapper" *ngIf="loading">
    <div class="popup-header">
        <div class="body-text-1">
            <skeleton-text [inverseColor]="true" [width]="281" [height]="18"></skeleton-text>
        </div>
    </div>
    <div class="popup-body">
        <div class="popup-body-row">
            <skeleton-text [height]="44"></skeleton-text>
        </div>
        <div class="popup-body-row popup-body-row-with-hint">
            <skeleton-text [height]="44"></skeleton-text>
            <skeleton-text [width]="192" [height]="14"></skeleton-text>
        </div>
    </div>
    <div class="popup-footer">
        <skeleton-text [height]="36"></skeleton-text>
    </div>
</div>
