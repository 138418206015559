import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-new-catalog-entity',
    templateUrl: './new-catalog-entity.component.html',
    styleUrls: ['./new-catalog-entity.component.scss']
})
export class NewCatalogEntityComponent {

    @Input()
    placeholder = '';

    constructor() {
    }

}
