import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder} from '@angular/forms';
import {HttpClient, HttpHeaders } from '@angular/common/http';
import { ConnectedPositionStrategy } from '@angular/cdk/overlay';

@Component({
    selector: 'app-payment-return-popup',
    templateUrl: './payment-return-popup.component.html',
    styleUrls: ['./payment-return-popup.component.scss']
})
export class PaymentReturnPopupComponent {

    public waiting = false;
    public error: string | null = null;

    constructor(
        private readonly dialogRef: MatDialogRef<PaymentReturnPopupComponent>,
        private readonly fb: FormBuilder,
        private readonly httpClient: HttpClient,
        @Inject(MAT_DIALOG_DATA) public readonly data: any
    ) {
        
    }

    public close(): void {
        this.dialogRef.close();
    }

    public async return(): Promise<void> {
        this.waiting = true;
        this.error = null;
        try {
            const httpOptions = {
                headers: new HttpHeaders({ 'Content-Type': 'application/json' })
            };
            await this.httpClient.delete(`api/order/payment/${this.data.paymentsUUIDs[0]}`, httpOptions).toPromise();
            this.close();
        } catch (e) {
            this.error = e.error.detail;
        }
        this.waiting = false;
    }

}
