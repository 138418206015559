import {Component, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
    EventSessionSettingsService,
    SettingsLoadingStatus,
    SettingsPopupState
} from '../event-session-settings.service';
import {Subscription} from 'rxjs';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {FullscreenLoaderService} from '../../../../shared/ui-kit/fullscreen-loader/fullscreen-loader.service';
import {MatDialog} from '@angular/material/dialog';
import {
    CopyEventSessionPricesFromPopupComponent
} from '../../copy-event-session-prices-from-popup/copy-event-session-prices-from-popup.component';
import {
    CopyEventSessionPricesToPopupComponent
} from '../../copy-event-session-prices-to-popup/copy-event-session-prices-to-popup.component';
import {
    CopySubscriptionSessionPricesToPopupComponent
} from '../../copy-subscription-session-prices-to-popup/copy-subscription-session-prices-to-popup.component';
import {
    CopySubscriptionSessionPricesFromPopupComponent
} from '../../copy-subscription-session-prices-from-popup/copy-subscription-session-prices-from-popup.component';
import {FilterDataOption, FilterDataSource} from '../../../../shared/ui-kit/filter/filter.component';
import {Customer} from '../../../pages/orders/types';
import {GroupedPriceRatePopupComponent} from './grouped-price-rate-popup/grouped-price-rate-popup.component';

interface Category {
    caption: string;
    name: string;
    uuid: string;
}

interface Zone {
    color: string;
    locationName: string;
    locationUuid: string;
    name: string;
    uuid: string;
}

interface Price {
    basePrice: number;
    categoryName: string;
    categoryUuid: string;
    eventSessionUuid: string;
    customerName: string;
    customerUuid: string;
    customerColor: string;
    customerOrder: number;
    tax: number | null;
    uuid: string;
    zoneColor: string;
    zoneName: string;
    zoneUuid: string;
    zoneOrder: number;
    selected: boolean;
}

interface PricesTable {
    zones: PriceTableEntity[];
}

interface PriceTableEntity {
    zoneName: string;
    zoneOrder: number;
    prices: Price[][];
}

@Component({
    selector: 'app-price-rates',
    templateUrl: './price-rates.component.html',
    styleUrls: ['./price-rates.component.scss']
})
export class PriceRatesComponent implements OnDestroy {

    categoryOpened = false;
    categoryEditOpened = false;
    zoneOpened = false;

    private categories: Category[] = [];
    private zones: Zone[] = [];
    public prices: Price[] = [];
    public pricesTable: PricesTable = {zones: []};
    public selectedPrices: Price[] = [];

    private readonly eventSessionLoadingSubscription: Subscription;

    public readonly form: FormGroup;
    public readonly editForm: FormGroup;

    public error: string | null = null;

    public loading = true;

    public editingPrice: Price | null = null;
    public editingPrices: Price[] = [];

    public customersDataSource: FilterDataSource = new FilterDataSource([]);

    constructor(
        private readonly httpClient: HttpClient,
        private readonly eventSessionSettingsService: EventSessionSettingsService,
        private readonly fb: FormBuilder,
        private readonly fullscreenLoaderService: FullscreenLoaderService,
        private readonly matDialog: MatDialog
    ) {
        this.eventSessionLoadingSubscription = this.eventSessionSettingsService.loading.subscribe(async (status) => {
            if (status === SettingsLoadingStatus.LOADED) {
                const eventLocationUuid = this.eventSessionSettingsService.getEventSession()?.locationUuid as string;
                const eventSessionUuid = this.eventSessionSettingsService.getEventSessionUuid() as string;
                await Promise.all([
                    this.loadCategories(),
                    this.loadZones(eventLocationUuid),
                    this.loadPrices(eventSessionUuid),
                    this.loadCustomers()
                ]);
                this.loading = false;
            }
        });

        const priceValidators = [Validators.required, (control: AbstractControl) => {
            if (!/^\d+$/.test(control.value)) {
                return {
                    invalid: true
                };
            }
            return null;
        }];

        const vatValidators = [(control: AbstractControl) => {
            const value = control.value;
            if (value === null || value.trim() === '') {
                return null;
            }
            if (!/^\d+$/.test(value)) {
                return {
                    invalid: true
                };
            }
            const num = +value;
            if (num < 0 || num > 100) {
                return {
                    invalid: true
                };
            }
            return null;
        }];

        const customersValidator = [Validators.required, (control: AbstractControl) => {
            if (control.value.length === 0) {
                return {
                    invalid: true
                };
            }
            return null;
        }];

        this.form = this.fb.group({
            category: [null, [Validators.required]],
            zone: [null, [Validators.required]],
            price: [null, priceValidators],
            vat: [null, vatValidators],
            customerUuids: [[], customersValidator]
        });

        this.editForm = this.fb.group({
            category: [null, [Validators.required]],
            price: [null, priceValidators],
            vat: [null, vatValidators]
        });
    }

    ngOnDestroy(): void {
        this.eventSessionLoadingSubscription.unsubscribe();
    }

    private async loadCategories(): Promise<void> {
        try {
            this.categories = await this.httpClient.get<Category[]>(`/api/category/all`).toPromise();
        } catch (e) {

        }
    }

    private async loadZones(locationUuid: string): Promise<void> {
        try {
            this.zones = await this.httpClient.get<Zone[]>(`/api/zone/all`, {
                params: {locationUuids: locationUuid}
            }).toPromise();
        } catch (e) {

        }
    }

    private async loadCustomers(): Promise<void> {
        try {
            const customers = await this.httpClient.get<Customer[]>(`/api/customer/all`).toPromise();
            customers.sort((a, b) => a.order - b.order);
            this.customersDataSource = new FilterDataSource(customers, ([] as FilterDataOption[]).concat(customers));
            this.form.patchValue({
                customerUuids: customers.map(c => c.uuid)
            });
        } catch (e) {

        }
    }

    public getCategories(): Category[] {
        return this.categories;
    }

    public getZones(): Zone[] {
        return this.zones;
    }

    public resetForm(): void {
        this.form.patchValue({
            category: null,
            zone: null,
            price: null,
            vat: null
        });
        this.form.markAsUntouched();
        this.error = null;
    }

    private async loadPrices(eventSessionUuid: string): Promise<void> {
        try {
            this.prices = await this.httpClient
                .get<Price[]>(`/api/price/all/by-event-session/${eventSessionUuid}`).toPromise();
            this.createPricesTable();
        } catch (e) {

        }
    }

    public async addPrice(): Promise<void> {
        this.error = null;
        this.fullscreenLoaderService.open();
        try {
            const tax = this.form.value.vat;
            const categoryUuid = this.form.value.category.uuid;
            const zoneUuid = this.form.value.zone.uuid;
            const basePrice = +this.form.value.price;

            const prices: Price[] = await Promise.all(this.form.value.customerUuids.map((customerUuid: string) => {
                const model = {
                    categoryUuid,
                    zoneUuid,
                    eventSessionUuid: this.eventSessionSettingsService.getEventSessionUuid() as string,
                    basePrice,
                    tax: tax === null || tax === '' ? null : +tax,
                    customerUuid
                };
                return this.httpClient
                    .post<Price>(`/api/price/create`, model).toPromise();
            }));

            this.prices = [...this.prices, ...prices];

            this.createPricesTable();

            this.resetForm();
        } catch (e) {
            this.error = e.error.message;
        }
        this.fullscreenLoaderService.close();
    }

    public togglePriceRateSelection(prices: Price[]): void {
        prices.forEach(price => {
            if (price.selected) {
                const index = this.selectedPrices.indexOf(price);
                if (index > -1) {
                    this.selectedPrices.splice(index, 1);
                }
            } else {
                this.selectedPrices.push(price);
            }
            price.selected = !price.selected;
        });
    }

    public onSelectAllChange(matCheckboxChange: MatCheckboxChange): void {
        if (matCheckboxChange.checked) {
            this.selectedPrices = [...this.prices];
        } else {
            this.selectedPrices = [];
        }

        this.prices.forEach(price => {
            price.selected = matCheckboxChange.checked;
        });
    }

    public async removeSelectedPrices(): Promise<void> {
        this.error = null;
        this.fullscreenLoaderService.open();
        const selectedPriceUuids = this.selectedPrices.map(price => price.uuid);
        try {
            await this.httpClient
                .delete(`/api/price/delete/${selectedPriceUuids.join(',')}`).toPromise();

            this.prices = this.prices.filter(price => !price.selected);
            this.selectedPrices = [];

            this.createPricesTable();
        } catch (e) {
            this.error = e.error.message;
        }
        this.fullscreenLoaderService.close();
    }

    public async removePrice(prices: Price[]): Promise<void> {
        this.error = null;
        this.fullscreenLoaderService.open();
        try {
            await Promise.all(prices.map(price => {
                return this.httpClient
                    .delete(`/api/price/delete/${price.uuid}`).toPromise();
            }));

            await this.reloadPrices();
        } catch (e) {
            this.error = e.error.message;
        }
        this.fullscreenLoaderService.close();
    }

    private createPricesTable(): void {
        const pricesMap = this.prices
            .reduce((accumulator: { [key: string]: { zoneName: string, zoneOrder: number, prices: Price[] } }, price) => {
                if (typeof accumulator[price.zoneUuid] === 'undefined') {
                    accumulator[price.zoneUuid] = {
                        zoneName: price.zoneName,
                        zoneOrder: price.zoneOrder,
                        prices: []
                    };
                }

                accumulator[price.zoneUuid].prices.push(price);
                return accumulator;
            }, {});

        this.pricesTable = {
            zones: []
        };

        Object.entries(pricesMap)
            .forEach(([key, entity]) => {
                const grouped = entity.prices.reduce((accumulator: Record<string, Price[]>, p) => {
                    const hash = `${p.categoryUuid}:${p.basePrice}:${p.tax}`;
                    if (!accumulator[hash]) {
                        accumulator[hash] = [];
                    }
                    accumulator[hash].push(p);
                    return accumulator;
                }, {});

                const e: PriceTableEntity = {
                    zoneName: entity.zoneName,
                    zoneOrder: entity.zoneOrder,
                    prices: []
                };

                this.pricesTable.zones.push(e);

                Object.values(grouped)
                    .forEach(arr => {
                        e.prices.push(arr);
                    });
            });

        this.pricesTable.zones.sort((a, b) => a.zoneOrder - b.zoneOrder);
    }

    public editPrice(prices: Price[]): void {
        this.editingPrices = prices;
        this.editingPrice = prices[0];
        this.editForm.patchValue({
            price: prices[0].basePrice.toString(),
            vat: prices[0].tax?.toString() || null,
            category: this.categories.find(category => category.uuid === prices[0].categoryUuid)
        });
    }

    public async updatePrice(): Promise<void> {
        this.fullscreenLoaderService.open();
        this.error = null;
        try {
            const tax = this.editForm.value.vat;
            const basePrice = +this.editForm.value.price;
            const categoryUuid = this.editForm.value.category.uuid;

            await Promise.all(this.editingPrices.map(p => {
                const model = {
                    basePrice,
                    categoryUuid,
                    eventSessionUuid: this.eventSessionSettingsService.getEventSessionUuid() as string,
                    tax: tax === null || tax === '' ? null : +tax,
                    uuid: p.uuid,
                    zoneUuid: p.zoneUuid,
                    customerUuid: p.customerUuid
                };
                return this.httpClient.put(`/api/price/update`, model).toPromise();
            }));

            await this.reloadPrices();

            this.editingPrice = null;
            this.editingPrices = [];
        } catch (e) {
            this.error = e.error.message;
        }
        this.fullscreenLoaderService.close();
    }

    public next(): void {
        this.eventSessionSettingsService.setCurrentState(SettingsPopupState.OPEN_SALES);
    }

    public copyFrom(): void {
        if (this.eventSessionSettingsService.getEventSession()?.consistType === 'TICKET') {
            this.matDialog.open(CopyEventSessionPricesFromPopupComponent, {
                panelClass: ['primary-popup-panel', 'default-popup-panel'],
                backdropClass: 'primary-popup-backdrop',
                data: {
                    locationUuid: this.eventSessionSettingsService.getEventSession()?.locationUuid,
                    eventSessionUuid: this.eventSessionSettingsService.getEventSession()?.uuid
                },
                restoreFocus: false,
                disableClose: true,
                autoFocus: false
            }).afterClosed().subscribe((reload) => {
                if (!reload) {
                    return;
                }
                this.reloadPrices();
            });
        } else {
            this.matDialog.open(CopySubscriptionSessionPricesFromPopupComponent, {
                panelClass: ['primary-popup-panel', 'default-popup-panel'],
                backdropClass: 'primary-popup-backdrop',
                data: {
                    locationUuid: this.eventSessionSettingsService.getEventSession()?.locationUuid,
                    eventSessionUuid: this.eventSessionSettingsService.getEventSession()?.uuid
                },
                restoreFocus: false,
                disableClose: true,
                autoFocus: false
            }).afterClosed().subscribe((reload) => {
                if (!reload) {
                    return;
                }
                this.reloadPrices();
            });
        }
    }

    public copyTo(): void {
        if (this.eventSessionSettingsService.getEventSession()?.consistType === 'TICKET') {
            this.matDialog.open(CopyEventSessionPricesToPopupComponent, {
                panelClass: ['primary-popup-panel', 'default-popup-panel'],
                backdropClass: 'primary-popup-backdrop',
                data: {
                    locationUuid: this.eventSessionSettingsService.getEventSession()?.locationUuid,
                    eventSessionUuid: this.eventSessionSettingsService.getEventSession()?.uuid
                },
                restoreFocus: false,
                disableClose: true,
                autoFocus: false
            });
        } else {
            this.matDialog.open(CopySubscriptionSessionPricesToPopupComponent, {
                panelClass: ['primary-popup-panel', 'default-popup-panel'],
                backdropClass: 'primary-popup-backdrop',
                data: {
                    locationUuid: this.eventSessionSettingsService.getEventSession()?.locationUuid,
                    eventSessionUuid: this.eventSessionSettingsService.getEventSession()?.uuid
                },
                restoreFocus: false,
                disableClose: true,
                autoFocus: false
            });
        }
    }

    private async reloadPrices(): Promise<void> {
        this.loading = true;
        await this.loadPrices(this.eventSessionSettingsService.getEventSession()?.uuid as string);
        this.loading = false;
    }

    public onCustomersChange(data: any[]): void {
        this.form.patchValue({
            customerUuids: data.map(c => c.uuid)
        });
    }

    public getUniqCustomers(prices: Price[]): { name: string; color: string, order: number }[] {
        const customers: Map<string, {
            name: string;
            color: string;
            order: number;
        }> = new Map<string, { name: string; color: string, order: number }>();

        prices.forEach(price => {
            customers.set(price.customerUuid, {
                name: price.customerName,
                color: price.customerColor,
                order: price.customerOrder
            });
        });
        return [...customers.values()].sort((a, b) => a.order - b.order);
    }

    public async editGroupedPriceRate(prices: Price[]): Promise<void> {
        await this.matDialog.open(GroupedPriceRatePopupComponent, {
            panelClass: ['primary-popup-panel', 'default-popup-panel'],
            backdropClass: 'primary-popup-backdrop',
            data: {
                prices: JSON.parse(JSON.stringify(prices)),
                categories: this.categories,
                eventSessionUuid: this.eventSessionSettingsService.getEventSessionUuid() as string
            },
            restoreFocus: false,
            disableClose: true,
            autoFocus: false
        }).afterClosed().toPromise();

        this.fullscreenLoaderService.open();
        await this.reloadPrices();
        this.fullscreenLoaderService.close();
    }
}
