import {Directive, ElementRef, Input, OnChanges} from '@angular/core';
import {NumberFormatterService} from './number-formatter.service';

@Directive({
    selector: '[appNumberFormatter]'
})
export class NumberFormatterDirective implements OnChanges {

    @Input() value: any = '';

    constructor(
        private readonly el: ElementRef
    ) {
    }

    ngOnChanges(): void {
        this.el.nativeElement.innerHTML = NumberFormatterService.format(this.value);
    }

}
