<div class="popup-wrapper">
    <div class="popup-header">
        <div class="body-text-1" >Смена пароля</div>
        <button
                mat-icon-button
                disableRipple
                type="button"
                class="button-icon"
                color="primary"
                (click)="close(popupResult.CANCEL)"
        >
            <mat-icon svgIcon="times"></mat-icon>
        </button>
    </div>
 

    <div class="popup-body" [formGroup]="form">

        <div  class="popup-body-row popup-body-row-with-hint">
            <div> 
                <div formGroupName="main">
                        <div class="popup-body-row popup-body-row-with-hint">
                            <mat-form-field class="input-field" color="primary">
                                <mat-label>Старый пароль<a style="color:red">*</a></mat-label>
                                <input type="password" autocomplete="off" matInput formControlName="old">
                            </mat-form-field>
                        </div>
                </div>
            </div>
        </div>
        <div  class="popup-body-row popup-body-row-with-hint">
            <div> 
                <div formGroupName="main">
                        <div class="popup-body-row popup-body-row-with-hint">
                            <mat-form-field class="input-field" color="primary">
                                <mat-label>Новый пароль<a style="color:red">*</a></mat-label>
                                <input type="password" autocomplete="off" matInput formControlName="new">
                            </mat-form-field>
                        </div>
                </div>
            </div>
        </div>
        <div  class="popup-body-row popup-body-row-with-hint">
            <div> 
                <div formGroupName="main">
                        <div class="popup-body-row popup-body-row-with-hint">
                            <mat-form-field class="input-field" color="primary">
                                <mat-label>Повторите новый пароль<a style="color:red">*</a></mat-label>
                                <input type="password" autocomplete="off" matInput formControlName="newconfirm">
                            </mat-form-field>
                        </div>
                </div>
            </div>
        </div>
    </div>


    <div class="error-container" *ngIf="error">
        <inline-alert-component [text]="error"></inline-alert-component>
    </div>


    <div class="popup-footer">
        <button
                mat-raised-button
                disableRipple
                type="button"
                class="button-primary-filled button-primary-filled-secondary"
                color="primary"
                [disabled]="form.invalid || waiting"
                (click)="save()"
        >
            Сменить
        </button>
    </div>
</div>


