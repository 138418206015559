import {Component, EventEmitter, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {Season} from '../../../shared/types/events';
import {BaseCatalog} from '../base-catalog/base-catalog';

@Component({
    selector: 'app-season-catalog',
    templateUrl: './season-catalog.component.html',
    styleUrls: ['./season-catalog.component.scss']
})
export class SeasonCatalogComponent implements BaseCatalog<Season> {

    public form: FormGroup;
    public waiting = false;
    public startDate: Date | null = null;
    public endDate: Date | null = null;

    @Output()
    create: EventEmitter<Season> = new EventEmitter<Season>();

    @Output()
    close: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private readonly fb: FormBuilder,
        private readonly httpClient: HttpClient
    ) {
        this.form = this.fb.group({
            name: [null, Validators.required]
        });
    }

    public async add(): Promise<void> {
        this.form.disable();
        this.waiting = true;
        try {
            const model = {
                name: {ru: this.form.value.name},
                startDate: this.startDate,
                endDate: this.endDate,
            };
            const season = await this.httpClient.post<Season>(`/api/season/create`, model).toPromise();
            this.create.emit(season);
            this.close.emit();
            this.form.enable();
        } catch (e) {
            this.form.enable();
            this.form.controls.name.setErrors({invalid: e.error.message});
        }
        this.waiting = false;
    }

    public onDateRangeChange({start, end}: { start: Date | null, end: Date | null }): void {
        this.startDate = start;
        this.endDate = end;
      }

}
