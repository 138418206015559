import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface ConfirmationConfig {
    title: string;
    description: string;
}

@Component({
    selector: 'app-confirmation-popup',
    templateUrl: './confirmation-popup.component.html',
    styleUrls: ['./confirmation-popup.component.scss']
})
export class ConfirmationPopupComponent {

    constructor(
        private readonly dialogRef: MatDialogRef<ConfirmationPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly config: ConfirmationConfig
    ) {
    }


    public confirm(): void {
        this.dialogRef.close(true);
    }

    public reject(): void {
        this.dialogRef.close(false);
    }
}
