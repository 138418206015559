<div class="page-container">
    <span class="body-text-2 sub-header" *ngIf="!loading">Выберите, в какие абонементы входит событие:</span>
    <span class="body-text-2 sub-header" *ngIf="loading"><skeleton-text [width]="425" [height]="18"
                                                                        [inverseColor]="true"></skeleton-text></span>
    <div class="main-cols-container main-cols-container-header">
        <div class="main-col-1">
            <div class="filters-group" *ngIf="!loading">
                <div class="filters-group-filter">
                    <filter-component [dataSource]="seasonsFilter" [confirmationRequired]="true"
                                      (onSelectionChange)="onSeasonChange($event)">
                        <span filter-placeholder>Сезон</span>
                        <span filter-title>Сезон</span>
                    </filter-component>
                </div>
                <div class="filters-group-filter">
                    <filter-component [dataSource]="eventTypeFilter" [confirmationRequired]="true"
                                      (onSelectionChange)="onEventTypeChange($event)">
                        <span filter-placeholder>Категория абонемента</span>
                        <span filter-title>Категория абонемента</span>
                    </filter-component>
                </div>
                <div class="filters-group-filter">
                    <filter-component [dataSource]="eventSessionTypeFilter" [confirmationRequired]="true"
                                      (onSelectionChange)="onEventSessionTypeChange($event)">
                        <span filter-placeholder>Турнир/Тип</span>
                        <span filter-title>Турнир/Тип</span>
                    </filter-component>
                </div>
                <div class="filters-group-filter">
                    <button
                            mat-button
                            disableRipple
                            type="button"
                            class="button-primary-basic-text"
                            color="primary"
                            [disabled]="sourceFilter.seasons?.length === 0 && sourceFilter.eventTypes?.length === 0 && sourceFilter.eventSessionTypes?.length === 0"
                            (click)="resetFilters()"
                    >
                        Сбросить
                    </button>
                </div>
            </div>
            <div class="filters-group" *ngIf="loading">
                <div class="filters-group-filter">
                    <skeleton-text [inverseColor]="true" [width]="90" [height]="32" [radius]="16"></skeleton-text>
                </div>
                <div class="filters-group-filter">
                    <skeleton-text [inverseColor]="true" [width]="205" [height]="32" [radius]="16"></skeleton-text>
                </div>
                <div class="filters-group-filter">
                    <skeleton-text [inverseColor]="true" [width]="130" [height]="32" [radius]="16"></skeleton-text>
                </div>
                <div class="filters-group-filter">
                    <skeleton-text [inverseColor]="true" [width]="100" [height]="32"></skeleton-text>
                </div>
            </div>
        </div>
        <div class="main-col-2"></div>
        <div class="main-col-3">
            <span class="body-text-2" *ngIf="!loading">Событие входит в:</span>
            <span class="body-text-2" *ngIf="loading"><skeleton-text [width]="160" [height]="18"
                                                                     [inverseColor]="true"></skeleton-text></span>
        </div>
    </div>
    <div class="main-cols-container main-cols-table-container">
        <div class="main-col-1">
            <div class="table-wrapper source-table">
                <mat-table [dataSource]="sourceTableData" appSimplebarScroller [scrollWithMouse]="true" *ngIf="!sourceTableLoading">

                    <ng-container matColumnDef="select-row" sticky>
                        <mat-header-cell *matHeaderCellDef class="mat-cell-sticky-start"></mat-header-cell>
                        <mat-cell *matCellDef="let element" class="mat-cell-sticky-start">
                            <div class="mat-cell-content">
                                <mat-checkbox
                                        disableRipple
                                        class="input-checkbox"
                                        color="primary"
                                        [checked]="element.selected"
                                        (click)="$event.preventDefault()"
                                >
                                </mat-checkbox>
                            </div>
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef>
                            <div class="mat-cell-content">
                                <sort label="Название" [state]="getSourceSortDirection('eventName')"
                                      (onChange)="onSourceSortChange('eventName', $event)"></sort>
                            </div>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <div class="mat-cell-content">
                                <div class="body-text-2">{{element.eventName}}</div>
                                <div class="body-text-3">{{element.eventCaption}}</div>
                            </div>
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>
                    <ng-container matColumnDef="season">
                        <mat-header-cell *matHeaderCellDef>
                            <div class="mat-cell-content">
                                <sort label="Сезон" [state]="getSourceSortDirection('seasonName')"
                                      (onChange)="onSourceSortChange('seasonName', $event)"></sort>
                            </div>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <div class="mat-cell-content">
                                <div class="body-text-2">{{element.seasonName}}</div>
                            </div>
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>
                    <ng-container matColumnDef="event-type">
                        <mat-header-cell *matHeaderCellDef>
                            <div class="mat-cell-content">
                                <sort label="Категория" [state]="getSourceSortDirection('eventTypeName')"
                                      (onChange)="onSourceSortChange('eventTypeName', $event)"></sort>
                            </div>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <div class="mat-cell-content">
                                <div class="body-text-3">{{element.eventTypeName}}</div>
                            </div>
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>
                    <ng-container matColumnDef="event-session-type">
                        <mat-header-cell *matHeaderCellDef>
                            <div class="mat-cell-content">
                                <sort label="Турнир/Тип" [state]="getSourceSortDirection('eventSessionTypeName')"
                                      (onChange)="onSourceSortChange('eventSessionTypeName', $event)"></sort>
                            </div>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <div class="mat-cell-content">
                                <div class="body-text-3">{{element.eventSessionTypeName}}</div>
                            </div>
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="empty-scrollbar-row">
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-cell *matCellDef="let element"></mat-cell>
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="sourceTableColumns; sticky: true"></mat-header-row>

                    <mat-row *matRowDef="let row; columns: sourceTableColumns;"
                             (click)="row.selected = !row.selected"></mat-row>

                    <mat-footer-row class="empty-scrollbar-row"
                                    *matFooterRowDef="['empty-scrollbar-row'];"></mat-footer-row>
                </mat-table>

                <mat-table [dataSource]="[1,2,3,4]" appSimplebarScroller *ngIf="sourceTableLoading">

                    <ng-container matColumnDef="select-row" sticky>
                        <mat-header-cell *matHeaderCellDef class="mat-cell-sticky-start"></mat-header-cell>
                        <mat-cell *matCellDef="let element" class="mat-cell-sticky-start">
                            <div class="mat-cell-content">
                            </div>
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef>
                            <div class="mat-cell-content">
                                <skeleton-text [width]="80" [height]="12"></skeleton-text>
                            </div>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <div class="mat-cell-content">
                                <div class="body-text-2">
                                    <skeleton-text [width]="130" [height]="24"></skeleton-text>
                                </div>
                                <div class="body-text-3">
                                    <skeleton-text [width]="100" [height]="18"></skeleton-text>
                                </div>
                            </div>
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>
                    <ng-container matColumnDef="season">
                        <mat-header-cell *matHeaderCellDef>
                            <div class="mat-cell-content">
                                <skeleton-text [width]="80" [height]="12"></skeleton-text>
                            </div>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <div class="mat-cell-content">
                                <div class="body-text-2">
                                    <skeleton-text [width]="100" [height]="18"></skeleton-text>
                                </div>
                            </div>
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>
                    <ng-container matColumnDef="event-type">
                        <mat-header-cell *matHeaderCellDef>
                            <div class="mat-cell-content">
                                <skeleton-text [width]="80" [height]="12"></skeleton-text>
                            </div>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <div class="mat-cell-content">
                                <div class="body-text-3">
                                    <skeleton-text [width]="100" [height]="18"></skeleton-text>
                                </div>
                            </div>
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>
                    <ng-container matColumnDef="event-session-type">
                        <mat-header-cell *matHeaderCellDef>
                            <div class="mat-cell-content">
                                <skeleton-text [width]="80" [height]="12"></skeleton-text>
                            </div>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <div class="mat-cell-content">
                                <div class="body-text-3">
                                    <skeleton-text [width]="100" [height]="18"></skeleton-text>
                                </div>
                            </div>
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="empty-scrollbar-row">
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-cell *matCellDef="let element"></mat-cell>
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="sourceTableColumns; sticky: true"></mat-header-row>

                    <mat-row *matRowDef="let row; columns: sourceTableColumns;"></mat-row>

                    <mat-footer-row class="empty-scrollbar-row"
                                    *matFooterRowDef="['empty-scrollbar-row'];"></mat-footer-row>
                </mat-table>

            </div>
        </div>
        <div class="main-col-2">
            <button
                    mat-icon-button
                    disableRipple
                    type="button"
                    class="button-icon-outline"
                    color="primary"
                    [disabled]="moveToDestDisabled()"
                    (click)="moveToDestTable()"
            >
                <mat-icon svgIcon="pagination-next"></mat-icon>
            </button>
            <button
                    mat-icon-button
                    disableRipple
                    type="button"
                    class="button-icon-outline"
                    color="primary"
                    [disabled]="moveToSourceDisabled()"
                    (click)="moveToSourceTable()"
            >
                <mat-icon svgIcon="pagination-prev"></mat-icon>
            </button>
        </div>
        <div class="main-col-3">
            <div class="table-wrapper dest-table">
                <mat-table [dataSource]="destTableData" appSimplebarScroller [scrollWithMouse]="true" *ngIf="!destTableLoading">

                    <ng-container matColumnDef="select-row">
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <div class="mat-cell-content">
                                <mat-checkbox
                                        disableRipple
                                        class="input-checkbox"
                                        color="primary"
                                        [checked]="element.selected"
                                        (click)="$event.preventDefault()"
                                >
                                </mat-checkbox>
                            </div>
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef>
                            <div class="mat-cell-content">
                                <sort label="Категория" [state]="getDestSortDirection('eventName')"
                                      (onChange)="onDestSortChange('eventName', $event)"></sort>
                            </div>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <div class="mat-cell-content">
                                <div class="body-text-2">{{element.eventName}}</div>
                                <div class="body-text-3">{{element.eventCaption}}</div>
                                <div class="body-text-3">{{element.seasonName}}</div>
                            </div>
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>
                    <ng-container matColumnDef="total">
                        <mat-header-cell *matHeaderCellDef>
                            <div class="mat-cell-content">
                                <span class="body-text-3">Всего: {{destTableData.length}}</span>
                            </div>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <div class="mat-cell-content">
                                <button
                                        mat-icon-button
                                        disableRipple
                                        type="button"
                                        class="button-icon"
                                        color="primary"
                                        (click)="$event.preventDefault(); $event.stopPropagation(); removeDestEvent(element)"
                                >
                                    <mat-icon svgIcon="times"></mat-icon>
                                </button>
                            </div>
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="empty-scrollbar-row">
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-cell *matCellDef="let element"></mat-cell>
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="destTableColumns; sticky: true"></mat-header-row>

                    <mat-row *matRowDef="let row; columns: destTableColumns;"
                             (click)="row.selected = !row.selected"></mat-row>

                    <mat-footer-row class="empty-scrollbar-row"
                                    *matFooterRowDef="['empty-scrollbar-row'];"></mat-footer-row>
                </mat-table>

                <mat-table [dataSource]="[1,2,3]" appSimplebarScroller *ngIf="destTableLoading">

                    <ng-container matColumnDef="select-row">
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <div class="mat-cell-content">
                            </div>
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef>
                            <div class="mat-cell-content">
                                <skeleton-text [width]="100" [height]="12"></skeleton-text>
                            </div>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <div class="mat-cell-content">
                                <div class="body-text-2">
                                    <skeleton-text [height]="24" [width]="120"></skeleton-text>
                                </div>
                                <div class="body-text-3">
                                    <skeleton-text [height]="18" [width]="100"></skeleton-text>
                                </div>
                                <div class="body-text-3">
                                    <skeleton-text [height]="18" [width]="100"></skeleton-text>
                                </div>
                            </div>
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>
                    <ng-container matColumnDef="total">
                        <mat-header-cell *matHeaderCellDef>
                            <div class="mat-cell-content">
                                <span class="body-text-3"><skeleton-text [width]="80"
                                                                         [height]="12"></skeleton-text></span>
                            </div>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <div class="mat-cell-content"></div>
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="empty-scrollbar-row">
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-cell *matCellDef="let element"></mat-cell>
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="destTableColumns; sticky: true"></mat-header-row>

                    <mat-row *matRowDef="let row; columns: destTableColumns;"></mat-row>

                    <mat-footer-row class="empty-scrollbar-row"
                                    *matFooterRowDef="['empty-scrollbar-row'];"></mat-footer-row>
                </mat-table>

            </div>
        </div>
    </div>
    <div class="actions">
        <div class="error-container">
            <inline-alert-component *ngIf="error" [text]="error"></inline-alert-component>
        </div>
        <button
                mat-raised-button
                disableRipple
                type="button"
                class="button-primary-filled"
                color="success"
                *ngIf="!loading"
                (click)="next()"
        >
            Далее
        </button>
        <skeleton-text *ngIf="loading" [width]="95" [height]="36"></skeleton-text>
    </div>
</div>
