<div class="popup-wrapper">
    <div class="popup-header">
        <div class="body-text-1">Редактировать выбранные расценки</div>
        <button
                mat-icon-button
                disableRipple
                type="button"
                class="button-icon"
                color="primary"
                (click)="close()"
        >
            <mat-icon svgIcon="times"></mat-icon>
        </button>
    </div>
    <div class="popup-body">
		<div class="error-container" *ngIf="error">
			<inline-alert-component [text]="error"></inline-alert-component>
		</div>
		<div class="price-rates-table">
			<div class="price-header">
				<div class="price-row">
					<div class="price-col price-category">Категория</div>
					<div class="price-col price-price">Цена</div>
					<div class="price-col price-tax">НДС, %</div>
					<div class="price-col price-customers">Место продажи</div>
					<div class="price-col price-actions"></div>
				</div>
			</div>
			<div class="table-body" appSimplebarScroller>
				<div class="price-group">
					<div class="price-row" *ngFor="let price of data.prices" [formGroup]="editForm"
						 [class.price-row-editing]="editingPrice?.uuid === price.uuid">
						<div class="price-col price-category body-text-3">
							<span *ngIf="price.uuid !== editingPrice?.uuid">{{price.categoryName}}</span>
							<mat-form-field *ngIf="price.uuid === editingPrice?.uuid" appearance="fill" color="primary"
											class="input-select"
											[class.input-select__opened]="categoryEditOpened"
							>
								<mat-label>Категория</mat-label>
								<mat-icon svgIcon="dropdown-arrow" matSuffix></mat-icon>
								<mat-select (openedChange)="categoryEditOpened = $event" formControlName="category"
											disableRipple
											disableOptionCentering
											panelClass="dropdown-panel">
									<div appSimplebarScroller>
										<mat-option *ngFor="let category of data.categories" [value]="category">
											{{category.name}}
										</mat-option>
									</div>
								</mat-select>
							</mat-form-field>
						</div>
						<div class="price-col price-price body-text-3">
                            <span *ngIf="editingPrice?.uuid !== price.uuid"><span appNumberFormatter
																					 [value]="price.basePrice"></span> ₽</span>
							<mat-form-field *ngIf="editingPrice?.uuid === price.uuid" class="input-field"
											color="primary">
								<mat-label>Цена</mat-label>
								<input type="text" mask="099999999999" [dropSpecialCharacters]="false" matInput
									   formControlName="price">
							</mat-form-field>
						</div>
						<div class="price-col price-tax body-text-3">
							<span *ngIf="price.uuid !== editingPrice?.uuid && price.tax !== null">{{price.tax}}%</span>
							<span *ngIf="price.uuid !== editingPrice?.uuid && price.tax === null">НДС не облагается</span>
							<mat-form-field *ngIf="price.uuid === editingPrice?.uuid" class="input-field"
											color="primary">
								<mat-label>НДС, %</mat-label>
								<input type="text" mask="999" [dropSpecialCharacters]="false" matInput
									   formControlName="vat">
							</mat-form-field>
						</div>
						<div class="price-col price-customers body-text-3">
							<span
								class="customer"
								[style.background-color]="price.customerColor + '1a'"
								[style.border-color]="price.customerColor"
							>{{price.customerName}}</span>
						</div>
						<div class="price-col price-actions">
							<button
								mat-icon-button
								disableRipple
								type="button"
								class="button-icon"
								color="primary"
								*ngIf="editingPrice?.uuid !== price.uuid"
								(click)="editPrice(price)"
							>
								<mat-icon svgIcon="pencil"></mat-icon>
							</button>
							<button
								mat-button
								disableRipple
								type="button"
								class="button-primary-basic-filled"
								*ngIf="editingPrice?.uuid === price.uuid"
								(click)="updatePrice(price)"
								[disabled]="editForm.invalid"
								color="primary"
							>
								Сохранить
							</button>
							<button
								mat-icon-button
								disableRipple
								type="button"
								class="button-icon"
								color="primary"
								(click)="removePrice(price)"
							>
								<mat-icon svgIcon="basket"></mat-icon>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
    </div>
</div>


