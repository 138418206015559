import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {MatButtonModule} from "@angular/material/button";
import {CalendarModule} from "../calendar/calendar.module";
import {MatMenuModule} from "@angular/material/menu";
import {MatIconModule} from "@angular/material/icon";
import {SimplebarScrollerModule} from "../directives/simple-scroller/simplebar-scroller.module";
import {NgxMaskModule} from "ngx-mask";
import {ReactiveFormsModule} from "@angular/forms";
import {InlineAlertModule} from "../inline-alert/inline-alert.module";
import {MatFormFieldModule} from "@angular/material/form-field";
import {InputDateTimePickerComponent} from "./input-date-time-picker.component";

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        CalendarModule,
        MatMenuModule,
        MatIconModule,
        MatFormFieldModule,
        SimplebarScrollerModule,
        NgxMaskModule.forRoot(),
        ReactiveFormsModule,
        InlineAlertModule
    ],
    declarations: [
        InputDateTimePickerComponent
    ],
    exports: [
        InputDateTimePickerComponent
    ]
})
export class InputDateTimePickerModule {
}
