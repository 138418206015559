import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormBuilder, FormGroup} from '@angular/forms';
import {Tournament, Season} from '../../shared/types/events';
import {HttpClient} from '@angular/common/http';
import {SubscriptionTypeCatalogComponent} from '../../components/reference-book/subscription-type-catalog/subscription-type-catalog.component';
import {SeasonCatalogComponent} from '../../components/reference-book/season-catalog/season-catalog.component';
import {SortState} from '../../../shared/ui-kit/sort/sort.component';
import {FullscreenLoaderService} from '../../../shared/ui-kit/fullscreen-loader/fullscreen-loader.service';

interface TableSeason extends Season {
    selected?: boolean;
}

export enum TournamentCreatePopupResult {
    CANCEL,
    SAVE,
    SAVE_AND_CREATE
}

export interface ResourceObject {
    lastModified: string;
    name: string;
    size: number;
    url: string;
    resourceUri: string;
}

@Component({
    selector: 'app-tournament-create-popup',
    templateUrl: './tournament-create-popup.component.html',
    styleUrls: ['./tournament-create-popup.component.scss']
})
export class TournamentCreatePopupComponent implements OnInit {

    public lang: FormControl = new FormControl(0);
    form: FormGroup;
    public loading = true;
    public waiting = false;
    public subscriptionTypeCatalogComponent = SubscriptionTypeCatalogComponent;
    public seasonCatalogComponent = SeasonCatalogComponent;
    public error: string | null = null;
    popupResult = TournamentCreatePopupResult;
    private langName: Map<number, string> = new Map<number, string>([
        [0, 'ru'],
        [1, 'en']
    ]);   
    query = '';
    private sort: {
        field: string,
        direction: SortState
    } = {field: 'name', direction: SortState.ASC};

    public tournament: Tournament;
    public seasons: Season[] = [];
    seasonOpened = false;

    constructor(
        private readonly dialogRef: MatDialogRef<TournamentCreatePopupComponent>,
        private readonly fb: FormBuilder,
        private readonly httpClient: HttpClient,
        private readonly fullscreenLoaderService: FullscreenLoaderService,
        //@Inject(MAT_DIALOG_DATA) public readonly tournament: Tournament,
        @Inject(MAT_DIALOG_DATA) public readonly xz: any
    ) {
        this.tournament = xz?.tournament;
        this.seasons = xz?.seasons;
        
        this.form = this.fb.group({});
        const main = this.fb.group({
            uuid: [null],
            seasonUuid: [null],
            name: [null],            
            logoLink: [null],
            paymentAccount: [null],
          });
        this.form.addControl('main', main);
        
        this.langName.forEach((langName) => {
            const lang = this.fb.group({
                caption: [null]
              });
            this.form.addControl(langName, lang);
        });
    }

    async ngOnInit(): Promise<void> {
        this.loading = true;
        if (this.seasons) {
            this.sortSeasons()
        }
        if (this.tournament && this.seasons) {
            this.form.patchValue({
                main: {
                    // @ts-ignore
                    uuid: this.tournament.uuid,
                    // @ts-ignore,
                    seasonUuid: this.tournament.seasonUuid,
                    // @ts-ignore
                    name: this.tournament.name,
                    // @ts-ignore
                    logoLink: this.tournament.logoLink,
                    paymentAccount: this.tournament.paymentAccount,
                    }
                });
                this.langName.forEach((langName) => {
                    this.form.patchValue({
                        // @ts-ignore
                        [langName] : {
                            // @ts-ignore
                            caption: this.tournament.caption[langName]
                        }
                    });
                });
        }
        this.loading = false;
    }

    private sortSeasons(): void {
        const query = this.query.toLowerCase();

        const seasonsCopy = this.seasons
            .filter((tableSeason: TableSeason) => {
                return tableSeason.name.ru.toLowerCase().includes(query);
            });
        if (this.sort.direction !== SortState.DEFAULT) {
            
            seasonsCopy.sort((a, b): number => {
                let compareResult = 0;
                const left = (a as any)[this.sort.field].ru.toLowerCase();
                const right = (b as any)[this.sort.field].ru.toLowerCase();
                if (left === null || left === undefined) {
                    compareResult = 1;
                } else if (right === null || right === undefined) {
                    compareResult = -1;
                } else {
                    if (left > right) {
                        compareResult = 1;
                    } else {
                        compareResult = -1;
                    }
                }
                if (this.sort.direction === SortState.DESC) {
                    compareResult *= -1;
                }
                return compareResult;
            });
        }
        this.seasons = seasonsCopy;
    }

    public close(result: TournamentCreatePopupResult, data?: any): void {
        this.dialogRef.close({
            result,
            data
        });
    }

    public async save(): Promise<void> {
        try {
            await this._save();
            this.close(TournamentCreatePopupResult.SAVE);
        } catch (e) {

        }
    }

    public async _save(): Promise<Tournament> {

        let caption = {};
        this.langName.forEach((langName) => { 
            if (this.form.value[langName].caption) {
                caption = Object.assign({[langName]: this.form.value[langName].caption ? this.form.value[langName].caption : ''}, caption);
            } 
        });

        const model: any = {
            uuid: this.form.value.main?.uuid ? this.form.value.main?.uuid : '',
            seasonUuid : this.form.value.main.seasonUuid,
            name: this.form.value.ru.caption,
            caption: caption,
            logoLink: this.form.value.main.logoLink,
            altLogoLink: this.form.value.main.altLogoLink,
            paymentAccount: this.form.value.main.paymentAccount,
        };
        this.waiting = true;
        this.error = null;
        this.form.disable();
        try {
           if (this.tournament) {
               return await this.httpClient.put<Tournament>(`/api/event-show-tournament/update`, model).toPromise();
            } else {
                 return await this.httpClient.post<Tournament>(`/api/event-show-tournament/create`, model).toPromise();
             }
        } catch (e) {
            this.error = e.error.message;
            this.form.enable();
            this.waiting = false;
            throw e;
        }
    }

    public onReferenceBookChange(tab: number): void {
        this.lang.setValue(tab);
    }

    public getLangName(index: number): string {
        return (this.langName.get(index)) as string;
    }

    public findSeasonByUuid(uuid: string): Season | null {
        return this.seasons.find(season => season.uuid === uuid) || null;
    }

    public getSeasonName (season : Season | null) {
        if (season) {
            return season.name.ru;
        } else return null;
    }

    public onSeasonCreate(season: Season): void {
        this.seasons.push(season);
        //this.seasonUuid = season.uuid;
        this.form.patchValue({
            main: {
                seasonUuid: season.uuid
                }
            });
    }

    public addImage(control: string, param: string): void {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.addEventListener('change', async (event) => {
            const files = (event.target as HTMLInputElement).files;
            const file = files && files[0];
            const formData: FormData = new FormData();
            formData.append('file', file as File);
            this.fullscreenLoaderService.open();
            try {
                const resourceObject = await this.httpClient.post<ResourceObject>(`/api/resource/object`, formData).toPromise();
                this.form.patchValue({
                    [control]: { [param]: resourceObject.resourceUri
                    }}, {emitEvent: false});
            } catch (e) {
            }
            this.fullscreenLoaderService.close();
        });
        input.click();
        input.remove();
    }

    public removeLink(control: string, param: string): void {
        this.form.patchValue({
            [control]: { [param]: null
            }});
    }

    public downloadResourceObject(control: string, param: string): void {
        const link = document.createElement('a');
        link.target = '_blank';
        link.href = this.form.controls[control].value[param];
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

}
