<div [formGroup]="form">
    
        <div class="reference-book-input">
            <mat-form-field class="input-field" color="primary">
                <mat-label>Название*</mat-label>
                <input type="text" autocomplete="off" matInput formControlName="name">
                <mat-error
                        *ngIf="form.controls.name.hasError('invalid')">{{form.controls.name.getError('invalid')}}</mat-error>
            </mat-form-field>
    <!--
            <mat-form-field class="input-field" color="primary">
                <mat-label>Подпись*</mat-label>
                <input type="text" autocomplete="off" matInput formControlName="caption">
                <mat-error
                        *ngIf="form.controls.caption.hasError('invalid')">{{form.controls.caption.getError('invalid')}}</mat-error>
            </mat-form-field>
    -->
        </div>
        
        <div class="reference-book-input">
            <mat-form-field class="input-field" color="primary">
                <mat-label>URL логотипа*</mat-label>
                <input type="text" autocomplete="off" matInput formControlName="logoLink">
                <mat-error
                        *ngIf="form.controls.logoLink.hasError('invalid')">{{form.controls.logoLink.getError('invalid')}}</mat-error>
            </mat-form-field>
    <!--
            <mat-form-field class="input-field" color="primary">
                <mat-label>Подпись*</mat-label>
                <input type="text" autocomplete="off" matInput formControlName="caption">
                <mat-error
                        *ngIf="form.controls.caption.hasError('invalid')">{{form.controls.caption.getError('invalid')}}</mat-error>
            </mat-form-field>
    -->
        </div>
        <div class="reference-book-action">
            <button
                    mat-raised-button
                    disableRipple
                    type="button"
                    class="button-primary-filled button-primary-filled-secondary"
                    color="primary"
                    [disabled]="form.invalid || waiting"
                    (click)="add()"
            >
                Добавить
            </button>
            <button
                    mat-icon-button
                    disableRipple
                    type="button"
                    class="button-icon"
                    color="primary"
                    [disabled]="waiting"
                    (click)="close.emit()"
            >
                <mat-icon svgIcon="times"></mat-icon>
            </button>
        </div>
    
</div>
