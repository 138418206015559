import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    Output
} from "@angular/core";
import {DateService} from "../../date.service";
import {BaseRangeCell} from "./base-range-cell";

@Component({
    selector: 'calendar-range-day-cell',
    template: `
        <span class="calendar-cell-content">{{date === null ? '' : date.getDate()}}</span>
    `,
    styleUrls: ['./base-range-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseRangeCellComponent implements BaseRangeCell {
    @Input() date: Date | null = null;

    @Input() start: Date | null = null;

    @Input() end: Date | null = null;

    @Input() hoveredDate: Date | null = null;

    @Output() select: EventEmitter<Date> = new EventEmitter(true);

    @Output() hover: EventEmitter<Date | null> = new EventEmitter(true);

    constructor(
        private dateService: DateService
    ) {
    }

    @HostBinding('class.calendar-day-today') get today(): boolean {
        if (!(this.date instanceof Date)) {
            return false;
        }
        return this.date.getTime() === this.dateService.today;
    }

    @HostBinding('class.calendar-day-empty') get empty(): boolean {
        return this.date === null;
    }

    @HostBinding('class.calendar-range-day-selected') get rangeSelected(): boolean {
        if (!(this.start instanceof Date) && !(this.end instanceof Date)) {
            return false;
        }
        if (this.start === null) {
            return false;
        }
        if (!(this.date instanceof Date)) {
            return false;
        }
        const time = this.date.getTime();
        if (this.start !== null && this.end === null) {
            return this.start.getTime() === time;
        }
        if (this.end === null) {
            return false;
        }
        return this.start.getTime() <= time && time <= this.end.getTime();
    }

    @HostBinding('class.calendar-range-day-selected-first') get getRangeSelectedFirst(): boolean {
        if (!(this.date instanceof Date)) {
            return false;
        }
        if (!(this.start instanceof Date)) {
            return false;
        }
        return this.date.getTime() === this.start.getTime();
    }

    @HostBinding('class.calendar-range-day-selected-last') get getRangeSelectedLast(): boolean {
        if (!(this.date instanceof Date)) {
            return false;
        }
        if (this.start !== null && this.end === null) {
            return this.date.getTime() === this.start.getTime();
        }
        if (!(this.end instanceof Date)) {
            return false;
        }
        return this.date.getTime() === this.end.getTime();
    }

    @HostBinding('class.calendar-range-day-highlighted') get rangeHighlighted(): boolean {
        if (this.date === null) {
            return false;
        }
        if (this.start === null || this.hoveredDate === null) {
            return false;
        }
        if (this.end !== null) {
            return false;
        }
        const start = this.start.getTime();
        const hoveredDate = this.hoveredDate.getTime();
        const date = this.date.getTime();
        return (date >= start && date <= hoveredDate) || (date <= start && date >= hoveredDate);
    }

    @HostBinding('class.calendar-range-day-highlighted-first') get rangeHighlightedFirst(): boolean {
        if (this.date === null) {
            return false;
        }
        if (this.start === null || this.hoveredDate === null) {
            return false;
        }
        if (this.end !== null) {
            return false;
        }
        const start = this.start.getTime();
        const hoveredDate = this.hoveredDate.getTime();
        return start === this.date.getTime() && hoveredDate > start;
    }

    @HostBinding('class.calendar-range-day-highlighted-last') get rangeHighlightedLast(): boolean {
        if (this.date === null) {
            return false;
        }
        if (this.start === null || this.hoveredDate === null) {
            return false;
        }
        if (this.end !== null) {
            return false;
        }
        const start = this.start.getTime();
        const hoveredDate = this.hoveredDate.getTime();
        return start === this.date.getTime() && hoveredDate < start;
    }

    @HostBinding('class.calendar-range-day-highlighted-tail-left') get rangeHighlightedTailLeft(): boolean {
        if (this.date === null) {
            return false;
        }
        if (this.start === null || this.hoveredDate === null) {
            return false;
        }
        if (this.end !== null) {
            return false;
        }
        const start = this.start.getTime();
        const hoveredDate = this.hoveredDate.getTime();
        return hoveredDate === this.date.getTime() && hoveredDate < start;
    }

    @HostBinding('class.calendar-range-day-highlighted-tail-right') get rangeHighlightedTailRight(): boolean {
        if (this.date === null) {
            return false;
        }
        if (this.start === null || this.hoveredDate === null) {
            return false;
        }
        if (this.end !== null) {
            return false;
        }
        const start = this.start.getTime();
        const hoveredDate = this.hoveredDate.getTime();
        return hoveredDate === this.date.getTime() && hoveredDate > start;
    }

    @HostBinding('class.calendar-range-day-hovered') get hovered(): boolean {
        if (this.date === null) {
            return false;
        }
        if (this.hoveredDate === null) {
            return false;
        }
        return this.date.getTime() === this.hoveredDate.getTime();
    }

    @HostListener('mouseover')
    onMouseOver() {
        if (!(this.date instanceof Date)) {
            return
        }
        this.hover.emit(this.date);
    }

    @HostListener('mouseout')
    onMouseOut() {
        this.hover.emit(null);
    }

    @HostListener('click')
    onClick() {
        if (!(this.date instanceof Date)) {
            return
        }
        this.select.emit(this.date);
    }
}
