import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {EventSessionSettingsPopupComponent} from './event-session-settings-popup.component';
import {EventSessionSettingsService, SettingsLoadingStatus, SettingsPopupState} from './event-session-settings.service';

@Injectable({
    providedIn: 'root'
})
export class EventSessionSettingsPopupService {

    constructor(
        private readonly matDialog: MatDialog,
        private readonly eventSessionSettingsService: EventSessionSettingsService
    ) {
    }

    public open(eventSessionUuid: string, state: SettingsPopupState): MatDialogRef<EventSessionSettingsPopupComponent> {
        this.eventSessionSettingsService.loading.next(SettingsLoadingStatus.UNKNOWN);
        const dialogRef = this.matDialog.open(EventSessionSettingsPopupComponent, {
            panelClass: 'fullscreen-mat-dialog-panel',
            restoreFocus: false,
            disableClose: true,
            autoFocus: false
        });
        this.eventSessionSettingsService.init(eventSessionUuid, state);

        return dialogRef;
    }
}
