import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EventTypeCatalogComponent} from './event-type-catalog.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';


@NgModule({
    declarations: [EventTypeCatalogComponent],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        ReactiveFormsModule,
        MatIconModule
    ],
    exports: [EventTypeCatalogComponent]
})
export class EventTypeCatalogModule {
}
