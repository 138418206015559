import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {UserService} from '../shared/user/user.service';

@Injectable({providedIn: 'root'})
export class PagesResolver implements Resolve<any> {
    constructor(
        private httpClient: HttpClient,
        private userService: UserService,
        private readonly router: Router
    ) {
    }

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<any> {
        try {
            await this.userService.loadCurrentUser();
        } catch (e) {
            this.router.navigate(['auth']);
        }
    }
}
