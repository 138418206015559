import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class NumberFormatterService {
    public static format(value: any): string {
        if ((typeof value !== 'number') && (typeof value !== 'string')) {
            return '';
        }
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
}
