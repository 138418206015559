import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {MatIconModule} from "@angular/material/icon";
import {SortComponent} from "./sort.component";

@NgModule({
    imports: [
        CommonModule,
        MatIconModule
    ],
    declarations: [
        SortComponent
    ],
    exports: [
        SortComponent
    ]
})
export class SortModule {
}
