import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {RangeSliderComponent} from "./range-slider.component";
import {Ng5SliderModule} from "ng5-slider";

@NgModule({
    imports: [
        CommonModule,
        Ng5SliderModule
    ],
    declarations: [
        RangeSliderComponent
    ],
    exports: [
        RangeSliderComponent
    ]
})
export class RangeSliderModule {
}
