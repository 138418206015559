import {NgModule} from '@angular/core';
import {CalendarComponent} from "./calendar.component";
import {CommonModule} from "@angular/common";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {BaseCellComponent} from "./components/base-cell-component/base-cell.component";
import {CalendarDaysComponent} from "./components/calendar-days.component/calendar-days.component";
import {RangeCalendarComponent} from "./range-calendar.component";
import {CalendarRangeDaysComponent} from "./components/calendar-range-days.component/calendar-range-days.component";
import {BaseRangeCellComponent} from "./components/base-cell-component/base-range-cell.component";

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule
    ],
    declarations: [
        CalendarComponent,
        BaseCellComponent,
        BaseRangeCellComponent,
        CalendarDaysComponent,
        CalendarRangeDaysComponent,
        RangeCalendarComponent
    ],
    exports: [
        CalendarComponent,
        BaseCellComponent,
        BaseRangeCellComponent,
        RangeCalendarComponent
    ],
    entryComponents: [
        BaseCellComponent,
        BaseRangeCellComponent
    ]
})
export class CalendarModule {
}
