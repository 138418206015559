import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {InlineAlertComponent} from "./inline-alert.component";
import {MatIconModule} from "@angular/material/icon";

@NgModule({
    imports: [
        CommonModule,
        MatIconModule
    ],
    declarations: [
        InlineAlertComponent
    ],
    exports: [
        InlineAlertComponent
    ]
})
export class InlineAlertModule {
}
