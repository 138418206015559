<div class="popup-close-container">
    <button
            mat-icon-button
            disableRipple
            type="button"
            class="button-icon"
            color="primary"
            [disabled]="waiting"
            (click)="close()"
    >
        <mat-icon svgIcon="times"></mat-icon>
    </button>
</div>

<div class="popup-wrapper" *ngIf="!loading">
    <div class="popup-header">
        <div class="popup-header-title-container">
            <div class="body-text-1">Открытие продаж</div>
            <div class="body-text-3 popup-header-subtitle">Выбрано событий: <span>{{this.eventSessions.length}}</span>
            </div>
        </div>
        <div class="popup-header-warning-container">
            <inline-alert-component *ngIf="this.eventSessions.length > 1" status="warning"
                                    text="Все существующие настройки для выбранных мероприятий будут заменены на выбранные!"></inline-alert-component>
        </div>
    </div>
    <div class="popup-body">
        <div class="popup-body-form-container" [formGroup]="form">
            <div class="client-input-wrapper">
                <mat-form-field appearance="fill" color="primary" class="input-select"
                                [class.input-select__opened]="clientsOpened">
                    <mat-label>Клиент</mat-label>
                    <mat-icon svgIcon="dropdown-arrow" matSuffix></mat-icon>
                    <mat-select (openedChange)="clientsOpened = $event" formControlName="client" disableRipple
                                disableOptionCentering
                                panelClass="dropdown-panel">
                        <div appSimplebarScroller>
                            <mat-option *ngFor="let customer of getCustomers()" [value]="customer">
                                {{customer.name}}
                            </mat-option>
                        </div>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="date-input-wrapper">
                <input-date-time-range-picker [start]="form.controls.start.value" [end]="form.controls.end.value"
                                              (rangeChange)="onRangeChange($event)"></input-date-time-range-picker>
            </div>
            <div class="add-button-wrapper">
                <button
                        mat-raised-button
                        disableRipple
                        type="button"
                        class="button-primary-filled"
                        [disabled]="waiting || form.invalid"
                        color="success"
                        (click)="createLimit()"
                >
                    <mat-icon svgIcon="checkmark" class="mat-button-icon_left"></mat-icon>
                    Добавить
                </button>
            </div>
        </div>
        <div class="popup-body-table-container" appSimplebarScroller>
            <div class="limits-row" *ngFor="let limit of table">
                <div class="limit-col-client body-text-2">{{limit.customerName}}</div>
                <div class="limit-col-date body-text-3">{{limit.start}} - {{limit.end}}</div>
                <div class="limit-col-actions">
                    <button
                            mat-icon-button
                            disableRipple
                            type="button"
                            class="button-icon"
                            color="primary"
                            [disabled]="waiting"
                            (click)="removeLimit(limit)"
                    >
                        <mat-icon svgIcon="basket"></mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="popup-footer">
        <div class="popup-footer-alert-container">
            <inline-alert-component [text]="error" *ngIf="error !== null"></inline-alert-component>
        </div>
        <div class="popup-footer-actions">
            <button
                    mat-button
                    disableRipple
                    type="button"
                    class="button-primary-basic-text"
                    [disabled]="waiting"
                    color="primary"
                    (click)="clearSales()"
            >
                Сбросить данные
            </button>
            <button
                    mat-raised-button
                    disableRipple
                    type="button"
                    class="button-primary-filled"
                    [disabled]="waiting || table.length === 0"
                    color="primary"
                    (click)="openSales()"
            >
                Открыть продажи
            </button>
        </div>
    </div>
</div>

<div class="popup-wrapper" *ngIf="loading">
    <div class="popup-header">
        <div class="popup-header-title-container">
            <div class="headline-1">
                <skeleton-text [width]="270" [height]="34"></skeleton-text>
            </div>
            <div class="body-text-3 popup-header-subtitle">
                <skeleton-text [width]="150" [height]="14"></skeleton-text>
            </div>
        </div>
    </div>
    <div class="popup-body">
        <div class="popup-body-form-container">
            <div class="client-input-wrapper">
                <skeleton-text [width]="261" [height]="44" [inverseColor]="true"></skeleton-text>
            </div>
            <div class="date-input-wrapper">
                <skeleton-text [width]="261" [height]="44" [inverseColor]="true"></skeleton-text>
            </div>
            <div class="add-button-wrapper">
                <skeleton-text [width]="134" [height]="44" [inverseColor]="true"></skeleton-text>
            </div>
        </div>
        <div class="popup-body-table-container" appSimplebarScroller>
            <div class="limits-row" *ngFor="let limit of [1,2,3]">
                <div class="limit-col-client body-text-2">
                    <skeleton-text [width]="100" [height]="18"></skeleton-text>
                </div>
                <div class="limit-col-date body-text-3">
                    <skeleton-text [width]="200" [height]="16"></skeleton-text>
                </div>
                <div class="limit-col-actions"></div>
            </div>
        </div>
    </div>
    <div class="popup-footer">
        <div class="popup-footer-alert-container"></div>
        <div class="popup-footer-actions">
            <skeleton-text [width]="160" [height]="32"></skeleton-text>
            <skeleton-text [width]="200" [height]="44"></skeleton-text>
        </div>
    </div>
</div>
