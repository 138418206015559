import {Component, HostBinding, OnDestroy, OnInit} from "@angular/core";

@Component({
    selector: 'fullscreen-loader-component',
    templateUrl: './fullscreen-loader.component.html',
    styleUrls: ['./fullscreen-loader.scss']
})
export class FullscreenLoaderComponent implements OnInit, OnDestroy {

    private timeoutId: any = null;
    private readonly delay = 200;
    private visible = false;

    ngOnInit(): void {
        this.timeoutId = setTimeout(() => {
            this.visible = true;
        }, this.delay);
    }

    ngOnDestroy(): void {
        if (this.timeoutId !== null) {
            clearTimeout(this.timeoutId);
        }
    }

    @HostBinding('class.fullscreen-loader-visible')
    get isVisible(): boolean {
        return this.visible;
    }
}
