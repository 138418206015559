import {Component, Inject, Input} from "@angular/core";
import {MAT_SNACK_BAR_DATA} from "@angular/material/snack-bar";

@Component({
    selector: 'shack-bar-component',
    template: `
        <div class="snack-bar">
            <mat-icon svgIcon="error"></mat-icon>
            <span class="body-text-2">{{data.text}}</span>
        </div>
    `,
    styleUrls: ['./snack-bar.component.scss']
})
export class SnackBarComponent {
    @Input()
    text: string = '';

    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: { text: string }) {

    }

}
