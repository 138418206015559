<div class="calendar">
    <div class="calendar-header">
        <button
                mat-icon-button
                disableRipple
                type="button"
                class="button-icon"
                color="primary"
                (click)="prevMonth()"
        >
            <mat-icon svgIcon="pagination-prev"></mat-icon>
        </button>
        <div class="calendar-header-title">{{getMonthName()}}, {{getYear()}}</div>
        <button
                mat-icon-button
                disableRipple
                type="button"
                class="button-icon"
                color="primary"
                (click)="nextMonth()"
        >
            <mat-icon svgIcon="pagination-next"></mat-icon>
        </button>

    </div>
    <div class="calendar-day-names">
        <span class="calendar-day-name">Пн</span>
        <span class="calendar-day-name">Вт</span>
        <span class="calendar-day-name">Ср</span>
        <span class="calendar-day-name">Чт</span>
        <span class="calendar-day-name">Пт</span>
        <span class="calendar-day-name">Сб</span>
        <span class="calendar-day-name">Вс</span>
    </div>
    <calendar-days
            [days]="days"
            [selectedValue]="date"
            [cellComponent]="cellComponent"
            (select)="onDateChange($event)"></calendar-days>
</div>
