import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormBuilder, FormGroup} from '@angular/forms';
import {EventProjectPreference} from '../../shared/types/events';
import {HttpClient} from '@angular/common/http';
import {SubscriptionTypeCatalogComponent} from '../../components/reference-book/subscription-type-catalog/subscription-type-catalog.component';
import {FullscreenLoaderService} from '../../../shared/ui-kit/fullscreen-loader/fullscreen-loader.service';

export enum EventProjectPreferenceCreatePopupResult {
    CANCEL,
    SAVE,
    SAVE_AND_CREATE
}

export interface ResourceObject {
    lastModified: string;
    name: string;
    size: number;
    url: string;
    resourceUri: string;
}

@Component({
    selector: 'app-event-project-preference-create-popup',
    templateUrl: './event-project-preference-create-popup.component.html',
    styleUrls: ['./event-project-preference-create-popup.component.scss']
})
export class EventProjectPreferenceCreatePopupComponent implements OnInit {
    public displayedTable = [true, false];
    displayAtEventsOpened = false;
    public lang: FormControl = new FormControl(0);
    form: FormGroup;
    public loading = true;
    public waiting = false;
    public subscriptionTypeCatalogComponent = SubscriptionTypeCatalogComponent;
    public error: string | null = null;
    popupResult = EventProjectPreferenceCreatePopupResult;
    private langName: Map<number, string> = new Map<number, string>([
        [0, 'ru'],
        [1, 'en']
    ]);

    constructor(
        private readonly dialogRef: MatDialogRef<EventProjectPreferenceCreatePopupComponent>,
        private readonly fb: FormBuilder,
        private readonly httpClient: HttpClient,
        private readonly fullscreenLoaderService: FullscreenLoaderService,
        @Inject(MAT_DIALOG_DATA) public readonly projectPreference: EventProjectPreference
    ) {

        this.form = this.fb.group({});
        const main = this.fb.group({
            uuid: [null],
            logoLink: [null],
            iconLink: [null],
            partnerLogoLink: [null],
            order: [null],
            age: [null],
            displayedAtEvents: [null],
            erid: [null],
          });
        this.form.addControl('main', main);

        this.langName.forEach((langName) => {
            const lang = this.fb.group({
              name: [null],
              description: [null],
              });
            this.form.addControl(langName, lang);
        });
    }

    async ngOnInit(): Promise<void> {
        this.loading = true;
        if (this.projectPreference) {
            this.form.patchValue({
                main: {
                    uuid: this.projectPreference.uuid,
                    logoLink: this.projectPreference.logoLink ? this.projectPreference.logoLink : '',
                    iconLink: this.projectPreference.iconLink ? this.projectPreference.iconLink : '',
                    partnerLogoLink: this.projectPreference.partnerLogoLink ? this.projectPreference.partnerLogoLink : '',
                    order: this.projectPreference.order ? this.projectPreference.order : 0,
                    age: this.projectPreference.age ? this.projectPreference.age : '',
                    displayedAtEvents: this.projectPreference.displayedAtEvents ? this.projectPreference.displayedAtEvents : false,
                    erid: this.projectPreference.erid ? this.projectPreference.erid : '',
                    }
                });
            this.langName.forEach((langName) => {
                    this.form.patchValue({
                        // @ts-ignore
                        [langName] : {
                          // @ts-ignore
                          description: this.projectPreference?.description[langName],
                          // @ts-ignore
                          name: this.projectPreference?.name[langName],
                        }
                    });
            });

        }
        this.loading = false;
    }

    public close(result: EventProjectPreferenceCreatePopupResult, data?: any): void {
        this.dialogRef.close({
            result,
            data
        });
    }

    public async save(): Promise<void> {
        try {
            await this._save();
            this.close(EventProjectPreferenceCreatePopupResult.SAVE);
        } catch (e) {

        }
    }

    public async _save() {
        let name: any;
        let description: any;
        this.langName.forEach((langName) => {
            const NameValue = this.form.value[langName].name;
            const descriptionValue = this.form.value[langName].description;
            name = Object.assign({
              [langName]: NameValue ?? ''
                }, name);
            description = Object.assign({
                [langName]: descriptionValue ?? ''
              }, description);
        });

        const model: any = {
          name,
          description,
          uuid: this.form.value.main.uuid ?? '',
          erid: this.form.value.main.erid,
          order: +this.form.value.main.order,
          age: this.form.value.main.age,
          displayedAtEvents: this.form.value.main.displayedAtEvents,
          logoLink: this.form.value.main.logoLink ? this.form.value.main.logoLink : '',
          iconLink: this.form.value.main.iconLink ? this.form.value.main.iconLink : '',
          partnerLogoLink: this.form.value.main.partnerLogoLink ? this.form.value.main.partnerLogoLink : '',
        };
        this.waiting = true;
        this.error = null;
        this.form.disable();
        try {
           if (this.projectPreference) {
                return await this.httpClient.put<EventProjectPreference>(`/api/event-project/update`, model).toPromise();
            } else {
                return await this.httpClient.post<EventProjectPreference>(`/api/event-project/create`, model).toPromise();
             }
        } catch (e) {
            // @ts-ignore
            this.error = e.error.message;
            this.form.enable();
            this.waiting = false;
            throw e;
        }
    }

    public onReferenceBookChange(tab: number): void {
        this.lang.setValue(tab);
    }

    public getLangName(index: number): string {
        return (this.langName.get(index)) as string;
    }

    public addImage(control: string, param: string): void {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.addEventListener('change', async (event) => {
            const files = (event.target as HTMLInputElement).files;
            const file = files && files[0];
            const newFileName = file?.name.replace(/\s/g, '');
            // @ts-ignore
            const newFile = this.renameFile(file, newFileName);
            const formData: FormData = new FormData();
            formData.append('file', newFile as File);
            this.fullscreenLoaderService.open();
            try {
                const resourceObject = await this.httpClient.post<ResourceObject>(`/api/resource/object`, formData).toPromise();
                this.form.patchValue({
                    [control]: { [param]: resourceObject.resourceUri
                    }}, {emitEvent: false});
            } catch (e) {
            }
            this.fullscreenLoaderService.close();
        });
        input.click();
        input.remove();
    }

    public removeLink(control: string, param: string): void {
        this.form.patchValue({
            [control]: { [param]: null
            }});
    }

    public downloadResourceObject(control: string, param: string): void {
        const link = document.createElement('a');
        link.target = '_blank';
        link.href = this.form.controls[control].value[param];
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

  public getdisplayedTable(): any[] {
    return this.displayedTable;
  }

  // tslint:disable-next-line:typedef
  public renameFile(originalFile: File, newName: string) {
    return new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    });
  }
}
