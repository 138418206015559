<div class="popup-wrapper">
    <div class="popup-header">
        <div class="body-text-1">Возврат билетов</div>
        <button
                mat-icon-button
                disableRipple
                type="button"
                class="button-icon"
                color="primary"
                (click)="close()"
        >
            <mat-icon svgIcon="times"></mat-icon>
        </button>
    </div>
    <div class="popup-body" [formGroup]="form">

        <div style="padding-bottom: 15px;">Сумма выбранных билетов <b>{{data.totalSumm  | mask:'separator.2'}}</b> руб.</div>

        <div style="display: flex; justify-content: space-between; align-items: center; padding-bottom: 30px;">
            <div class="field-wrapper field-wrapper-name">
                <mat-form-field class="input-field" color="primary">
                    <mat-label>Процент удержания</mat-label>
                    <input type="number" matInput formControlName="percent" (change)="onChangePercent($event.target)">
                </mat-form-field>
            </div>
            <div class="field-wrapper field-wrapper-name">
                <mat-form-field class="input-field" color="primary">
                    <mat-label>Сумма удержания*</mat-label>
                    <input type="number" matInput formControlName="amount" (change)="onChangeAmount($event.target)">
                </mat-form-field>
            </div>
        </div>


        <div style="display: flex; align-items: center; justify-content: space-between;">
            <div>
                <button
                        mat-raised-button
                        disableRipple
                        type="button"
                        class="button-primary-filled button-primary-filled-secondary"
                        color="primary"
                        [disabled]="waiting"
                        (click)="sendSms()"
                >
                    Получить смс
                </button>
            </div>
            <div class="field-wrapper field-wrapper-name">
                <mat-form-field class="input-field" color="primary">
                    <mat-label>Код подтверждения*</mat-label>
                    <input type="number" matInput formControlName="sms" (change)="onChangeSms($event.target)">
                </mat-form-field>
            </div>

        </div>

        <div class="popup-footer">
            <button
                    mat-raised-button
                    disableRipple
                    type="button"
                    class="button-primary-filled button-primary-filled-secondary"
                    color="primary"
                    [disabled]="form.invalid || waiting || checkSms()===0"
                    (click)="return()"
            >
                Сделать возврат
            </button>
        </div>
    </div>
        
        <div class="error-container" *ngIf="error">
            <inline-alert-component [text]="error"></inline-alert-component>
        </div>

</div>