import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {FullscreenLoaderService} from "../../../../../shared/ui-kit/fullscreen-loader/fullscreen-loader.service";
import {HttpClient} from "@angular/common/http";

interface Price {
    basePrice: number;
    categoryName: string;
    categoryUuid: string;
    eventSessionUuid: string;
    customerName: string;
    customerUuid: string;
    customerColor: string;
    customerOrder: number;
    tax: number | null;
    uuid: string;
    zoneColor: string;
    zoneName: string;
    zoneUuid: string;
    selected: boolean;
}

interface Category {
    caption: string;
    name: string;
    uuid: string;
}


@Component({
    selector: 'app-grouped-price-rate-popup',
    templateUrl: './grouped-price-rate-popup.component.html',
    styleUrls: ['./grouped-price-rate-popup.component.scss']
})
export class GroupedPriceRatePopupComponent implements OnInit {

    public readonly editForm: FormGroup;
    public editingPrice: Price | null = null;

    categoryEditOpened = false;

    public error: string | null = null;

    constructor(
        private readonly dialogRef: MatDialogRef<GroupedPriceRatePopupComponent>,
        private readonly fb: FormBuilder,
        private readonly fullscreenLoaderService: FullscreenLoaderService,
        private readonly httpClient: HttpClient,
        @Inject(MAT_DIALOG_DATA) public readonly data: {
            categories: Category[],
            prices: Price[],
            eventSessionUuid: string
        }
    ) {

        const priceValidators = [Validators.required, (control: AbstractControl) => {
            if (!/^\d+$/.test(control.value)) {
                return {
                    invalid: true
                };
            }
            return null;
        }];

        const vatValidators = [(control: AbstractControl) => {
            const value = control.value;
            if (value === null || value.trim() === '') {
                return null;
            }
            if (!/^\d+$/.test(value)) {
                return {
                    invalid: true
                };
            }
            const num = +value;
            if (num < 0 || num > 100) {
                return {
                    invalid: true
                };
            }
            return null;
        }];

        this.editForm = this.fb.group({
            category: [null, [Validators.required]],
            price: [null, priceValidators],
            vat: [null, vatValidators]
        });
    }

    ngOnInit(): void {

    }

    public close(): void {
        this.dialogRef.close();
    }

    public editPrice(price: Price): void {
        this.editingPrice = price;
        this.editForm.patchValue({
            price: price.basePrice.toString(),
            vat: price.tax?.toString() || null,
            category: this.data.categories.find(category => category.uuid === price.categoryUuid)
        });
    }

    public async updatePrice(price: Price): Promise<void> {
        this.fullscreenLoaderService.open();
        this.error = null;

        try {
            const tax = this.editForm.value.vat;
            const basePrice = +this.editForm.value.price;
            const categoryUuid = this.editForm.value.category.uuid;

            const model = {
                basePrice,
                categoryUuid,
                eventSessionUuid: this.data.eventSessionUuid,
                tax: tax === null || tax === '' ? null : +tax,
                uuid: price.uuid,
                zoneUuid: price.zoneUuid,
                customerUuid: price.customerUuid
            };
            await this.httpClient.put(`/api/price/update`, model).toPromise();

            price.basePrice = model.basePrice;
            price.tax = model.tax;
            price.categoryUuid = this.editForm.value.category.uuid;
            price.categoryName = this.editForm.value.category.name;

            this.editingPrice = null;
        } catch (e) {
            this.error = e.error.message;
        }
        this.fullscreenLoaderService.close();
    }

    public async removePrice(price: Price): Promise<void> {
        this.error = null;
        this.fullscreenLoaderService.open();
        try {
            await this.httpClient
                .delete(`/api/price/delete/${price.uuid}`).toPromise();

            this.data.prices.splice(this.data.prices.indexOf(price), 1);
            this.data.prices = [...this.data.prices];
        } catch (e) {
            this.error = e.error.message;
        }
        this.fullscreenLoaderService.close();
    }

}
