import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SubscriptionTypeCatalogComponent} from './subscription-type-catalog.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';


@NgModule({
    declarations: [SubscriptionTypeCatalogComponent],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        ReactiveFormsModule,
        MatIconModule
    ],
    exports: [SubscriptionTypeCatalogComponent]
})
export class SubscriptionTypeCatalogModule {
}
