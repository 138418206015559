import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {format} from 'date-fns';

@Component({
    selector: 'app-lock-time-change-popup',
    templateUrl: './lock-time-change-popup.component.html',
    styleUrls: ['./lock-time-change-popup.component.scss']
})
export class LockTimeChangePopupComponent {

    form: FormGroup;

    public waiting = false;

    public error: string | null = null;

    constructor(
        private readonly dialogRef: MatDialogRef<LockTimeChangePopupComponent>,
        private readonly fb: FormBuilder,
        private readonly httpClient: HttpClient,
        @Inject(MAT_DIALOG_DATA) public readonly orderUuid: string
    ) {
        this.form = this.fb.group({
            date: [null, [Validators.required]]
        });
    }

    public close(): void {
        this.dialogRef.close();
    }

    public async save(): Promise<void> {
        this.waiting = true;
        this.error = null;
        try {
            const model = {
                lockTime: format(this.form.value.date, `yyyy-MM-dd'T'HH:mm:ss`),
                orderUuid: this.orderUuid
            };
            await this.httpClient.put(`/api/order/update-lock-time`, model).toPromise();
            this.close();
        } catch (e) {
            this.error = e.error.message;
        }
        this.waiting = false;
    }

    public onDateChange(date: Date | null): void {
        this.form.controls.date.setValue(date);
    }

}
