import {Component} from "@angular/core";

@Component({
    selector: 'inline-spinner-component',
    template: `
        <mat-spinner diameter="20"></mat-spinner>
    `,
    styles: [
        `
            :host {
                display: inline-flex;
                width: 24px;
                height: 24px;
                justify-content: center;
                align-items: center;
            }       
        `
    ]
})
export class InlineSpinnerComponent {

}
