import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {Tournament} from '../../../shared/types/events';
import {BaseCatalog} from '../base-catalog/base-catalog';

@Component({
    selector: 'app-event-session-type-catalog',
    templateUrl: './event-session-type-catalog.component.html',
    styleUrls: ['./event-session-type-catalog.component.scss']
})
export class EventSessionTypeCatalogComponent implements BaseCatalog<Tournament> {

    public form: FormGroup;
    public waiting = false;


    @Output()
    create: EventEmitter<Tournament> = new EventEmitter<Tournament>();

    @Output()
    close: EventEmitter<void> = new EventEmitter<void>();

    @Input()
    seasonUuid = '';

    constructor(
        private readonly fb: FormBuilder,
        private readonly httpClient: HttpClient
    ) {
        this.form = this.fb.group({
            name: [null, Validators.required],
            seasonUuid: [null]
        });
    }

    public async add(): Promise<void> {
        this.form.disable();
        this.waiting = true;
        try {
            const model = {
                seasonUuid: this.seasonUuid,
                name: this.form.value.name,
                caption: {ru: this.form.value.name}
            };
            const eventSessionType = await this.httpClient
                .post<Tournament>(`/api/event-show-tournament/create`, model).toPromise();
            this.create.emit(eventSessionType);
            this.close.emit();
            this.form.enable();
        } catch (e) {
            this.form.enable();
            this.form.controls.name.setErrors({invalid: e.error.message});
        }
        this.waiting = false;
    }
}
