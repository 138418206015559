import {AfterViewInit, Directive, ElementRef, Input, OnDestroy} from '@angular/core';
import SimpleBar from 'simplebar';
import {fromEvent, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Directive({
    selector: '[appSimplebarScroller]'
})
export class SimpleBarScrollerDirective implements AfterViewInit, OnDestroy {

    private simpleBar: SimpleBar | undefined;

    @Input()
    scrollEnabled = true;

    @Input()
    scrollWithMouse = false;

    @Input()
    scrollWithMouseThreshold = 5;

    private readonly $destroy: Subject<void> = new Subject<void>();

    constructor(private elementRef: ElementRef) {

    }

    ngAfterViewInit(): void {
        if (!this.scrollEnabled) {
            return;
        }
        const element = this.elementRef.nativeElement;

        this.simpleBar = new SimpleBar(element);


        if (this.scrollWithMouse) {
            let mouseDownX: number | null = null;
            let scrollLeft = 0;
            let scrolling = false;

            const scrollElement = this.simpleBar.getScrollElement() as HTMLElement;

            fromEvent<MouseEvent>(element, 'mousedown')
                .pipe(
                    takeUntil(this.$destroy)
                )
                .subscribe((e: MouseEvent) => {
                    mouseDownX = e.screenX;
                    scrollLeft = scrollElement.scrollLeft;
                    scrolling = false;
                });

            fromEvent<MouseEvent>(element, 'mousemove')
                .pipe(
                    takeUntil(this.$destroy)
                )
                .subscribe((e: MouseEvent) => {
                    if (mouseDownX === null) {
                        return;
                    }
                    const dx = mouseDownX - e.screenX;
                    if (Math.abs(dx) < this.scrollWithMouseThreshold) {
                        return;
                    }
                    if (!scrolling) {
                        scrollElement.style.pointerEvents = 'none';
                        scrollElement.style.userSelect = 'none';
                    }

                    scrolling = true;
                    scrollElement.scrollLeft = Math.max(0, scrollLeft + dx);
                });

            fromEvent<MouseEvent>(element, 'mouseup')
                .pipe(
                    takeUntil(this.$destroy)
                )
                .subscribe((e: MouseEvent) => {
                    mouseDownX = null;
                    scrolling = false;
                    scrollElement.style.pointerEvents = 'unset';
                    scrollElement.style.userSelect = 'unset';
                });
        }
    }

    ngOnDestroy(): void {
        this.$destroy.next();
        this.$destroy.complete();
    }

}
