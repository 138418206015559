import {Component, HostBinding, Input} from '@angular/core';

@Component({
    selector: 'skeleton-circle',
    templateUrl: './skeleton-circle.component.html',
    styleUrls: ['./skeleton-circle.component.scss']
})
export class SkeletonCircleComponent {
    @Input()
    size = 24;

    @HostBinding('style.height.px')
    get getHeight(): number {
        return this.size;
    }

    @HostBinding('style.width.px')
    get getWidth(): number {
        return this.size;
    }
}
