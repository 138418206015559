import {SimpleBarScrollerDirective} from "./simplebar-scroller.directive";
import {NgModule} from "@angular/core";

@NgModule({
    imports: [],
    declarations: [
        SimpleBarScrollerDirective
    ],
    exports: [
        SimpleBarScrollerDirective
    ]
})
export class SimplebarScrollerModule {
}
