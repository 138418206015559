<div class="popup-wrapper">
    <div class="popup-header">
        <div class="body-text-1">{{config.title}}</div>
        <button
                mat-icon-button
                disableRipple
                type="button"
                class="button-icon"
                color="primary"
                (click)="reject()"
        >
            <mat-icon svgIcon="times"></mat-icon>
        </button>
    </div>
    <div class="popup-body">
        <div class="body-text-2">{{config.description}}</div>
    </div>
    <div class="popup-footer">
        <button
                mat-raised-button
                disableRipple
                type="button"
                class="button-primary-filled button-primary-filled-secondary"
                color="primary"
                (click)="confirm()"
        >
            Подтвердить
        </button>
        <button
                mat-stroked-button
                disableRipple
                type="button"
                class="button-primary-outline"
                color="primary"
                (click)="reject()"
        >
            Отмена
        </button>
    </div>
</div>

