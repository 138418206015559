<div class="page-container">
    <div class="announcement" [formGroup]="form">
        <div class="body-text-2">Параметры анонса события на сайте</div>
        <div class="announcement-row">
            <div class="announcement-col">
                <input-date-time-range-picker
                        placeholder="Период*"
                        [start]="form.controls.start.value"
                        [end]="form.controls.end.value"
                        (rangeChange)="onRangeChange($event)"></input-date-time-range-picker>
            </div>
            <div class="announcement-col">
                <mat-checkbox
                        disableRipple
                        class="input-checkbox"
                        color="primary"
                        formControlName="enableSubscribe"
                >
                    Подписка на начало продаж
                </mat-checkbox>
            </div>
        </div>
        <div class="announcement-row">
            <div class="announcement-col">
                <mat-form-field class="input-field" color="primary">
                    <mat-label>Текст (250 сим.)</mat-label>
                    <input type="text" maxlength="250" matInput formControlName="text">
                </mat-form-field>
            </div>
        </div>
        <div class="body-text-2">Логотип</div>
        <div class="announcement-row">
            <div class="announcement-col">
                <app-add-image (click)="selectImage()" *ngIf="!form.controls.logoLink.value"></app-add-image>
                <div class="logo-link" *ngIf="form.controls.logoLink.value">
                    <img src="{{form.controls.logoLink.value}}">
                    <div class="logo-link-actions">
                        <button
                                mat-icon-button
                                disableRipple
                                type="button"
                                class="button-icon"
                                color="primary"
                                (click)="downloadResourceObject()"
                        >
                            <mat-icon svgIcon="download"></mat-icon>
                        </button>
                        <button
                                mat-icon-button
                                disableRipple
                                type="button"
                                class="button-icon"
                                color="primary"
                                (click)="removeLink()"
                        >
                            <mat-icon svgIcon="basket"></mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="error-container" *ngIf="error">
        <inline-alert-component [text]="error"></inline-alert-component>
    </div>

    <div class="actions">
        <button
                mat-raised-button
                disableRipple
                type="button"
                class="button-primary-filled"
                color="primary"
                (click)="remove()"
        >
            Удалить
        </button>
        <button
                mat-raised-button
                disableRipple
                type="button"
                class="button-primary-filled"
                color="success"
                [disabled]="form.invalid"
                (click)="save()"
        >
            Сохранить
        </button>
    </div>
</div>

