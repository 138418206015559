import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

interface User {
    name: string;
    uuid: string;
}

@Injectable({
    providedIn: 'root'
})
export class UserService {

    private user: User | null = null;

    constructor(
        private readonly httpClient: HttpClient
    ) {
    }

    async loadCurrentUser(): Promise<void> {
        try {
            this.user = await this.httpClient.get<User>(`/api/authentication/me`).toPromise();
        } catch (e) {
            throw e;
        }
    }

    public getUser(): User | null {
        return this.user;
    }

    public getUsername(): string {
        if (this.user) {
            return this.user.name;
        }
        return '';
    }

    public async logout(): Promise<void> {
        try {
            await this.httpClient.post(`/api/authentication/logout`, null).toPromise();
            this.user = null;
        } catch (e) {
            throw e;
        }
    }
}
