import {Injectable} from "@angular/core";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";

@Injectable({
    providedIn: 'root'
})
export class IconsRegistry {

    private static ICONS = [
        'pencil',
        'times',
        'times-filled',
        'search',
        'dropdown-arrow',
        'plus',
        'filter-arrow',
        'arrow',
        'copy',
        'basket',
        'sidenav-arrow',
        'nav-statistics',
        'nav-database',
        'nav-subscriptions',
        'nav-events',
        'nav-rate',
        'nav-orders',
        'nav-report',
        'logout',
        'info',
        'bell',
        'pagination-first',
        'pagination-last',
        'pagination-prev',
        'pagination-next',
        'location',
        'more-vertical',
        'time',
        'gear',
        'flag',
        'calendar',
        'location-mini',
        'seat',
        'ticket',
        'checkmark-filled',
        'hourglass',
        'warning-filled',
        'lock-unlocked',
        'lock-locked',
        'error',
        'chair',
        'checkmark',
        'refresh',
        'save',
        'event-calendar',
        'price-rates',
        'ticket-layouts',
        'season-ticket-consist',
        'open-sale',
        'sales-restrictions',
        'view-sales',
        'minus',
        'download'
    ]

    constructor(
        private readonly matIconRegistry: MatIconRegistry,
        private readonly domSanitizer: DomSanitizer
    ) {
    }

    public init() {
        IconsRegistry.ICONS.forEach(icon => {
            this.matIconRegistry.addSvgIconLiteral(icon,
                this.domSanitizer.bypassSecurityTrustHtml(require(`!!raw-loader?!./assets/${icon}.svg`).default)
            );
        });
    }

}
